import React from 'react';
import {Provider as StoreProvider} from 'react-redux';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import store from './services/store';
import {useCheckToken} from './services/userReducer';
import {GlobalDialog} from './components/services/Dialog'



import Header from './components/Header';
import Footer from './components/Footer';
import KakaoTalkLink from './components/KakaoTalkLink';
// import SearchContainer from './components/logisti/SearchContainer';
// import ScrollToTop from './components/ScrollToTop';

import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Login from './pages/Login';
import Register from './pages/Register';
import Profile from './pages/Profile';
import Request from './pages/Request';
import Contact from './pages/Contact';
import Tracking from './pages/Tracking';
import MyPage from './pages/MyPage';
import About from './pages/About';
import Services from './pages/Services';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import EmailPolicy from './pages/EmailPolicy';
import ResetPassword from './pages/ResetPassword';
import Application from './pages/Application';
import CustomerForm from './pages/CustomerForm'


const App = () => {

  return (
    <StoreProvider store={store}>
      <Router>
        <AuthCheck />
        <Header />
        <Switch>
          <Route path={'/'} component={Home} exact={true} />
          <Route path={'/login'} component={Login} exact={true} />
          <Route path={'/register'} component={Register} exact={true} />
          <Route path={'/profile'} component={Profile} exact={true} />
          <Route path={'/request'} component={Request} exact={true} />
          <Route path={'/contact'} component={Contact} exact={true} />
          <Route path={'/tracking/:trackingNo'} component={Tracking} exact={true} />
          <Route path={'/my-page'} component={MyPage} exact={true} />
          <Route path={'/about'} component={About} exact={true} />
          <Route path={'/moving'} component={Services} exact={true} />
          <Route path={'/vehicle'} component={Services} exact={true} />
          <Route path={'/courier'} component={Services} exact={true} />
          <Route path={'/cashmall'} component={Services} exact={true} />
          <Route path={'/forwarding'} component={Services} exact={true} />
          <Route path={'/ecommerce'} component={Services} exact={true} />
          <Route path={'/terms'} component={Terms} exact={true} />
          <Route path={'/privacy'} component={Privacy} exact={true} />
          <Route path={'/email-policy'} component={EmailPolicy} exact={true} />
          <Route path={'/reset-password'} component={ResetPassword} exact={true} />
          <Route path={'/application'} component={Application} exact={true} />
          <Route path={'/application/form'} component={CustomerForm} exact={true} />
          <Redirect from={'/international-shipping'} to={'/tracking/NG'} component={Tracking} exact={true} />
          <Route component={NotFound} />
        </Switch>
        <GlobalDialog />
        <Footer />
        <KakaoTalkLink />
        {/*<ScrollToTop />*/}
        {/*<SearchContainer />*/}
         </Router>
      </StoreProvider>
  );
}

const AuthCheck = () => {
  useCheckToken();
  return null;
}

export default App;
