/**
 * @flow
 */
import React from 'react';
import PageTitle from '../components/PageTitle';
import usePageScriptRunner from '../hooks/usePageScriptRunner';
import {RegisterLoginForm} from '../components/forms/RegisterLoginForm';
import useGuestOnly from '../hooks/useGuestOnly';

const Register = () => {
  useGuestOnly();
  usePageScriptRunner();
  return (
    <>
      <PageTitle title={'Login / Register'} breadcrumbs={[{label: 'Home', path: '/'}]} paddingTop={200} />
      <RegisterLoginForm />
    </>
  );
};

export default Register;
