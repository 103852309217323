/**
 * @flow
 */
import React from 'react';
import {useHistory} from 'react-router-dom';
import {useUser} from '../services/userReducer';

export default function useGuestOnly(pathToRedirect = '/') {
  const user = useUser();
  const history = useHistory();
  React.useEffect(() => {
    if (user.partner_id > 0) {
      history.push(pathToRedirect);
    }
  }, []);
}
