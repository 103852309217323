/**
 * @flow
 */
import React from 'react';
import {useAboutLabels} from '../../services/langReducer';

const About2 = () => {
  const labels = useAboutLabels();
  return (
    <section id="about2" className="about about-2 pb-30" style={{paddingTop: 20}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
            <div className="heading text-center">
              <h2 className="heading__title">{labels.aboutLabel}</h2>
              <div className="divider__line divider__theme divider__center mb-0" />
            </div>{/* /.heading */}
          </div>{/* /.col-lg-6 */}
        </div>{/* /.row */}
        <div className="row">
          <div className="col-sm-12 col-md-9 col-lg-5">
            <div className="mb-60" style={{position: 'relative', height: 250}}> {/* removed `about__img` */}
              <div className={'d-flex align-items-center justify-content-center'} style={{
                position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, height: 250,
                backgroundImage: `url(assets/public/images/about/02.jpg)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}>
                <a className="popup-video" href={labels.aboutVideoUrl}>
                  <span className="video__player-animation" />
                  <div className="video__player">
                    <i className="fa fa-play" />
                  </div>
                </a>
              </div>
            </div>{/* /.about-img */}
          </div>{/* /.col-lg-5 */}
          <div className="col-sm-12 col-md-12 col-lg-7">
            <div className="row heading heading-2">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <img src="assets/public/images/about/coship_txt.png" alt="Coship" className="signature mb-30" height={32} />
                <p className="heading__desc mt-30 mb-30 text-justify">{labels.aboutHeading}</p>
                {/*<p className="text-justify">{labels.aboutContent}</p>*/}
              </div>{/* /.col-lg-7 */}
            </div>{/* /.row */}
          </div>{/* /.col-lg-7 */}
          <div className="col-sm-12 col-md-12 col-lg-12">
            <p className="text-justify font-weight-bold text-dark">{labels.aboutContent}</p>
          </div>
        </div>{/* /.row */}
      </div>{/* /.container */}
    </section>
  );
};

export default About2;
