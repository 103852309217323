/**
 * @flow
 */
import React from 'react';
import {useLabels, useLang} from '../../services/langReducer';
import ServicePage, {getServiceLabels} from './ServicePage';
import BottomSlide from './BottomSlide';
import Accordion from './Accordion';

const Forwarding = () => {
  const lang = useLang();
  const langName = lang === 'ko' ? 'korean' : 'english';
  const labels = useLabels();
  const serviceLabels = getServiceLabels(labels);
  const serviceLabel = window[lang === 'ko' ? 'forwardingLabelsKo' : 'forwardingLabelsEn'];
  const imagePath = 'assets/public/images/services/forwarding';
  const title = labels.menuServiceCommercial;
  const background = `forwarding/01.${langName}.jpg`;
  const {overviewText, video} = serviceLabel;
  const accordionItems = [
    {
      title: 'FAQ',
      content: (
        <>
          {serviceLabel.faqItems.map((item, index) => {
            return (
              <div className='mb-4' key={index}>
                <b className='d-inline-block mb-2'>Q. {item.title}</b>
                <div>{item.content}</div>
              </div>
            );
          })}
        </>
      ),
    },
    {
      title: serviceLabel.title4,
      content: serviceLabel.desc4,
    }
  ];
  return (
    <ServicePage title={title} aside={{labels, serviceLabels}} overview={{background, video, overviewText}}>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 mb-30">
          <div className="text__block-3">
            <h5 className="text__block-title">{serviceLabel.title1}</h5>
          </div>
          <img src={`${imagePath}/02.${langName}.jpg`} alt="chart" className="img-fluid mb-50" />
          <p className="text__block-desc">{serviceLabel.desc1_1}</p>
          <p className="text__block-desc">{serviceLabel.desc1_2}</p>
        </div>{/* /.col-lg-12 */}
      </div>{/* /.row */}
      <div className="row mb-30">
        <div className="col-sm-12 col-md-12 col-lg-12 mb-30">
          <div className="text__block">
            <h5 className="text__block-title">{serviceLabel.title2}</h5>
            <h6>{serviceLabel.title2_1}</h6>
            {serviceLabel.desc2_1Items.map((label, key) => {
              if (key >= 1 && key <= 6) {
                return <p className="mb-1 ml-30" key={key}>{label}</p>;
              } else {
                return <p className="mb-1" key={key}>{label}</p>;
              }
            })}
            <h6 className={'mt-30'}>{serviceLabel.title2_2}</h6>
            {serviceLabel.desc2_2Items.map((label, key) => {
              if (key === 0) {
                return (
                  <p className="mb-1" key={key}>
                    {label}
                    <a className='ml-4' href={'https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/register.html'} target={'_blank'}>{serviceLabel.desc2_2Register}</a>
                  </p>
                );
              } else {
                return <p className="mb-1" key={key}>{label}</p>;
              }
            })}
            <h6 className={'mt-30'}>{serviceLabel.title2_3}</h6>
            <p className={'font-weight-bold'}>{serviceLabel.desc2_3Items[0]}</p>
            <p>{serviceLabel.desc2_3Items[1]}</p>
            <p className={'font-weight-bold'}>{serviceLabel.desc2_3Items[2]}</p>
            <p>{serviceLabel.desc2_3Items[3]}</p>
          </div>{/* /.text-block */}
        </div>{/* /.col-lg-12 */}
      </div>{/* /.row */}
      <div className="row mb-30">
        <div className="col-sm-12 col-md-12 col-lg-12 mb-30">
          <div className="text__block">
            <h5 className="text__block-title">{serviceLabel.title3}</h5>
            <h6>{serviceLabel.title3_1}</h6>
            {serviceLabel.desc3_1Items.map((label, key) => {
              if (key === 1 || (key >= 4 && key <= 7)) {
                if (key === 4) {
                  return (
                    <p className="mb-1 ml-30" key={key}>
                      {label}
                      <a className={'ml-2'} href={'https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/register.html'} target={'_blank'}>{serviceLabel.desc3_1Link}</a>
                    </p>
                  );
                } else {
                  return <p className="mb-1 ml-30" key={key}>{label}</p>;
                }
              } else if (key >= 2 && key <= 3) {
                return <p className="mb-1 ml-30 pl-30" key={key}>{label}</p>;
              } else {
                return <p className="mb-1" key={key}>{label}</p>;
              }
            })}
          </div>{/* /.text-block */}
        </div>{/* /.col-lg-12 */}
      </div>{/* /.row */}
      {/*폴더블 컨텐츠*/}
      <div className="row mt-50 mb-30">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <Accordion items={accordionItems} prefix={'main'} />
        </div>{/* /.col-lg-12 */}
      </div>
      {/*이미지 슬라이드*/}
      <div className='row mt-50'>
        <div className='col-sm-12 col-md-12 col-lg-12'>
          <BottomSlide imagePath={imagePath} items={serviceLabel.slideItems} />
        </div>
      </div>
    </ServicePage>
  );
};

export default Forwarding;
