/**
 * @flow
 */
import React from 'react';

interface AccordionProps {
  prefix: string;
  items: {
    title: string;
    content: React$Node;
  }[],
}

const Accordion = (props: AccordionProps) => {
  return (
    <div id={`${props.prefix}accordion`}>
      {props.items.map((item, index) => {
        return (
          <div className="accordion-item" key={index}>
            <div className="accordion__item-header" data-toggle="collapse" data-target={`#${props.prefix}collapse${index}`}>
              <a className="accordion__item-title" href="#">{item.title}</a>
            </div>{/* /.accordion-item-header */}
            <div id={`${props.prefix}collapse${index}`} className="collapse" data-parent={`#${props.prefix}accordion`}>
              <div className="accordion__item-body">
                {item.content}
              </div>{/* /.accordion-item-body */}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Accordion;
