/**
 * @flow
 */
import React from 'react';
import {useLabels, useLang} from '../../services/langReducer';
import ServicePage, {getServiceLabels} from './ServicePage';
import BottomSlide from './BottomSlide';

const ECommerce = () => {
  const lang = useLang();
  const langName = lang === 'ko' ? 'korean' : 'english';
  const labels = useLabels();
  const serviceLabels = getServiceLabels(labels);
  const serviceLabel = window[lang === 'ko' ? 'ecommerceLabelsKo' : 'ecommerceLabelsEn'];
  const imagePath = 'assets/public/images/services/ecommerce';
  const title = labels.menuServiceWarehouse;
  const background = `ecommerce/01.${langName}.jpg`;
  const {overviewText, video} = serviceLabel;
  return (
    <ServicePage title={title} aside={{labels, serviceLabels}} overview={{background, video, overviewText}}>
      {/*귀국 차량운송 진행 절차*/}
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="text__block">
            <h5 className="text__block-title">{serviceLabel.title1}</h5>
            <p className="text__block-desc">{serviceLabel.desc1_1}</p>
            <ul style={{listStyleType: 'disc', paddingLeft: '1em'}}>
              {serviceLabel.desc1_1Items.map((label, key) => <li key={key}>{label}</li>)}
            </ul>
            <p className="text__block-desc mt-20">{serviceLabel.desc1_2}</p>
            <p className="text__block-desc">{serviceLabel.desc1_3}</p>
          </div>{/* /.text-block */}
        </div>{/* /.col-lg-12 */}
      </div>{/* /.row */}
      {/*이미지 슬라이드*/}
      <div className='row mt-50'>
        <div className='col-sm-12 col-md-12 col-lg-12'>
          <BottomSlide imagePath={imagePath} items={serviceLabel.slideItems} />
        </div>
      </div>
    </ServicePage>
  );
};

export default ECommerce;
