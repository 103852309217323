/**
 * @flow
 */
import React from 'react';
import {util} from '../../services/service';
import {useAboutLabels, useLang} from '../../services/langReducer';

const Banner1 = () => {
  const lang = useLang();
  const labels = useAboutLabels();
  return (
    <section id="banner1" className="banner banner-1 video fancybox-layout2 p-0 bg-theme mb-100">
      <div className="container-fluid col-padding-0">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 equal-height">
            <div style={{padding: util.getClientWidth() > 900 ? '50px 100px' : '8px 16px'}}> {/* removed inner-padding */}
              <div className="heading heading-2 mb-50">
                {lang === 'ko' && <h2 className="heading__title color-white">{labels.coshipHistoryLabel}</h2>}
                {/*<p className="heading__desc color-white">Logisti Group is a representative logistics operator providing full*/}
                {/*  range of service in the sphere of customs clearance and transportation worldwide for any type of*/}
                {/*  cargo. </p>*/}
              </div>{/* /.heading */}
              <div className="row">
                {lang === 'en' && (
                  <div className={'col-12'}>
                    <div className='color-white mb-2'>{labels.history2006}</div>
                    <div className='color-white mb-2'>{labels.history2007}</div>
                    <div className='color-white mb-2'>{labels.history2010}</div>
                    <div className='color-white mb-2'>{labels.history2011}</div>
                  </div>
                )}
                {lang === 'ko' && (
                  <div className="col-12">
                    <div className="color-white mb-2"><b>2006</b><br/>{labels.history2006}</div>
                    <div className="color-white mb-2"><b>2007</b><br/>{labels.history2007}</div>
                    <div className="color-white mb-2"><b>2010</b><br/>{labels.history2010}</div>
                    <div className="color-white mb-2"><b>2011</b><br/>{labels.history2011}</div>
                    <div className="color-white mb-2"><b>2012</b><br/>{labels.history2012}</div>
                    <div className="color-white mb-2"><b>2016</b><br/>{labels.history2016}</div>
                    <div className="color-white"><b>2017</b><br/>{labels.history2017_1}</div>
                    <div className="color-white">{labels.history2017_2}</div>
                    <div className="color-white">{labels.history2017_3}</div>
                    <div className="color-white">{labels.history2017_4}</div>
                    <div className="color-white mb-2">{labels.history2017_5}</div>
                    <div className="color-white mb-2"><b>2019</b><br/>{labels.history2019}</div>
                    <div className="color-white"><b>2020</b><br/>{labels.history2020_1}</div>
                    <div className="color-white mb-2">{labels.history2020_2}</div>
                    <div className="color-white"><b>2023</b><br/>{labels.history2023}</div>
                  </div>
                )}
                {/*/!* fancybox item #1 *!/*/}
                {/*<div className="col-sm-6 col-md-6 col-lg-6">*/}
                {/*  <div className="fancybox-item">*/}
                {/*    <div className="fancybox__icon">*/}
                {/*      <i className="icon-credit-cards" />*/}
                {/*    </div>/!* /.fancybox-icon *!/*/}
                {/*    <div className="fancybox__content">*/}
                {/*      <h4 className="fancybox__title">Transparent Pricing</h4>*/}
                {/*      <p className="fancybox__desc">The world of international supply chains involves a myriad of unknown*/}
                {/*        risks*/}
                {/*        and challenging regulations.</p>*/}
                {/*    </div>/!* /.fancybox-content *!/*/}
                {/*  </div>/!* /.fancybox-item *!/*/}
                {/*</div>/!* /.col-lg-3 *!/*/}
                {/*/!* fancybox item #2 *!/*/}
                {/*<div className="col-sm-6 col-md-6 col-lg-6">*/}
                {/*  <div className="fancybox-item">*/}
                {/*    <div className="fancybox__icon">*/}
                {/*      <i className="icon-search-worldwide" />*/}
                {/*    </div>/!* /.fancybox-icon *!/*/}
                {/*    <div className="fancybox__content">*/}
                {/*      <h4 className="fancybox__title">Real-Time Tracking</h4>*/}
                {/*      <p className="fancybox__desc">We ensure our customers’ supply chains are fully compliant by our*/}
                {/*        comprehensive practices and programs. </p>*/}
                {/*    </div>/!* /.fancybox-content *!/*/}
                {/*  </div>/!* /.fancybox-item *!/*/}
                {/*</div>/!* /.col-lg-3 *!/*/}
              </div>{/* /.row */}
              {/*<a href="request-quote.html" className="btn  btn__white btn__hover2 btn__lg">Schedule An Appointment</a>*/}
            </div>
          </div>{/* /.col-lg-6 */}
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="bg-overlay">
              <div className="bg-img"><img src={'assets/public/images/about/02.jpg'} alt="background" /></div>
              <div className="video__btn video__btn-white align-v-h equal-height">
                {/*<a className="popup-video" href={labels.historyVideoUrl}>*/}
                {/*  <span className="video__player-animation" />*/}
                {/*  <div className="video__player">*/}
                {/*    <i className="fa fa-play" />*/}
                {/*  </div>*/}
                {/*</a>*/}
              </div>
            </div>{/* /.video */}
          </div>{/* /.col-lg-6 */}
        </div>{/* /.row */}
      </div>{/* /.container */}
    </section>
  );
};

export default Banner1;
