/**
 * @flow
 */
import React from 'react';
import {useLabels} from "../../services/langReducer";
import {useLocation, useHistory} from 'react-router-dom';
// import {Button, Card, CardBody, Col, Container, Row} from 'reactstrap';
// import {serviceTypeOptions} from '../../forms/CustomerInputForm';
// import {util} from '../../services/service';

// const buttonStyle = {
//   width: '100%',
//   height: '96px',
//   fontSize: 20,
//   // fontWeight: 'bold',
// };

export const basePathRegex = '/application';
export const dropzoneIdRegex = '/application';

export const serviceTypeOptions = [
  {value: '', label: ''},
  {value: 'MA', label: '항공 택배 (AIR)'},
  {value: 'MO', label: '선박 MAPLEBOX'},
  {value: 'CP', label: 'CANADA POST (소형 1박스일 경우)'},
  {value: 'UPS', label: 'UPS (대형 1박스 이상일 경우)'}
];

const ServiceSelector = () => {
  const history = useHistory();
  const location = useLocation();
  const basePath = location.pathname.match(basePathRegex)?.[0] ?? '/'; // undefine,null일 경우 '/'
  const serviceRef = React.useRef();
  const destinationRef = React.useRef();
  const labels = useLabels();


  const onClick = ({value}, destination = '', kind) => {
    // 클릭 시, 택배사의 정보(value)와 목적지의 정보(destination)를 가지고 CustomerForm으로 이동합니다.
    serviceRef.current = value;
    destinationRef.current = destination;
    history.push(`${basePath}/form?s=${serviceRef.current}&d=${destinationRef.current}&n=${kind}`);

  };

  return (
    <section id="application" className="contact text-center" style={{paddingTop: 20}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
            <div className="heading text-center mb-50">
              <h2 className="heading__title">{labels.menuApplication}</h2>
              <div className="divider__line divider__theme divider__center mb-0" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className={'col mb-4'} sm={6}><Selector option={serviceTypeOptions[1]} onClick={onClick} destination={'KOREA'} kind={'C'} /></div>
          <div className={'col mb-4'} sm={6}><Selector option={serviceTypeOptions[2]} onClick={onClick} destination={'KOREA'} kind={'C'} /></div>
        </div>
        <div className="row">
          <div className={'col mb-4'} sm={6}><Selector option={serviceTypeOptions[1]} onClick={onClick} destination={'CANADA'} kind={'C'} /></div>
          <div className={'col mb-4'} sm={6}><Selector option={serviceTypeOptions[2]} onClick={onClick} destination={'CANADA'} kind={'C'} /></div>
        </div>
        <div className="row">
          <div className={'col mb-4'} sm={6}><Selector option={serviceTypeOptions[3]} onClick={onClick} kind={'P'} /></div> {/* P */}
          <div className={'col mb-4'} sm={6}><Selector option={serviceTypeOptions[4]} onClick={onClick} kind={'U'} /></div> {/* U */}
        </div>
      </div>
    </section>
  );
};

const Selector = ({option, onClick, destination, kind, dev, disabled, display}) => {
  let label = '';
  if (destination === 'KOREA') {
    label = ' (캐나다 ⟶ 한국)';
  } else if (destination === 'CANADA') {
    label = ' (한국 ⟶ 캐나다)';
  }
  // return <Button color={'primary'} onClick={onClick}>{option.label} {label}</Button>;
  return <button disabled={disabled}  type="button" className="btn btn__secondary btn__hover3 w-100" onClick={() => onClick(option, destination, kind)}>{option.label} {label} {dev}</button>;
};

export default ServiceSelector;
