/**
 * @flow
 */
import React from 'react';
import {AlertError, AlertSuccess, Col, Field, Row, SelectField, TextArea} from './formHelper';
import {useUser} from '../../services/userReducer';
import {api, util} from '../../services/service';
import {useLabels} from '../../services/langReducer';
import {Link} from 'react-router-dom';

const RequestForm = () => {
	const [editError, setEditError] = React.useState('');
	const [editSuccess, setEditSuccess] = React.useState(false);
	const user = useUser();
	const formRef = React.useRef();
	const labels = useLabels();
	// React.useEffect(() => {
	//   if (user.ready === true && !user.partner_id) {
	//     // 게스트 모드인 경우 토큰을 요청
	//     api.queryToken().catch();
	//   }
	// }, [user]);
	function validate({customerName, serviceType, email, phone, fromCountry, toCountry, address, bigo}) {
		if (!(customerName && serviceType && email && phone && fromCountry && toCountry)) {
			return labels.requiredError;
		}
	}
	
	async function handleSubmit(e) {
		const {
			customerName, serviceType, email, phone, fromCountry, toCountry, address, bigo, kind
		} = util.getFormDataFromEvent(e);
		const data = {
			customerName, serviceType, email, phone, fromCountry, toCountry, address, bigo, kind
		};
		const error = validate(data);
		if (error) {
			window.scrollTo(0, 0);
			setEditError(error);
			return;
		}
		if (!user.partner_id) {
			await api.queryToken();
		}
		const res = await api.requestQuote(data);
		if (!res) {
			setEditError(labels.requestFailedError);
			window.scrollTo(0, 0);
		} else if (typeof res === 'string') {
			setEditError(res);
			window.scrollTo(0, 0);
		} else {
			setEditSuccess(true);
			window.scrollTo(0, 0);
			formRef.current.reset();
		}
	}
	
	return (
		<section id="contact1" className="contact text-center" style={{paddingTop: 20}}>
			<div className="container">
				<div className="row">
					<div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
						<div className="heading text-center mb-50">
							<h2 className="heading__title">{labels.requestQuoteLabel}</h2>
							<div className="divider__line divider__theme divider__center mb-0"/>
							<div className='text-left mt-20'>{labels.requestQuoteLine1}</div>
							<div className='text-left mt-20'>{labels.requestQuoteLine2}</div>
						</div>
						{/* /.heading */}
					</div>
					{/* /.col-lg-6 */}
				</div>
				{/* /.row */}
				<div className="row text-left">
					<div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
						<form ref={formRef} onSubmit={handleSubmit}>
							{editError && (
								<Row>
									<Col col={12}>
										<AlertError message={editError} onClose={() => setEditError('')}/>
									</Col>
								</Row>
							)}
							{editSuccess && (
								<Row>
									<Col col={12}>
										<AlertSuccess message={labels.requestSuccess} onClose={() => setEditSuccess(false)}/>
									</Col>
								</Row>
							)}
							<Row>
								<Col col={6}><Field name={'customerName'} value={user.local_name ?? ''} label={labels.nameLabel}
								                    required/></Col>
								<Col col={6}><SelectField name={'serviceType'} label={labels.serviceTypeLabel} required
								                          options={labels.serviceTypeOptions} addPleaseSelect/></Col>
							</Row>
							<Row>
								<Col col={6}><Field name={'email'} value={user.email} label={labels.emailLabel} type={"email"} required/></Col>
								<Col col={6}><Field name={'phone'} value={user.phone} label={labels.phoneLabel} type={"number"} required/></Col>
							</Row>
							<Row>
								<Col col={6}><Field name={'fromCountry'} label={labels.departureLabel} required/></Col>
								<Col col={6}><Field name={'toCountry'} label={labels.destinationLabel} required/></Col>
							</Row>
							<Row>
								<Col col={12}><Field name={'address'} value={user.address} label={labels.addressLabel}/></Col>
							</Row>
							<Row>
								<Col col={12}><TextArea name={'bigo'} label={labels.remarkLabel}/></Col>
							</Row>
							<div className="row">
								<div className="col-sm-12 col-md-12 col-lg-12">
									<button type="submit" className="btn btn__secondary btn__hover3">{labels.requestQuoteLabel}</button>
									{!user.partner_id && <Link to={'/login'} className="ml-50">{labels.signUpAndContinue}</Link>}
								</div>
								{/* /.col-lg-12 */}
							</div>
							{/* /.row */}
						</form>
					</div>
					{/* /.col-lg-8 */}
				</div>
				{/* /.row */}
			</div>
			{/* /.container */}
		</section>
	);
};

export default RequestForm;
