/**
 * @flow
 */
import React from "react";
import {Link, useLocation} from 'react-router-dom';

const Aside = ({labels, serviceLabels}) => {
  const location = useLocation();
  return (
    <aside className="sidebar mb-30">
      <div className="widget widget-categories widget-categories-2">
        <div className="widget-content">
          <ul className="list-unstyled">
            {Object.entries(serviceLabels).map(([key, {title: menuTitle}], index) => {
              const path = `/${key}`;
              return (
                <li>
                  <Link to={path} className={path === location.pathname ? 'active' : ''}>{menuTitle}</Link>
                </li>
              );
            })}
          </ul>
        </div>{/* /.widget-content */}
      </div>{/* /.widget-categories */}
      <div className="widget widget-help bg-theme">
        <div className="widget__content">
          <h5>{labels.howCanWeHelp}</h5>
          <p>{labels.weUnderstand}</p>
          <Link to={'/request'} className="btn btn__secondary btn__hover2"><i className="fa fa-envelope" />{labels.menuRequest}</Link>
        </div>{/* /.widget-download */}
      </div>{/* /.widget-help */}
    </aside>
  );
};

export default Aside;
