/**
 * @flow
 */
import React from "react";
import {Button} from "reactstrap";

const HeaderCashmall = ({langName}) => {
  if (langName === 'english') {
     return (<></>);
  }
  return (
      <>
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-12 mb-50">
        <div className="text__block">
          <p className="text__block-desc">한국, 캐나다 또는 해외에서 구입을 원하시는 상품이 있으신가요?<br/>캐나다쉬핑이 구입부터 배송까지 ONE STOP 서비스를 제공해 드립니다.</p>
          <div>
            <a href={'https://pf.kakao.com/_dLNBd/chat'} target={'_blank'}>
              <img src="assets/public/images/services/cashmall/header/1.png" alt="cashmall" />
            </a>
          </div>
        </div>{/* /.text-block */}
      </div>{/* /.col-lg-12 */}
    </div>
    <div className="row">
      <div className="col-sm-3 col-md-3 col-lg-3 mb-30">
        <a href={'https://search.naver.com/search.naver?where=nexearch&sm=top_hty&fbm=0&ie=utf8&query=%EC%BA%90%EB%82%98%EB%8B%A4%ED%99%98%EC%9C%A8'} target={'_blank'}>
          <Button className="btn btn__secondary btn__hover3">오늘의 환율</Button>
        </a>
      </div>
      <div className="col-sm-3 col-md-3 col-lg-3 mb-30">
        <a href={'https://pf.kakao.com/_dLNBd/chat'} target={'_blank'}>
          <Button className="btn btn__secondary btn__hover3">운임비용 상담</Button>
        </a>
      </div>
      <div className="col-sm-3 col-md-3 col-lg-3 mb-30">
        <a href={'https://www.ups.com/track?loc=en_CA&requester=ST/'} target={'_blank'}>
          <Button className="btn btn__secondary btn__hover3" style={{fontSize: 12}}>한국→캐나다 배송조회</Button>
        </a>
      </div>
      <div className="col-sm-3 col-md-3 col-lg-3 mb-30">
        <a href={'https://search.naver.com/search.naver?sm=tab_hty.top&where=nexearch&query=캐나다쉬핑&oquery=네이버+캐나다환율&tqi=i4MNkdp0YiRssMbMQSGssssst4K-317670'} target={'_blank'}>
          <Button className="btn btn__secondary btn__hover3" style={{fontSize: 12}}>캐나다→한국 배송조회</Button>
        </a>
      </div>
    </div>
        </>
  );
};

export default HeaderCashmall;
