/**
 * @flow
 */
import React from 'react';
import usePageScriptRunner from '../hooks/usePageScriptRunner';
import useGrid from '../hooks/useGrid';
import TrackingForm from '../components/forms/TrackingForm';
import {useLang} from '../services/langReducer';
import {api} from '../services/service';
import {useHistory, useLocation} from 'react-router-dom';

// const SERVICE_KEY = '744j5OjVVLzRk2e8VdK9Ga%2BDzXjPhuz%2F6lkcI1Sv2hr%2B1Wir7dT9QejxSn%2FkR%2BNgGyrZUXOH6zhdkUNdne6zBA%3D%3D';
// const KOREA_POST_URL = 'http://openapi.epost.go.kr/trace/retrieveLongitudinalCombinedService/retrieveLongitudinalCombinedService/getLongitudinalCombinedList';

const Tracking = ({match}) => {
  usePageScriptRunner();
  const location = useLocation();
  const blNo = location.state?.['blNo'];
  const lang = useLang();
  const grid = useTrackingGrid();
  const onTrack = async (blNo) => {
    // 실제 blNo(Tracking Number)를 가지고 api요청하여 받은 데이터를
    const {data} = await api.getTrackingData(blNo, lang);
    // 이하는 TestData로 사용하였습니다.
    // data.push({"track_date": "2021-02-04", "track_status": "입고 완료", "remark": "VANCOUVER", "link": null}, {"track_date": "2021-02-06", "track_status": "출고", "remark": "VANCOUVER AIRPORT", "link": null}, {
    // "track_date": "2021-02-07", "track_status": "세관통관", "remark": "INCHEON AIRPORT", "link": null}, {"track_date": "", "track_status": "", "remark":  , "link": 'XML'}
    // )
    //console.log('trackingData', data)


    if(data && data[data.length - 1]?.link === 'XML'){
      // 하단은 https로 http요청 할 시에 발생하는 크롬 충돌 때문에 배포 시 작동하지 않습니다.
      // // 한국 우체국 택배 배송 요청
      // let queryParams = '?' + encodeURIComponent('ServiceKey') + '='+ `${SERVICE_KEY}`;
      // // 송장번호
      // queryParams += '&' + encodeURIComponent('rgist') + '=' + encodeURIComponent(`${blNo}`);
      // const res = await axios.get(KOREA_POST_URL + queryParams);
      // const koreanPostInfo = res.data?.response?.trackInfo?.detaileTrackList;
      // {date, time, statue, location} = koreanPostInfo[0] 넘어 오는 정보

      // data의 마지막 remark에 XML이 문자열로 담겨 있습니다.
      const xmlInfo = data[data.length - 1].remark
      // DomParser는 xml파일을 읽을 때 문자열로 읽어서 그 문자열을 xml객체로 만들어 줄 때 사용합니다.
      let parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlInfo,"text/xml");

      // xmlToJson 함수를 사용해서 해당 xml객체를 JSON파일로 바꿔줍니다.
      const res = xmlToJson(xmlDoc);
      const koreanPostInfo = res?.response?.trackInfo?.detaileTrackList;

      //console.log('우체국 택배 결과', koreanPostInfo);

      if(koreanPostInfo === undefined){
        data.pop();
        return grid.setRows(data ?? []);
      } else {
        data.pop();
        koreanPostInfo.map(info => {
          let obj = {};
          obj['track_date'] = `${info.date['#text']} ${info.time['#text']}`;
          obj['track_status'] = info.statue['#text'];
          obj['remark'] = info.location['#text'];
          obj['link'] = null;
          data.push(obj);
        });
        grid.setRows(data ?? []);
      }
    } else {
    	// console.log(data)
      grid.setRows(data ?? []);
    }

  };
  React.useEffect(() => {
    if (blNo) {
      onTrack(blNo).then(() => {
        // 작성한 Tracking No Id trackingNo의 value로 전달 되는 과정 같다.
        document.querySelector('#trackingNo').value = blNo;
      });
    }
  }, [blNo]);

	React.useEffect(() => {
    if(match.params['trackingNo'] !== 'NG'){
      onTrack(match.params['trackingNo']).then(() => {
        // 작성한 Tracking No Id trackingNo의 value로 전달 되는 과정 같다.
        document.querySelector('#trackingNo').value = match.params['trackingNo'];
      });
    }
  }, [match])

  return (
    <div className="common-page">
      <div className={'container'}>
        <TrackingForm onTrack={onTrack} blNo={blNo} />
        {grid.render()}
      </div>
    </div>
  );
};

// xml객체를 Json으로 바꿔주는 역할을 합니다.
function xmlToJson(xml) {
	// Create the return object
	let obj = {};

	if (xml.nodeType === 1) { // element
		// do attributes
		if (xml.attributes.length > 0) {
		obj["@attributes"] = {};
			for (let j = 0; j < xml.attributes.length; j++) {
				let attribute = xml.attributes.item(j);
				obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
			}
		}
	} else if (xml.nodeType === 3) { // text
		obj = xml.nodeValue;
	}

	// do children
	if (xml.hasChildNodes()) {
		for(let i = 0; i < xml.childNodes.length; i++) {
			let item = xml.childNodes.item(i);
			let nodeName = item.nodeName;
			if (typeof(obj[nodeName]) == "undefined") {
				obj[nodeName] = xmlToJson(item);
			} else {
				if (typeof(obj[nodeName].push) == "undefined") {
					let old = obj[nodeName];
					obj[nodeName] = [];
					obj[nodeName].push(old);
				}
				obj[nodeName].push(xmlToJson(item));
			}
		}
	}
	return obj;
};

function TrackingCellRenderer(params) {
  const link = params?.data?.link ?? '';
  if(link) {
    return <a href={link} target={'_blank'}>{params?.value}</a>;
  }
  return params?.value;
}

function useTrackingGrid() {
  const lang = useLang();
  const isEn = lang === 'en';
  const grid = useGrid({
    height: 500,
    columnDefs: [
      {field: 'track_date', headerName: isEn ? 'Date' : '날짜', flex: 1},
      {field: 'track_status', headerName: isEn ? 'Status' : '진행상황', flex: 1},
      {field: 'remark', headerName: isEn ? 'Remark' : '비고', flex: 1, cellRendererFramework: TrackingCellRenderer},
    ],
  });
  React.useEffect(() => {
    grid.setRows([]);
  }, [grid.isReady]);
  return grid;
}

export default Tracking;
