/**
 * @flow
 */
import React from 'react';
import {useLabels, useLang} from '../../services/langReducer';
import ServicePage, {getServiceLabels} from './ServicePage';
import Accordion from './Accordion';
import BottomSlide from './BottomSlide';

const Vehicle = () => {
  const lang = useLang();
  const langName = lang === 'ko' ? 'korean' : 'english';
  const labels = useLabels();
  const serviceLabels = getServiceLabels(labels);
  const serviceLabel = window[lang === 'ko' ? 'vehicleLabelsKo' : 'vehicleLabelsEn'];
  const imagePath = 'assets/public/images/services/vehicle';
  const title = labels.menuServiceCar;
  const background = `vehicle/01.${langName}.jpg`;
  const {overviewText, video} = serviceLabel;
  const accordionItems = [
    {
      title: serviceLabel.title5,
      content: (
        <>
          <ul style={{listStyleType: 'disc', paddingLeft: '1em'}}>
            {serviceLabel.desc5Items.map((label, key) => <li key={key}>{label}</li>)}
          </ul>
          <p className='mt-20'>{serviceLabel.desc5Remark}</p>
        </>
      ),
    },
    {
      title: serviceLabel.title6,
      content: (
        <>
          <p>{serviceLabel.desc6}</p>
          {serviceLabel.desc6Remarks.map((label, key) => <p className='mt-20' key={key}>{label}</p>)}
        </>
      ),
    },
    {
      title: serviceLabel.title7,
      content: (
        <>
          <p className='mb-20'>{serviceLabel.desc7}</p>
          <ul style={{listStyleType: 'disc', paddingLeft: '1em'}}>
            {serviceLabel.desc7Items.map((label, key) => <li key={key}>{label}</li>)}
          </ul>
          {serviceLabel.desc7Remarks.map((label, key) => <p className='mt-20' key={key}>{label}</p>)}
        </>
      ),
    },
    {
      title: serviceLabel.title8,
      content: serviceLabel.desc8Items.map((label, key) => <p className='mb-20' key={key}>{label}</p>),
    },
    // {
    //   title: serviceLabel.title9,
    //   content: serviceLabel.desc9Items.map((label, key) => <p className='mb-20' key={key}>{label}</p>),
    // },
    {
      title: 'FAQ',
      content: (
        <>
          {serviceLabel.faqItems.map((item, index) => {
            if (index === 2) {
              return (
                <div className='mb-4' key={index}>
                  <b className='d-inline-block mb-2'>Q. {item.title}</b>
                  <div>
                    <p>{item.content[0]}</p>
                    <p>{item.content[1]}</p>
                    <p className='mb-20'>{item.content[2]}</p>
                    <p className='mb-20'>{item.content[3]}</p>
                    <p>{item.content[4]}</p>
                    <p>{item.content[5]}</p>
                  </div>
                </div>
              );
            } else {
              return (
                <div className='mb-4' key={index}>
                  <b className='d-inline-block mb-2'>Q. {item.title}</b>
                  <div>{item.content}</div>
                </div>
              );
            }
          })}
        </>
      ),
    },
  ];
  return (
    <ServicePage title={title} aside={{labels, serviceLabels}} overview={{background, video, overviewText}}>
      {/*캐나다에서 타시던 차를 한국으로 가져가고 싶으세요?*/}
      <div className='row mb-50'>
        <div className='col-sm-12 col-md-12 col-lg-12'>
          {/*<h4 className='text-danger text-center' style={{lineHeight: 1.5}}>{serviceLabel.title1}</h4>*/}
          <h5 className='text-center mb-30' style={{lineHeight: 1.5}}>{serviceLabel.title1}</h5>
          <p className="text__block-desc mb-30 font-weight-bold">{serviceLabel.title2}</p>
        </div>
        <div className='col-sm-12 col-md-12 col-lg-6'>
          <p className="text__block-desc">{serviceLabel.desc1_1}</p>
          <p className="text__block-desc">{serviceLabel.desc1_2}</p>
        </div>
        <div className='col-sm-12 col-md-12 col-lg-6'>
          <p className="text__block-desc">{serviceLabel.desc1_3}</p>
          <p className="text__block-desc">{serviceLabel.desc1_4}</p>
        </div>
      </div>
      {/*캐나다 쉬핑 차량운송의 장점*/}
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="text__block">
            <h5 className="text__block-title">{serviceLabel.title3}</h5>
            <p className="text__block-desc">{serviceLabel.desc3}</p>
            <ul style={{listStyleType: 'disc', paddingLeft: '1em'}}>
              {serviceLabel.desc3Items.map((label, key) => <li key={key}>{label}</li>)}
            </ul>
          </div>{/* /.text-block */}
        </div>{/* /.col-lg-12 */}
      </div>{/* /.row */}
      {/*귀국 차량운송 진행 절차*/}
      <div className="row mt-50">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="text__block">
            <h5 className="text__block-title">{serviceLabel.title4}</h5>
            <img  src={`${imagePath}/02.${langName}.png`} width={'100%'} alt={'car'} />
            <div className='d-flex align-items-center mt-2 mb-4'>
              <img src={`${imagePath}/03.png`} width={15} alt={'circle'} />
              <span className='mx-2'>{serviceLabel.desc4_2}</span>
              <img src={`${imagePath}/04.png`} width={15} alt={'circle'} />
              <span className='mx-2'>{serviceLabel.desc4_1}</span>
            </div>
            {serviceLabel.desc4Items.map((label, index) => <p className='text__block-desc' key={index}>{label}</p>)}
          </div>{/* /.text-block */}
        </div>{/* /.col-lg-12 */}
      </div>{/* /.row */}
      {/*한국 ---> 캐나다*/}
      <div className="row mt-50">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="text__block">
            <h5 className="text__block-title">{serviceLabel.title9}</h5>
            {serviceLabel.desc9Items.map((label, key) => <p className='mb-20' key={key}>{label}</p>)}
          </div>{/* /.text-block */}
        </div>{/* /.col-lg-12 */}
      </div>{/* /.row */}
      {/*폴더블 컨텐츠*/}
      <div className="row mt-50 mb-30">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <Accordion items={accordionItems} prefix={'main'} />
        </div>{/* /.col-lg-12 */}
      </div>
      {/*이미지 슬라이드*/}
      <div className='row mt-50'>
        <div className='col-sm-12 col-md-12 col-lg-12'>
          <BottomSlide imagePath={imagePath} items={serviceLabel.slideItems} />
        </div>
      </div>
    </ServicePage>
  );
};

export default Vehicle;
