/**
 * @flow
 */
import React from 'react';
import useForm from './useForm';
import {useLabels} from '../../services/langReducer';
import {useLocation} from 'react-router-dom';

const ResetPasswordForm = ({onSubmit}) => {
  const form = useForgotPasswordForm(onSubmit);
  const labels = useLabels();
  return (
    <section id="contact1" className="contact text-center" style={{paddingTop: 20, paddingBottom: 0}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
            <div className="heading text-center mb-50">
              <h2 className="heading__title">{labels.resetPassword}</h2>
              <div className="divider__line divider__theme divider__center mb-0" />
            </div>{/* /.heading */}
          </div>{/* /.col-lg-6 */}
        </div>{/* /.row */}
        <div className="row text-left">
          <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
            {form.render()}
          </div>{/* /.col-lg-8 */}
        </div>{/* /.row */}
      </div>{/* /.container */}
    </section>
  );
};

function useForgotPasswordForm(onSubmit) {
  const labels = useLabels();
  return useForm({
    fields: [
      {name: 'email', placeholder: labels.emailLabel, required: true},
    ],
    onInit: (values, setValues) => {},
    onValidate: (values) => {return null;},
    onSubmit: async (values, setSuccess, setError) => {
      const {email} = values;
      if (email) {
        onSubmit(email);
      }
    },
    onRender: ({fields, values, setValues, success, setSuccess, error, setError, _r}) => {
      return (
        <div className={'d-flex w-100 flex-column align-items-center justify-content-start'} style={{minHeight: '50vh'}}>
          <div style={{minWidth: 300}}>
            {_r('email')}
          </div>
          <div className="mr-2 mb-4">
            <button type="submit" className="btn btn__secondary btn__hover3">{labels.submitLabel}</button>
          </div>
        </div>
      );
    },
  });
}

export default ResetPasswordForm;
