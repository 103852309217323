/**
 * @flow
 */
import React from 'react';

interface FieldOption {
  label: string;
  value: string;
}

interface FieldDef {
  name: string;
  type?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  options?: FieldOption[];
}

interface OnRenderParams {
  fields: FieldDef;
  values: Object;
  setValues: (values: Object) => void;
  success: boolean | undefined;
  error: string;
  setError: (error: string) => void;
}

interface UseFormParams {
  fields: FieldDef[];
  onRender: (params: OnRenderParams) => React$Node;
  onValidate: (values: Object) => string | null;
  onSubmit: (values: Object, setError: (error: string) => void) => Promise;
  onInit?: (values: Object, setValues: (values: Object) => void) => void;
}

export const Field = ({name, value, label, placeholder, required, type, onChange}) => {
  return (
    <div className={'form-group'}>
      {label && (
        <label htmlFor={name}>
          {label}
          {required === true && <b className={'ml-1 color-theme'}>*</b>}
        </label>
      )}
      <input
        type={type}
        id={name}
        name={name}
        placeholder={placeholder}
        value={value ?? ''}
        onChange={onChange}
        className={'form-control'}
      />
    </div>
  );
};

export function renderField(field: FieldDef, value, onChange) {
  const {name, label, placeholder, required, type} = field;
  return (
    <Field {...{name, label, placeholder, required, type, value, onChange}} />
  );
}

export default function useForm(params: UseFormParams) {
  const [values, setValues] = React.useState<Object>({});
  const [success, setSuccess] = React.useState<boolean | undefined>();
  const [error, setError] = React.useState<string>();
  const _r = (name: string) => {
    const field = params.fields.find(i => i.name === name);
    return renderField(field, values[name], ({target: {value}}) => setValues({...values, [name]: value}));
  };
  React.useEffect(() => {
    params.onInit && params.onInit(values, setValues);
  }, []);
  return {
    render: () => {
      return (
        <form onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          const newError = params.onValidate(values);
          if (newError === null) {
            // console.log("여기가 submit")
            await params.onSubmit(values, setSuccess, setError);
          } else {
            setError(newError);
          }
        }}>
          {
            params.onRender({
              fields: params.fields,
              values, setValues,
              success, setSuccess,
              error, setError,
              _r,
            })
          }
        </form>
      );
    },
  };
}
