/**
 * @flow
 */
import React from 'react';
import usePageScriptRunner from '../hooks/usePageScriptRunner';
import {useLabels, usePrivacyLabels} from '../services/langReducer';

const EmailPolicy = () => {
  usePageScriptRunner();
  const commonLabels = useLabels();
  const labels = usePrivacyLabels();
  return (
    <div className="common-page">
      <section id="caseStudiesSingle" className="case-studies-single" style={{paddingTop: 20}}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
              <div className="heading text-center mb-50">
                <h2 className="heading__title">{commonLabels.emailPolicyLabel}</h2>
                <div className="divider__line divider__theme divider__center mb-0" />
              </div>{/* /.heading */}
            </div>{/* /.col-lg-6 */}
          </div>{/* /.row */}
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12'>
              <div className="alert alert-light" role="alert">
                <p>{labels.emailPolicyItems[0]}</p>
                <p>{labels.emailPolicyItems[1]}</p>
              </div>
              <p className='mt-50'>{labels.emailPolicyItems[2]}</p>
              <ul style={{listStyleType: 'disc', paddingLeft: '1em'}}>
                <li>{labels.emailPolicyItems[3]}</li>
                <li>{labels.emailPolicyItems[4]}</li>
                <li>{labels.emailPolicyItems[5]}</li>
              </ul>
              <p className='mt-20'>{labels.emailPolicyItems[6]}</p>
            </div>
          </div>
        </div>{/* /.container */}
      </section>
    </div>
  );
};

export default EmailPolicy;
