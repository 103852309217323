/**
 * @flow
 */
import React from 'react';
import usePageScriptRunner from '../hooks/usePageScriptRunner';
import useUserOnly from '../hooks/useUserOnly';
import ProfileForm from '../components/forms/ProfileForm';

const Profile = () => {
  useUserOnly();
  usePageScriptRunner();
  return (
    <div className="common-page">
      {/*<PageTitle title={'Profile'} breadcrumbs={[{label: 'Home', path: '/'}]} paddingTop={160} paddingBottom={10} />*/}
      <ProfileForm />
    </div>
  );
};

export default Profile;
