/**
 * @flow
 */
import React from 'react';
import usePageScriptRunner from '../hooks/usePageScriptRunner';

const NotFound = () => {
  usePageScriptRunner();
  return (
    <div className="common-page">
      404 Not Found!
    </div>
  );
};

export default NotFound;
