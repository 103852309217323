/**
 * @flow
 */
import React from 'react';
import usePageScriptRunner from '../hooks/usePageScriptRunner';
import {useLabels, useTermsLabels} from '../services/langReducer';

const Terms = () => {
  usePageScriptRunner();
  const commonLabels = useLabels();
  const labels = useTermsLabels();
  return (
    <div className="common-page">
      <section id="caseStudiesSingle" className="case-studies-single" style={{paddingTop: 20}}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
              <div className="heading text-center mb-50">
                <h2 className="heading__title">{commonLabels.termsLabel}</h2>
                <div className="divider__line divider__theme divider__center mb-0" />
              </div>{/* /.heading */}
            </div>{/* /.col-lg-6 */}
          </div>{/* /.row */}
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12'>
              {labels.termsLines.map((label, key) => {
                return (
                  <p key={key} className='my-2'>{label}</p>
                );
              })}
            </div>
          </div>
        </div>{/* /.container */}
      </section>
    </div>
  );
};

export default Terms;
