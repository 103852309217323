/**
 * @flow
 */
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {api} from './service';

const USER_UPDATE = 'USER_UPDATE';
const USER_RESET = 'USER_RESET';

export function updateUser(dispatch, user: UserState) {
  dispatch({type: USER_UPDATE, user});
}

export function resetUser(dispatch) {
  dispatch({type: USER_RESET});
}

export interface UserState {
  id: number;
  login_id: string;
  login_type: string;
  email: string;
  local_name: string;
  eng_name: string;
  phone: string;
  address: string;
  token: string;
  ready: boolean;
  partner_type: string; // 'VD', 'DZ', ...
}

export function useUser(): UserState {
  return useSelector(state => state['user']);
}

export function useCheckToken() {
  const dispatch = useDispatch();
  const user = useUser();
  const ready = user.ready;
  const userId = user?.id ?? 0;
  React.useEffect(() => {
    if (ready === undefined) {
      if (userId === 0) {
        const token = sessionStorage.getItem('@token');
        const userJsonStr = sessionStorage.getItem('@user');
        if (token && userJsonStr) {
          const user = JSON.parse(userJsonStr);
          api.setUserAndToken(user);
          // console.log('[useCheckToken] user mode', user);
        } else {
          updateUser(dispatch, {...user, ready: true});
          // console.log('[useCheckToken] guest mode');
        }
      }
    } else {
      // console.log('[useCheckToken] already checked!');
    }
  }, [userId, ready]);
}

export default function userReducer(state: UserState = {}, action) {
  const {type, user} = action;
  switch (type) {
    case USER_UPDATE:
      return {...user};
    case USER_RESET:
      return {};
    default:
      return state;
  }
}
