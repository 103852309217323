/**
 * @flow
 */
import React from 'react';
import usePageScriptRunner from '../hooks/usePageScriptRunner';
import About2 from '../components/about/About2';
import Banner1 from '../components/about/Banner1';

const About = () => {
  usePageScriptRunner();
  return (
    <div className="common-page">
      <About2 />
      <Banner1 />
    </div>
  );
};

export default About;
