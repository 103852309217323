/**
 * @flow
 */
import React from 'react';
import {GoogleMap as Map, Marker, withGoogleMap, withScriptjs} from 'react-google-maps';
import {locationCoords} from './GlobalLocations';
import isMobile from 'is-mobile';

const key = `AIzaSyA9ke0DuvxY4TptA0MXFEKg3Bxrpy2WeqI`;
const GoogleMap = () => {
  return (
    <section id="googleMap" className="google-map p-0">
      <InnerMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `500px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </section>
  );
};

// 49.1889483,-122.665953
// 49.1888299,-122.6650632
export let globalSetPosition;
const InnerMap = withScriptjs(withGoogleMap(() => {
  const [position, setPosition] = React.useState(locationCoords[0]);
  globalSetPosition = setPosition;
  return (
    <Map
      zoom={16}
      center={position}
    >
      {/* TODO: marker here */}
      <Marker position={position} onClick={(e) => {
        if (isMobile()) {
          document.location.href = `http://maps.google.com/?daddr=${position.addr}`;
        } else {
          window.open(`http://maps.google.com/?daddr=${position.addr}`);
        }
      }} />
    </Map>
  );
}));

export default GoogleMap;
