/**
 * @flow
 */
import React from 'react';
import usePageScriptRunner from '../hooks/usePageScriptRunner';
import ServiceSelector from '../components/forms/ServiceSelector';
import {useHistory} from 'react-router-dom';
import {api, util} from "../services/service";

const Application = () => {
  usePageScriptRunner();
  const history = useHistory();
  React.useEffect(  () => {
     const token = sessionStorage.getItem('@token');
    //  console.log('토큰이 있나 확인해보까?', token)
     if(token) {
         return
     } else {
         history.push('/login')

     }
  })
  return (
    <div className="common-page">
      <ServiceSelector />
    </div>
  );
};

export default Application;
