/**
 * @flow
 */
import React from "react";
// import PageContainer from './PageContainer';
import CustomerInputForm from "../components/forms/CustomerInputForm";
import {api, util} from "../services/service";
import {useHistory, useLocation} from "react-router-dom";
import {basePathRegex, dropzoneIdRegex} from "../components/forms/ServiceSelector";
import usePageScriptRunner from "../hooks/usePageScriptRunner";
// test for commit
const customerInputForm = new CustomerInputForm(true);

const CustomerForm = () => {
  usePageScriptRunner(); // 어떤 역할을 하는 것인지 찾아봐야 합니다. 실제 삽입하지 않았을 때에, 계속 로딩중이었습니다.
  const location = useLocation();
  const history = useHistory();
  const basePath = location.pathname.match(basePathRegex)?.[0] ?? "/";
  const dropzoneId = basePath.match(dropzoneIdRegex)?.[1] ?? 0;
  const destinationRef = React.useRef();

  const {d: destination = "KR", n, s, r, upsid = null, sid = null, key = null} = util.getQS(); // MyPage에서 트래킹 넘버를 r로 넘겨서 가져와 Edit할 수 있게 도와줍니다.

  customerInputForm.dropzoneId = dropzoneId;
  customerInputForm.onSave = async (data) => {

    let res;
    if (destination === 'CANADA' && n === 'C' && s === 'MA') {

      res = await api.saveBLDataForUPS(dropzoneId, r, {
        upsId: Number(upsid),
        staffId: Number(sid),
        keyStr: key,
        ...data,
      });
      if (res) {
        util.showSuccessUPS("Request has been saved successfully!", history);
        {
          (!upsid && !sid && !key) &&
          customerInputForm.clearValuesFast();
        }
      }
    } else {

      const res = await api.saveBLData(dropzoneId, r, data);

      if (res) {
        util.showSuccess("Request has been saved successfully!");
        customerInputForm.clearValuesFast();
        history.push(`/my-page`);
      }
    }
  };


  customerInputForm.destination = destination;

  React.useEffect(() => {
    // URL getValue 추가
    const {s, n} = util.getQS();
    customerInputForm.setValue("serviceType", s);
    customerInputForm.kind = n;
  }, []);


  React.useEffect(() => {
    const token = sessionStorage.getItem("@token");

    if (destination === 'CANADA' && n === 'C' && s === 'MA' && token === null) {
      return;
    } else {
      if (token) {
        return;
      } else {
        history.push("/login");
      }
    }
  });

  return <div className={"common-page"}>{customerInputForm.renderForm()}</div>;
};

export default CustomerForm;
