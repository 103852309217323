/**
 * @flow
 */
import React from 'react';
import {Link} from 'react-router-dom';
import {useUser} from '../services/userReducer';

const containerStyle = {
  right: 20,
  bottom: 20,
  zIndex: 10000,
  display: 'flex',
  flexDirection: 'column',
};

const IMAGE_SIZE = 55;
const KAKAO_URL = 'https://pf.kakao.com/_dLNBd';

const KakaoTalkLink = () => {
  const user = useUser();
  return (
    <div className="position-fixed" style={containerStyle}>
      <Link to={user.partner_id ? '/my-page' : '/login'} className={'mb-3'}>
        <img src={'/assets/public/images/login.png'} width={IMAGE_SIZE} height={IMAGE_SIZE} alt={'user'} />
      </Link>
      <a href={KAKAO_URL} target={'_blank'} rel="noopener noreferrer">
        <img src={'/assets/public/images/kakaotalk.png'} width={IMAGE_SIZE} height={IMAGE_SIZE} alt={'KakaoTalk Page'} />
      </a>
    </div>
  );
};

export default KakaoTalkLink;
