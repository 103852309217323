/**
 * @flow
 */
import {createBrowserHistory} from 'history';
import moment from 'moment';
import queryString from 'query-string';
import {showDialog, showDialogUPS} from '../components/services/Dialog'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import {util} from "./service";


const history = createBrowserHistory();

const qtyMask = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: '',
  allowDecimal: false,
});

const priceMask = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: '',
  allowDecimal: true,
});

class Util {
  MASK_QTY = qtyMask;
  MASK_PRICE = priceMask;

  dispatch;

  nav(path, state) {
    history.push(path, state);
  }

  getFormDataFromEvent(e) {
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData(e.target);
    const res = {};
    for (const [name, value] of formData.entries()) {
      res[name] = value;
    }
    return res;
  }

  getClientWidth() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  dateFormatter(p) {
    return p.value ? moment(p.value).format('YYYY-MM-DD') : '';
  }

  getQS() {
    return queryString.parse(document.location.search);
  }

  getPickupDate(partnerId, pickupDay, pickupTime) {
    // const dropzone = dropzoneOptions.find(i => i.value === partnerId);
    // const {pickupDay, pickupTime} = dropzone ?? {};
    if (pickupDay && pickupTime) {
      const daysStart = {
        Sun: 0, Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6,
      };
      const daysInfo = [
        ['Sun', false, ''],
        ['Mon', false, ''],
        ['Tue', false, ''],
        ['Wed', false, ''],
        ['Thu', false, ''],
        ['Fri', false, ''],
        ['Sat', false, ''],
        ['Sun', false, ''],
        ['Mon', false, ''],
        ['Tue', false, ''],
        ['Wed', false, ''],
        ['Thu', false, ''],
        ['Fri', false, ''],
        ['Sat', false, ''],
      ];
      let startDate = moment().startOf('week');
      daysInfo.forEach((dayInfo, index) => {
        const [day, allowed] = dayInfo;
        if (pickupDay.find(d => d === day)) {
          daysInfo[index][1] = true;
        }
        daysInfo[index][2] = startDate.format('YYYY-MM-DD');
        startDate = startDate.add(1, 'day');
      });
      const todayDay = moment().format('ddd');
      const todayDate = moment().format('YYYY-MM-DD');
      const todayTime = moment().format('A');
      const todayIndex = daysStart[todayDay];
      let pickupDayInfo;
      for (let i = todayIndex; i < daysInfo.length; ++i) {
        const [day, allowed, date] = daysInfo[i];
        if (allowed) {
          if (date === todayDate) {
            if (pickupTime === 'AM' && todayTime === 'PM') {

              pickupDayInfo = {pickupDate: moment(date).toDate(), pickupTime: todayTime};
              break;
            }
          } else {

            pickupDayInfo = {pickupDate: moment(date).toDate(), pickupTime: todayTime};
            break;
          }
        }
      }
      return pickupDayInfo;
    }
  }

  formatD(ts) {
    return ts ? moment(ts).format('YYYY-MM-DD') : '';
  }

  showSuccess(message) {
    showDialog(message, 'Success', 'success');
  }

  //항공택배 한국=>캐나다용 Success 메세지 출력 함수
  showSuccessUPS(message, history) {

    const {upsid = null, sid = null, key = null} = util.getQS();

    const onConfirm = () => {
      if (upsid && sid && key) {
        return
      } else {
        history.push('/')
      }
    }
    showDialogUPS(message, 'Success', 'success', onConfirm);
  }

  showWarning(message) {
    showDialog(message, 'Warning', 'warning');
  }

  showError(message = 'Something went wrong!') {
    showDialog(message, 'Error', 'danger');
  }

  showConfirm(message, onConfirm) {
    showDialog(message, 'Confirm', 'primary', onConfirm);
  }

  toFloat(value: any) {
    return typeof value === 'string' ? parseFloat(value) : value;
  }

  toTS(date) {
    if (date instanceof Date) return date.getTime();
    if (date === null || date === undefined) return date;
    return moment(date).toDate().getTime();
  }

  toInt(value: any) {
    return typeof value === 'string' ? parseInt(value) : value;
  }

  // showWarning(message) {
  //   showDialog(message, 'Warning', 'warning');
  // }
}


export default Util;
