/**
 * @flow
 */
import React from 'react';
import {AlertError, AlertSuccess, Col, Row} from './formHelper';
import {updateUser, useUser} from '../../services/userReducer';
import {api, util} from '../../services/service';
import type {UserState} from '../../services/userReducer';
import useForm from './useForm';
import {useLabels} from '../../services/langReducer';

const ProfileForm = () => {
  const form = useProfileForm();
  const labels = useLabels();
  return (
    <section id="contact1" className="contact text-center" style={{paddingTop: 20}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
            <div className="heading text-center mb-50">
              <h2 className="heading__title">{labels.editProfileLabel}</h2>
              <div className="divider__line divider__theme divider__center mb-0" />
            </div>{/* /.heading */}
          </div>{/* /.col-lg-6 */}
        </div>{/* /.row */}
        <div className="row text-left">
          <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
            {form.render()}
          </div>{/* /.col-lg-8 */}
        </div>{/* /.row */}
      </div>{/* /.container */}
    </section>
  );
};

function useProfileForm() {
  const user = useUser();
  const labels = useLabels();
  return useForm({
    fields: [
      {name: 'localName', label: labels.localNameLabel, required: true},
      {name: 'engName', label: labels.engNameLabel, required: true},
      {name: 'email', label: labels.emailLabel, required: true},
      {name: 'phone', label: labels.phoneLabel, required: true},
      {name: 'pwd', label: labels.passwordLabel, required: false, type: 'password'},
      {name: 'password2', label: labels.password2Label, required: false, type: 'password'},
      {name: 'localAddress', label: labels.addressLabel, required: false},
    ],
    onInit: async (values, setValues) => {
      const res = await api.queryUser(user['partner_id']);
      const {local_name: localName, eng_name: engName, email, phone, address: localAddress} = res ?? {};
      setValues({
        localName, engName, email, phone, localAddress
      });
    },
    onValidate: (values) => {
      const {localName, engName, email, pwd = '', password2 = '', phone, localAddress} = values;
      let error = null;
      if (pwd.length > 0 && pwd !== password2) {
        error = labels.passwordError;
      } else if (pwd.length > 0 && pwd.length < 6) {
        error = labels.passwordLengthError;
      } else if (!(localName && engName && email && phone)) {
        error = labels.requiredError;
      }
      if (error) {
        util.scrollToTop();
      }
      return error;
    },
    onSubmit: async (values, setSuccess, setError) => {
      const {localName, engName, email, pwd, password2, phone, localAddress} = values;
      setSuccess(undefined);
      const res = await api.registerUser({localName, engName, email, pwd, phone, localAddress, id: user.partner_id});
      if (res === null) {
        setError(labels.registerFailedError);
        setSuccess(false);
      } else {
        setSuccess(true);
        const newUser: UserState = {
          ...user, local_name: localName, eng_name: engName, phone, address: localAddress,
        };
        updateUser(util.dispatch, newUser);
        sessionStorage.setItem('@user', JSON.stringify(newUser));
      }
      util.scrollToTop();
    },
    onRender: ({fields, values, setValues, success, setSuccess, error, setError, _r}) => {
      return (
        <>
          {error && (
            <Row>
              <Col col={12}>
                <AlertError message={error} onClose={() => setError(null)} />
              </Col>
            </Row>
          )}
          {success && (
            <Row>
              <Col col={12}>
                <AlertSuccess message={labels.editProfileSuccess} onClose={() => setSuccess(undefined)} />
              </Col>
            </Row>
          )}
          <Row>
            <Col col={6}>{_r('localName')}</Col>
            <Col col={6}>{/*_r('engName')*/}</Col>
          </Row>
          <Row>
            <Col col={6}>{_r('email')}</Col>
            <Col col={6}>{_r('phone')}</Col>
          </Row>
          <Row>
            <Col col={6}>{_r('pwd')}</Col>
            <Col col={6}>{_r('password2')}</Col>
          </Row>
          <Row>
            <Col col={12}>{_r('localAddress')}</Col>
          </Row>
          <Row>
            <Col col={12}>
              <button type="submit" className="btn btn__secondary btn__hover3">{labels.editProfileLabel}</button>
            </Col>{/* /.col-lg-12 */}
          </Row>
        </>
      );
    },
  });
}

export default ProfileForm;
