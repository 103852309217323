/**
 * @flow
 */
import React from 'react';
import {useLocation} from 'react-router-dom';
import {useServicePageScriptRunner} from '../hooks/usePageScriptRunner';
import Moving from '../components/services/Moving';
import Cashmall from '../components/services/Cashmall';
import Forwarding from '../components/services/Forwarding';
import Vehicle from '../components/services/Vehicle';
import Courier from '../components/services/Courier';
import ECommerce from '../components/services/ECommerce';

const serviceMap = {
  'moving': Moving,
  'vehicle': Vehicle,
  'courier': Courier,
  'cashmall': Cashmall,
  'forwarding': Forwarding,
  'ecommerce': ECommerce,
};

const Services = () => {
  const location = useLocation();
  const path = location.pathname.substring(1);
  const Section = serviceMap[path];
  useServicePageScriptRunner(false, path);
  return (
    <div className="common-page">
      {Section ? <Section /> : null}
    </div>
  );
};

export default Services;
