/**
 * @flow
 */
import React from 'react';
import useForm from './useForm';
import {useLabels} from '../../services/langReducer';
import trackingFormImage from '../../media/trackingFormImage.jpg'
import {useLocation} from 'react-router-dom';

const TrackingForm = ({onTrack, blNo}) => {
  const trackingFormVideo = 'https://www.youtube.com/watch?v=SC8L5HoY3sk'
  const form = useTrackingForm(onTrack, blNo);
  const labels = useLabels();
  
  // React.useEffect(() => {
  //   if(match.params['trackingNo']){
  //     blNo = match.params['trackingNo'];
  //   }
  // }, [match])
  
  return (
    <section id="contact1" className="contact text-center" style={{paddingTop: 20, paddingBottom: 0}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
            <div className="heading text-center mb-50">
              <h2 className="heading__title">{labels.trackingLabel}</h2>
              <div className="divider__line divider__theme divider__center mb-0" />
            </div>{/* /.heading */}
          </div>{/* /.col-lg-6 */}
        </div>{/* /.row */}

        {/* Video */}
        <div style={{position: 'relative'}}>
            <img src={trackingFormImage} alt="background" style={{width: '64%', marginBottom: '30px'}} />
            {trackingFormVideo && (
              <div className="d-flex justify-content-center align-items-center" style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0}}>
                <div className="video__btn align-v-h">
                  <a className="popup-video" href={trackingFormVideo}>
                    <span className="video__player-animation" />
                    <div className="video__player">
                      <i className="fa fa-play" />
                    </div>
                  </a>
                </div>
              </div>
            )}
          </div>

        <div className="row text-left">
          <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
            {form.render()}
          </div>{/* /.col-lg-8 */}
        </div>{/* /.row */}
      </div>{/* /.container */}
    </section>
  );
};

function useTrackingForm(onTrack, blNo) {
  const labels = useLabels();
  return useForm({
    fields: [
      {name: 'trackingNo', placeholder: 'Tracking No.', required: true},
    ],
    onInit: (values, setValues) => {},
    onValidate: (values) => {return null;},
    onSubmit: async (values, setSuccess, setError) => {
      const {trackingNo} = values;
      if (trackingNo) {
        onTrack(trackingNo);
      }
    },
    onRender: ({fields, values, setValues, success, setSuccess, error, setError, _r}) => {
      return (
        <div className={'d-flex w-100 flex-wrap'}>
          <div className={'flex-grow-1 mr-2'} style={{minWidth: 300}}>
            {_r('trackingNo')}
          </div>
          <div className="mr-2 mb-4">
            <button type="submit" className="btn btn__secondary btn__hover3">{labels.submitLabel}</button>
          </div>
        </div>
      );
    },
  });
}

export default TrackingForm;
