/**
 * @flow
 */
import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {api, util} from '../../services/service';
import {AlertError, AlertSuccess, CheckField, Col, Field, Row} from './formHelper';
import {useLabels} from '../../services/langReducer';
import {useUser} from '../../services/userReducer';

export const LoginRegisterForm = () => {
  const labels = useLabels();
  return (
    <section id="requestQuoteTabs" className="request-quote request-quote-tabs pt-0 bg-gray">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="request__form">
              <nav className="nav nav-tabs">
                <a className="nav__link active" data-toggle="tab" href="#login">
                  <div className="request__form-header d-flex align-items-center">
                    <i className="icon-box" />
                    <h4 className="request__form-title">{labels.loginLabel}</h4>
                  </div>{/* /.request-form-header */}
                </a>
                <a className="nav__link" data-toggle="tab" href="#register">
                  <div className="request__form-header d-flex align-items-center">
                    <i className="icon-worldwide" />
                    <h4 className="request__form-title">{labels.registerLabel}</h4>
                  </div>{/* /.request-form-header */}
                </a>
              </nav>
              <div className="tab-content">
                <div className="tab-pane fade show active" id="login">
                  <LoginForm />
                </div>{/* /.tab */}
                <div className="tab-pane fade" id="register">
                  <RegisterForm />
                </div>{/* /.tab */}
              </div>{/* /.tab-content */}
            </div>{/* /.request-form */}
          </div>{/* /.col-lg-12 */}
        </div>{/* /.row */}
      </div>{/* /.container */}
    </section>
  );
};

export const RegisterLoginForm = () => {
  const labels = useLabels();
  return (
    <section id="requestQuoteTabs" className="request-quote request-quote-tabs pt-0 bg-gray">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="request__form">
              <nav className="nav nav-tabs">
                <a className="nav__link" data-toggle="tab" href="#login">
                  <div className="request__form-header d-flex align-items-center">
                    <i className="icon-box" />
                    <h4 className="request__form-title">{labels.loginLabel}</h4>
                  </div>{/* /.request-form-header */}
                </a>
                <a className="nav__link active" data-toggle="tab" href="#register">
                  <div className="request__form-header d-flex align-items-center">
                    <i className="icon-worldwide" />
                    <h4 className="request__form-title">{labels.registerLabel}</h4>
                  </div>{/* /.request-form-header */}
                </a>
              </nav>
              <div className="tab-content">
                <div className="tab-pane fade" id="login">
                  <LoginForm />
                </div>{/* /.tab */}
                <div className="tab-pane fade show active" id="register">
                  <RegisterForm />
                </div>{/* /.tab */}
              </div>{/* /.tab-content */}
            </div>{/* /.request-form */}
          </div>{/* /.col-lg-12 */}
        </div>{/* /.row */}
      </div>{/* /.container */}
    </section>
  );
};

const HowCanWeHelpYou = () => {
  const labels = useLabels();
  return (
    <div className="widget widget-help bg-theme">
      <div className="widget__content">
        <h5>{labels.howCanWeHelp}</h5>
        <p>{labels.weUnderstand}</p>
        <Link to={'/contact'} className="btn btn__secondary btn__hover2">
          <i className="fa fa-envelope" />
          {labels.contactUsLabel}
        </Link>
      </div>{/* /.widget-download */}
    </div>
  );
};

const LoginForm = () => {
  const [loginError, setLoginError] = React.useState(false);
  const history = useHistory();
  const labels = useLabels();
  const user = useUser()
  // React.useEffect(() => {
  //   if (user.ready === true && !user.partner_id) {
  //     // 게스트 모드인 경우 토큰을 요청
  //     api.queryToken().catch();
  //   }
  // }, [user]);
  return (
    <form onSubmit={async (e) => {
      const {email: loginId, password, remember} = util.getFormDataFromEvent(e);
      const trust = remember === 'on';
      if (loginId && password) {
        await api.queryToken();
        const res = await api.login({loginId, password, trust});
        if (res === null) {
          setLoginError(true);
        } else {
          history.push('/');
        }
      } else {
        window.scrollTo(0, 0);
        setLoginError(true);
      }
    }}>
      <div className="request__form-body">
        <Row>
          <Col col={8}>
            <Row>
              {loginError && (
                <Col col={12}>
                  <AlertError message={labels.loginError} onClose={() => setLoginError('')} />
                </Col>
              )}
              <Col col={12}><Field name={'email'} label={labels.emailLabel} icon={'envelope'} type={'email'} /></Col>
              <Col col={12}><Field name={'password'} label={labels.passwordLabel} icon={'lock'} type={'password'} /></Col>
              <Col col={12} className="mb-20 d-flex justify-content-between flex-wrap">
                <CheckField name={'remember'} label={labels.rememberLabel} />
                <a href={'#forgot'} onClick={(e) => {
                  e.preventDefault();
                  history.push('/reset-password');
                }}>{labels.forgotLabel}</a>
              </Col>
              <Col col={12}><button className="btn btn__secondary btn__block" type={'submit'}>{labels.loginLabel}</button></Col>
            </Row>
          </Col>
          <Col col={4}>
            <HowCanWeHelpYou />
          </Col>
        </Row>
      </div>
    </form>
  );
};

const RegisterForm = () => {
  const [registerError, setRegisterError] = React.useState('');
  const [registerSuccess, setRegisterSuccess] = React.useState(false);
  const labels = useLabels();
  const user = useUser()
  // React.useEffect(() => {
  //   if (user.ready === true && !user.partner_id) {
  //     // 게스트 모드인 경우 토큰을 요청
  //     api.queryToken().catch();
  //   }
  // }, [user]);
  if (registerSuccess) {
    return (
      <Row>
        <Col col={8}>
          <AlertSuccess message={labels.registerSuccess} onClose={() => setRegisterSuccess(false)} />
          <div className={'ml-40'}>
            <button className="btn btn__secondary btn__block" onClick={() => {
              document.location = '/login';
            }}>
              {labels.loginLabel}
            </button>
          </div>
        </Col>
        <Col col={4}>
          <HowCanWeHelpYou />
        </Col>
      </Row>
    );
  }
  return (
    <form onSubmit={async (e) => {
      const {
        localName, email, pwd, password2, phone, localAddress, consent
      } = util.getFormDataFromEvent(e);
      if (!(localName && email && pwd && phone)) {
        window.scrollTo(0, 0);
        setRegisterError(labels.requiredError);
        return;
      }
      if (pwd !== password2) {
        window.scrollTo(0, 0);
        setRegisterError(labels.passwordError);
        return;
      }
      if (pwd.length < 6) {
        window.scrollTo(0, 0);
        setRegisterError(labels.passwordLengthError);
        return;
      }
      if (consent !== 'on') {
        window.scrollTo(0, 0);
        setRegisterError(labels.consentError);
        return;
      }
      await api.queryToken();
      const res = await api.registerUser({localName, engName: localName, email, pwd, phone, localAddress});
      if (!res) {
        window.scrollTo(0, 0);
        setRegisterError(labels.registerFailedError);
      } else if (typeof res === 'string') {
        window.scrollTo(0, 0);
        setRegisterError(res);
      } else {
        window.scrollTo(0, 0);
        setRegisterSuccess(true);
      }
    }}>
      <div className="request__form-body">
        <Row>
          <Col col={8}>
            <Row>
              {registerError && (
                <Col col={12}>
                  <AlertError message={registerError} onClose={() => setRegisterError('')} />
                </Col>
              )}
              <Col col={6}><Field name={'localName'} label={labels.localNameLabel} required /></Col>
              <Col col={6} />
              <Col col={6}><Field name={'email'} label={labels.emailLabel} required type={"email"}/></Col>
              <Col col={6}><Field name={'phone'} label={labels.phoneLabel} required type={"number"}/></Col>
              <Col col={6}><Field name={'pwd'} label={labels.passwordLabel} required type={'password'} /></Col>
              <Col col={6}><Field name={'password2'} label={labels.password2Label} required type={'password'} /></Col>
              <Col col={12}><Field name={'localAddress'} label={labels.addressLabel} /></Col>
              <Col col={12} className="mb-20"><CheckField name={'consent'} label={labels.consentLabel} /></Col>
              <Col col={12}>
                <button className="btn btn__secondary btn__block" type={'submit'}>{labels.registerLabel}</button>
              </Col>
            </Row>
          </Col>
          <Col col={4}>
            <HowCanWeHelpYou />
          </Col>
        </Row>
      </div>
    </form>
  );
};
