/**
 * @flow
 */
import React from "react";

export default function usePageScriptRunner(watchScroll = false) {
  React.useEffect(() => {
    // Global variables
    // eslint-disable-next-line no-undef
    const jQ = $;
    const $win = jQ(window);

    /*==========  Pre Loading   ==========*/
    // $win.on('load', function () {
    //   jQ(".preloader").fadeOut(4000);
    //   jQ(".preloader").remove();
    // });
    jQ(".preloader").fadeOut(4000);
    jQ(".preloader").remove();

    /*==========   Mobile Menu   ==========*/
    let $navToggler = jQ(".navbar-toggler");
    const navTogglerClick = () => {
      $navToggler.toggleClass("actived");
      jQ(".navbar-collapse").toggleClass("menu-opened");
    };
    let $navMenuItem = jQ(".nav__item-link");
    const navMenuItemClick = () => {

      $navToggler.toggleClass("actived");
      jQ(".navbar-collapse").toggleClass("menu-opened");
      // console.log('menu item click', new Date());
    };

    $navToggler.on("click", navTogglerClick);
    $navMenuItem.on("click", navMenuItemClick);

    /*==========   Sticky Navbar   ==========*/
    const handleScroll = function () {
      if (watchScroll === true) {
        if ($win.width() >= 992) {
          let $navbar = jQ(".navbar");
          if ($win.scrollTop() > 80) {
            $navbar.addClass("fixed-navbar");
          } else {
            $navbar.removeClass("fixed-navbar");
          }
        }
      }
    };
    $win.on("scroll", handleScroll);

    /*==========  Popup Search   ==========*/
    // Show Module Search
    // jQ('.module__btn-search').on('click', function (e) {
    //   e.preventDefault();
    //   jQ('.module__search-container').toggleClass('active', 'inActive').removeClass('inActive');
    // });
    // Close Module Search
    // jQ('.close-search').on('click', function () {
    //   jQ('.module__search-container').removeClass('active').addClass('inActive');
    // });

    /*==========   Scroll Top Button   ==========*/
    // let $scrollTopBtn = jQ('#scrollTopBtn');
    // // Show Scroll Top Button
    // $win.on('scroll', function () {
    //   if (jQ(this).scrollTop() > 700) {
    //     $scrollTopBtn.addClass('actived');
    //   } else {
    //     $scrollTopBtn.removeClass('actived');
    //   }
    // });
    // // Animate Body after Clicking on Scroll Top Button
    // $scrollTopBtn.on('click', function () {
    //   jQ('html, body').animate({
    //     scrollTop: 0
    //   }, 500);
    // });

    /*==========   Equal Height Elements   ==========*/
    let maxHeight = 0;
    jQ(".equal-height").each(function () {
      if (jQ(this).height() > maxHeight) {
        maxHeight = jQ(this).height();
      }
    });
    jQ(".equal-height").height(maxHeight);

    /*==========   Set Background-img to section   ==========*/
    //메인 홈 화면 백그라운으 정의 코드
    jQ(".bg-img").each(function () {
      let imgSrc = jQ(this).children("img").attr("src");
      jQ(this)
        .parent()
        .css({
          "background-image": "url(" + imgSrc + ")",
          "background-size": "cover",
          "background-position": "center",
        });
      jQ(this).parent().addClass("bg-img");
      jQ(this).remove();
    });

    /*==========   Add active class to accordions   ==========*/
    const accordionHeaderClickHandler = function () {
      jQ(this).addClass("opened");
      jQ(this).parent().siblings().find(".accordion__item-header").removeClass("opened");
    };
    const accordionTitleClickHandler = function (e) {
      e.preventDefault();
    };
    jQ(".accordion__item-header").on("click", accordionHeaderClickHandler);
    jQ(".accordion__item-title").on("click", accordionTitleClickHandler);

    /*==========   Load More Items  ==========*/
    // function loadMore(loadMoreBtn, loadedItem) {
    //   jQ(loadMoreBtn).on('click', function (e) {
    //     e.preventDefault();
    //     jQ(this).fadeOut();
    //     jQ(loadedItem).fadeIn();
    //   })
    // }
    //
    // loadMore('.loadMoreBlog', '.hidden-blog-item');
    // loadMore('.loadMoreServices', '.hidden-service');
    // loadMore('.loadMoreProjects', '.project-hidden > .project-item');

    /*==========   Add Animation to About Img ==========*/
    const $about = jQ(".about");
    const aboutHandler = () => {
      if ($about.length > 0) {
        try {
          let skillsOffset = $about.offset().top - 200,
            skillsHight = $win.outerHeight(),
            winScrollTop = $win.scrollTop();
          if (winScrollTop > skillsOffset - 1 && winScrollTop < skillsOffset + skillsHight - 1) {
            jQ(".about__img").addClass("animate-img");
          }
        } catch (error) {
          // 무시함!
        }
      }
    };
    $win.on("scroll", aboutHandler);
    // if ($about.length > 0) {
    //   jQ(window).on('scroll', function () {
    //     try {
    //       let skillsOffset = jQ(".about").offset().top - 200,
    //         skillsHight = jQ(this).outerHeight(),
    //         winScrollTop = jQ(window).scrollTop();
    //       if (winScrollTop > skillsOffset - 1 && winScrollTop < skillsOffset + skillsHight - 1) {
    //         jQ('.about__img').addClass('animate-img');
    //       }
    //     } catch (error) {
    //       // 무시함!
    //     }
    //   });
    // }

    /*==========   Owl Carousel  ==========*/
    jQ(".carousel").each(function () {
      jQ(this).owlCarousel({
        nav: jQ(this).data("nav"),
        dots: jQ(this).data("dots"),
        loop: jQ(this).data("loop"),
        margin: jQ(this).data("space"),
        center: jQ(this).data("center"),
        dotsSpeed: jQ(this).data("speed"),
        transitionStyle: jQ(this).data("transition"),
        animateOut: jQ(this).data("animate-out"),
        animateIn: jQ(this).data("animate-in"),
        autoplay: jQ(this).data("autoplay"),
        autoplayTimeout: jQ(this).data("autoplay-timeout") || 5000,
        autoplayHoverPause: jQ(this).data("autoplay-hover-pause") || false,
        responsive: {
          0: {
            items: 1,
          },
          400: {
            items: jQ(this).data("slide-sm"),
          },
          700: {
            items: jQ(this).data("slide-md"),
          },
          1000: {
            items: jQ(this).data("slide"),
          },
        },
      });
    });
    // Owl Carousel With Thumbnails
    jQ(".thumbs-carousel").owlCarousel({
      thumbs: true,
      thumbsPrerendered: true,
      loop: true,
      margin: 0,
      nav: jQ(this).data("nav"),
      dots: jQ(this).data("dots"),
      dotsSpeed: jQ(this).data("speed"),
      transitionStyle: jQ(this).data("transition"),
      animateOut: jQ(this).data("animate-out"),
      animateIn: jQ(this).data("animate-in"),
      autoplay: jQ(this).data("autoplay"),
      autoplayTimeout: jQ(this).data("autoplay-timeout") || 5000,
      autoplayHoverPause: jQ(this).data("autoplay-hover-pause") || false,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        1000: {
          items: 1,
        },
      },
    });

    /*==========  Popup Video  ==========*/
    jQ(".popup-video").magnificPopup({
      mainClass: "mfp-fade",
      removalDelay: 0,
      preloader: false,
      fixedContentPos: false,
      type: "iframe",
      iframe: {
        markup:
          '<div class="mfp-iframe-scaler">' +
          '<div class="mfp-close"></div>' +
          '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
          "</div>",
        patterns: {
          youtube: {
            index: "youtube.com/",
            id: "v=",
            src: "//www.youtube.com/embed/%id%?autoplay=1",
          },
        },
        srcAction: "iframe_src",
      },
    });

    /*==========   counterUp  ==========*/
    jQ(".counter").counterUp({
      delay: 10,
      time: 4000,
    });

    /*==========   Projects Filtering and Sorting  ==========*/
    const projectFilterClickHandler = (e) => {
      e.preventDefault();
    };
    jQ("#filtered-items-wrap").mixItUp();
    jQ(".projects-filter li a").on("click", projectFilterClickHandler);
    window.scrollTo(0, 0);

    // console.log('[pageScriptRunner] initialized!');
    setTimeout(() => {
      $navMenuItem = jQ(".nav__item-link");
      $navMenuItem.off("click", navMenuItemClick);
      $navMenuItem.on("click", navMenuItemClick);
      // console.log('[pageScriptRunner] event handler reassigned!');
    }, 1000);

    return () => {
      $win.off("scroll", handleScroll);
      $win.off("scroll", aboutHandler);
      $navToggler.off("click", navTogglerClick);
      $navMenuItem.off("click", navMenuItemClick);
      jQ(".projects-filter li a").off("click", projectFilterClickHandler);
      jQ(".accordion__item-header").off("click", accordionHeaderClickHandler);
      jQ(".accordion__item-title").off("click", accordionTitleClickHandler);
    };
  }, []);
}
export function useServicePageScriptRunner(watchScroll = false, path) {
  React.useEffect(() => {
    // console.log(`[Script Runner] ${path}`);
    // Global variables
    // eslint-disable-next-line no-undef
    const jQ = $;
    const $win = jQ(window);

    /*==========  Pre Loading   ==========*/
    // $win.on('load', function () {
    //   jQ(".preloader").fadeOut(4000);
    //   jQ(".preloader").remove();
    // });
    jQ(".preloader").fadeOut(4000);
    jQ(".preloader").remove();

    /*==========   Mobile Menu   ==========*/
    let $navToggler = jQ(".navbar-toggler");
    const navTogglerClick = () => {
      $navToggler.toggleClass("actived");
      jQ(".navbar-collapse").toggleClass("menu-opened");
    };
    let $navMenuItem = jQ(".nav__item-link");
    const navMenuItemClick = () => {

      $navToggler.toggleClass("actived");
      jQ(".navbar-collapse").toggleClass("menu-opened");
      // console.log('menu item click', new Date());
    };

    $navToggler.on("click", navTogglerClick);
    $navMenuItem.on("click", navMenuItemClick);

    /*==========   Sticky Navbar   ==========*/
    const handleScroll = function () {
      if (watchScroll === true) {
        if ($win.width() >= 992) {
          let $navbar = jQ(".navbar");
          if ($win.scrollTop() > 80) {
            $navbar.addClass("fixed-navbar");
          } else {
            $navbar.removeClass("fixed-navbar");
          }
        }
      }
    };
    $win.on("scroll", handleScroll);

    /*==========  Popup Search   ==========*/
    // Show Module Search
    // jQ('.module__btn-search').on('click', function (e) {
    //   e.preventDefault();
    //   jQ('.module__search-container').toggleClass('active', 'inActive').removeClass('inActive');
    // });
    // Close Module Search
    // jQ('.close-search').on('click', function () {
    //   jQ('.module__search-container').removeClass('active').addClass('inActive');
    // });

    /*==========   Scroll Top Button   ==========*/
    // let $scrollTopBtn = jQ('#scrollTopBtn');
    // // Show Scroll Top Button
    // $win.on('scroll', function () {
    //   if (jQ(this).scrollTop() > 700) {
    //     $scrollTopBtn.addClass('actived');
    //   } else {
    //     $scrollTopBtn.removeClass('actived');
    //   }
    // });
    // // Animate Body after Clicking on Scroll Top Button
    // $scrollTopBtn.on('click', function () {
    //   jQ('html, body').animate({
    //     scrollTop: 0
    //   }, 500);
    // });

    /*==========   Equal Height Elements   ==========*/
    let maxHeight = 0;
    jQ(".equal-height").each(function () {
      if (jQ(this).height() > maxHeight) {
        maxHeight = jQ(this).height();
      }
    });
    jQ(".equal-height").height(maxHeight);

    /*==========   Set Background-img to section   ==========*/
    jQ(".bg-img").each(function () {
      let imgSrc = jQ(this).children("img").attr("src");
      jQ(this)
        .parent()
        .css({
          "background-image": "url(" + imgSrc + ")",
          "background-size": "cover",
          "background-position": "center",
        });
      jQ(this).parent().addClass("bg-img");
      jQ(this).remove();
    });

    /*==========   Add active class to accordions   ==========*/
    const accordionHeaderClickHandler = function () {
      jQ(this).addClass("opened");
      jQ(this).parent().siblings().find(".accordion__item-header").removeClass("opened");
    };
    const accordionTitleClickHandler = function (e) {
      e.preventDefault();
    };
    jQ(".accordion__item-header").on("click", accordionHeaderClickHandler);
    jQ(".accordion__item-title").on("click", accordionTitleClickHandler);

    /*==========   Load More Items  ==========*/
    // function loadMore(loadMoreBtn, loadedItem) {
    //   jQ(loadMoreBtn).on('click', function (e) {
    //     e.preventDefault();
    //     jQ(this).fadeOut();
    //     jQ(loadedItem).fadeIn();
    //   })
    // }
    //
    // loadMore('.loadMoreBlog', '.hidden-blog-item');
    // loadMore('.loadMoreServices', '.hidden-service');
    // loadMore('.loadMoreProjects', '.project-hidden > .project-item');

    /*==========   Add Animation to About Img ==========*/
    const $about = jQ(".about");
    const aboutHandler = () => {
      if ($about.length > 0) {
        try {
          let skillsOffset = $about.offset().top - 200,
            skillsHight = $win.outerHeight(),
            winScrollTop = $win.scrollTop();
          if (winScrollTop > skillsOffset - 1 && winScrollTop < skillsOffset + skillsHight - 1) {
            jQ(".about__img").addClass("animate-img");
          }
        } catch (error) {
          // 무시함!
        }
      }
    };
    $win.on("scroll", aboutHandler);
    // if ($about.length > 0) {
    //   jQ(window).on('scroll', function () {
    //     try {
    //       let skillsOffset = jQ(".about").offset().top - 200,
    //         skillsHight = jQ(this).outerHeight(),
    //         winScrollTop = jQ(window).scrollTop();
    //       if (winScrollTop > skillsOffset - 1 && winScrollTop < skillsOffset + skillsHight - 1) {
    //         jQ('.about__img').addClass('animate-img');
    //       }
    //     } catch (error) {
    //       // 무시함!
    //     }
    //   });
    // }

    /*==========   Owl Carousel  ==========*/
    jQ(".carousel").each(function () {
      jQ(this).owlCarousel({
        nav: jQ(this).data("nav"),
        dots: jQ(this).data("dots"),
        loop: jQ(this).data("loop"),
        margin: jQ(this).data("space"),
        center: jQ(this).data("center"),
        dotsSpeed: jQ(this).data("speed"),
        transitionStyle: jQ(this).data("transition"),
        animateOut: jQ(this).data("animate-out"),
        animateIn: jQ(this).data("animate-in"),
        autoplay: jQ(this).data("autoplay"),
        autoplayTimeout: jQ(this).data("autoplay-timeout") || 5000,
        autoplayHoverPause: jQ(this).data("autoplay-hover-pause") || false,
        responsive: {
          0: {
            items: 1,
          },
          400: {
            items: jQ(this).data("slide-sm"),
          },
          700: {
            items: jQ(this).data("slide-md"),
          },
          1000: {
            items: jQ(this).data("slide"),
          },
        },
      });
    });
    // Owl Carousel With Thumbnails
    jQ(".thumbs-carousel").owlCarousel({
      thumbs: true,
      thumbsPrerendered: true,
      loop: true,
      margin: 0,
      nav: jQ(this).data("nav"),
      dots: jQ(this).data("dots"),
      dotsSpeed: jQ(this).data("speed"),
      transitionStyle: jQ(this).data("transition"),
      animateOut: jQ(this).data("animate-out"),
      animateIn: jQ(this).data("animate-in"),
      autoplay: jQ(this).data("autoplay"),
      autoplayTimeout: jQ(this).data("autoplay-timeout") || 5000,
      autoplayHoverPause: jQ(this).data("autoplay-hover-pause") || false,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        1000: {
          items: 1,
        },
      },
    });

    /*==========  Popup Video  ==========*/
    jQ(".popup-video").magnificPopup({
      mainClass: "mfp-fade",
      removalDelay: 0,
      preloader: false,
      fixedContentPos: false,
      type: "iframe",
      iframe: {
        markup:
          '<div class="mfp-iframe-scaler">' +
          '<div class="mfp-close"></div>' +
          '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
          "</div>",
        patterns: {
          youtube: {
            index: "youtube.com/",
            id: "v=",
            src: "//www.youtube.com/embed/%id%?autoplay=1",
          },
        },
        srcAction: "iframe_src",
      },
    });

    /*==========   counterUp  ==========*/
    jQ(".counter").counterUp({
      delay: 10,
      time: 4000,
    });

    /*==========   Projects Filtering and Sorting  ==========*/
    const projectFilterClickHandler = (e) => {
      e.preventDefault();
    };
    jQ("#filtered-items-wrap").mixItUp();
    jQ(".projects-filter li a").on("click", projectFilterClickHandler);
    window.scrollTo(0, 0);

    // console.log('[pageScriptRunner] initialized!');
    setTimeout(() => {

      $navMenuItem.off("click", navMenuItemClick);
      $navMenuItem.on("click", navMenuItemClick);
      // console.log('[pageScriptRunner] event handler reassigned!');
    }, 1000);

    return () => {
      $win.off("scroll", handleScroll);
      $win.off("scroll", aboutHandler);
      $navToggler.off("click", navTogglerClick);
      $navMenuItem.off("click", navMenuItemClick);
      jQ(".projects-filter li a").off("click", projectFilterClickHandler);
      jQ(".accordion__item-header").off("click", accordionHeaderClickHandler);
      jQ(".accordion__item-title").off("click", accordionTitleClickHandler);
    };
  }, [path]);
}
