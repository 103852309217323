const formLabels = {
  loginLabel: 'Login',
  registerLabel: 'Register',
  emailLabel: 'Email',
  passwordLabel: 'Password',
  rememberLabel: 'Remember',
  forgotLabel: 'Forgot password?',
  howCanWeHelp: 'How Can We Help You!',
  weUnderstand: `We understand the importance approaching each work integrally and believe in the power of simple and easy communication.`,
  contactUsLabel: 'Contact Us',
  localNameLabel: 'Name (Local)',
  engNameLabel: 'Name (English)',
  password2Label: 'Password (Confirm)',
  phoneLabel: 'Phone',
  addressLabel: 'Address',
  consentLabel: 'I agree to the terms and conditions.',
  loginError: 'Please check your email and password.',
  requiredError: 'Please enter all the required fields.',
  passwordError: 'Please check your password.',
  passwordLengthError: 'Password must be more than 6 characters.',
  consentError: 'Please check the terms and conditions.',
  registerFailedError: 'Failed to register. Please try it later.',
  registerSuccess: 'Registration has been processed successfully.',
  editProfileLabel: 'Edit Profile',
  editProfileSuccess: 'Profile has been saved successfully.',
  requestQuoteLabel: 'Request A Quote',
  nameLabel: 'Name',
  departureLabel: 'Departure',
  destinationLabel: 'Destination',
  serviceTypeLabel: 'Service Type',
  pleaseSelectLabel: 'Please select...',
  serviceTypeOptions: [
    'Overseas Moving',
    'Vehicle Transport',
    'International Courier',
    'Shipping Agent',
    'Import/Export Cargo',
    'E-COMMERCE SOLUTION',
  ],
  remarkLabel: 'Remark',
  submitLabel: 'Submit',
  companyLabel: 'Company',
  requestFailedError: 'Failed to request. Please try it later.',
  requestSuccess: 'Request a quote has been processed successfully.',
  contactUsSuccess: 'Request has been processed successfully.',
  trackingLabel: 'Tracking',
};

export default formLabels;
