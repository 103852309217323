/**
 * @flow
 */
import React from "react";

const Overview = ({overviewText, background, video}) => {
  return (
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-12 mb-50">
        <div className="text__block">
          <p className="text__block-desc">{overviewText}</p>
          <div style={{position: 'relative'}}>
            <img src={`assets/public/images/services/${background}`} alt="background" />
            {video && (
              <div className="d-flex justify-content-center align-items-center" style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0}}>
                <div className="video__btn align-v-h">
                  <a className="popup-video" href={video}>
                    <span className="video__player-animation" />
                    <div className="video__player">
                      <i className="fa fa-play" />
                    </div>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>{/* /.text-block */}
      </div>{/* /.col-lg-12 */}
    </div>
  );
};

export default Overview;
