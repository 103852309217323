/**
 * @flow
 */
import React from 'react';
import {Link} from 'react-router-dom';
import {useLang} from '../../services/langReducer';

const CoshipHistory = () => {
  const lang = useLang();
  const projectItems = window[lang === 'en' ? 'projectItemsEn' : 'projectItemsKo'];
  return (
    <section id="carouselTabs" className="carousel-tabs pb-70">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
            <div className="heading text-center mb-50">
              {/*<span className="heading__subtitle color-white">Latest Case Studies</span>*/}
              <h2 className="heading__title color-theme">COSHIP HISTORY</h2>
              <div className="divider__line divider__theme divider__center" />
            </div>{/* /.heading */}
          </div>{/* /.col-lg-6 */}
        </div>{/* /.row */}
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="tab-content">
              <div className="tab-pane fade show active" id="tab1">
                <div className="projects-carousel-3  carousel owl-carousel carousel-dots" data-slide={3} data-slide-md={2} data-slide-sm={1} data-autoplay="true" data-nav="false" data-dots="true" data-space={30} data-loop="true" data-speed={800}>
                  {projectItems.map((item, index) => {
                    return (
                      <div className="project-item" key={index}>
                        <div className="project__img">
                          <img src={`assets/public/images/home/work/${item.image}`} alt="project img" />
                          {/*<Link to={''} className="zoom__icon" />*/}
                        </div>{/* /.project-img */}
                        <div className="project__content">
                          {/*<div className="project__cat">*/}
                          {/*  <a href="#">Analystics</a><a href="#">Optimization</a>*/}
                          {/*</div>/!* /.project-cat *!/*/}
                          <h4 className="project__title"><Link to={item.url}>{item.title}</Link></h4>
                          <p className="project__desc">{item.desc}</p>
                        </div>{/* /.project-content */}
                      </div>
                    );
                  })}
                </div>{/* /.carousel */}
              </div>{/* /.tab-pane */}
            </div>{/* /.tab-content */}
          </div>{/* /.col-lg-12 */}
        </div>{/* /.row */}
      </div>{/* /.container */}
    </section>
  );
};

export default CoshipHistory;
