/**
 * @flow
 */
import React from 'react';
import usePageScriptRunner from '../hooks/usePageScriptRunner';
import {LoginRegisterForm} from '../components/forms/RegisterLoginForm';
import useGuestOnly from '../hooks/useGuestOnly';

const Login = () => {
  useGuestOnly();
  usePageScriptRunner();
  return (
    <div className="common-page">
      <LoginRegisterForm />
    </div>
  );
};

export default Login;
