/**
 * @flow
 */
import React from 'react';
import usePageScriptRunner from '../hooks/usePageScriptRunner';
import {useLabels, usePrivacyLabels} from '../services/langReducer';

const Privacy = () => {
  usePageScriptRunner();
  const commonLabels = useLabels();
  const labels = usePrivacyLabels();
  return (
    <div className="common-page">
      <section id="caseStudiesSingle" className="pt-20">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
              <div className="heading text-center mb-50">
                <h2 className="heading__title">{commonLabels.privacyLabel}</h2>
                <div className="divider__line divider__theme divider__center mb-0" />
              </div>{/* /.heading */}
            </div>{/* /.col-lg-6 */}
          </div>{/* /.row */}
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12'>
              {labels.privacyLines.map((label, key) => {
                return (
                  <p key={key} className='my-2'>{label}</p>
                );
              })}
            </div>
          </div>
          {/*<div className='row'>*/}
          {/*  <div className='col-sm-12 col-md-12 col-lg-12'>*/}
          {/*    <h6 style={{lineHeight: 1.3}}>{labels.privacyTitle1}</h6>*/}
          {/*    <h6 style={{lineHeight: 1.3}}>{labels.privacyTitle2}</h6>*/}
          {/*    {labels.privacyItems.map((label, key) => <p key={key}>{label}</p>)}*/}
          {/*    {labels.privacyItems.map((label, key) => {*/}
          {/*      const contents = labels[`privacyItem${key + 1}`];*/}
          {/*      return (*/}
          {/*        <div className='mt-50 mb-20' key={key}>*/}
          {/*          <h6>{label}</h6>*/}
          {/*          {contents.map((label, i) => <p key={i} className='my-1'>{label}</p>)}*/}
          {/*        </div>*/}
          {/*      );*/}
          {/*    })}*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>{/* /.container */}
      </section>
    </div>
  );
};

export default Privacy;
