/**
 * @flow
 */
import React from 'react';
import Slider from '../components/home/Slider';
import WhyCoship from '../components/home/WhyCoship';
import CoshipHistory from '../components/home/CoshipHistory';
import GlobalLocations from '../components/home/GlobalLocations';
import GoogleMap from '../components/home/GoogleMap';
import usePageScriptRunner from '../hooks/usePageScriptRunner';

const Home = () => {

  usePageScriptRunner(true);

  return (
    <>
      <Slider />
      <CoshipHistory />
      <WhyCoship />
      <GlobalLocations />
      <GoogleMap />
    </>
  );
};

export default Home;
