/**
 * @flow
 */
import {useSelector} from 'react-redux';

const LANG_UPDATE = 'LANG_UPDATE';
const LANG_RESET = 'LANG_RESET';
const DEFAULT_LANG = 'ko';

type LangType = 'en' | 'ko';

export function updateLang(dispatch, lang: LangType) {
  localStorage.setItem('@lang', lang);
  dispatch({type: LANG_UPDATE, lang});
}

export function resetLang(dispatch) {
  dispatch({type: LANG_RESET});
}

export interface LangState {
  lang: LangType;
}

export function useLang(): LangState {
  return useSelector(state => state['lang']['lang'] ?? DEFAULT_LANG);
}

export function useLabels() {
  const lang = useLang();
  return window[lang === 'en' ? 'labelsEn' : 'labelsKo'];
}

export function useAboutLabels() {
  const lang = useLang();
  return window[lang === 'en' ? 'aboutLabelsEn' : 'aboutLabelsKo'];
}

export function usePrivacyLabels() {
  return window['privacyLabels'];
}

export function useTermsLabels() {
  return window['termsLabels'];
}

const initialState: LangState = {
  lang: localStorage.getItem('@lang') ?? DEFAULT_LANG,
};

export default function langReducer(state: LangState = initialState, action) {
  const {type, lang} = action;
  switch (type) {
    case LANG_UPDATE:
      return {lang};
    case LANG_RESET:
      return {};
    default:
      return state;
  }
}
