/**
 * @flow
 */
import React from 'react';
import {useLabels, useLang} from '../../services/langReducer';
import ServicePage, {getServiceLabels} from './ServicePage';
import BottomSlide from './BottomSlide';
import HeaderCashmall from './HeaderCashmall';

const Cashmall = () => {
  const lang = useLang();
  const langName = lang === 'ko' ? 'korean' : 'english';
  const labels = useLabels();
  const serviceLabels = getServiceLabels(labels);
  const serviceLabel = window[lang === 'ko' ? 'cashmallLabelsKo' : 'cashmallLabelsEn'];
  const imagePath = 'assets/public/images/services/cashmall';
  const title = labels.menuServiceAgent;
  const background = `cashmall/01.${langName}.jpg`;
  const {overviewText, video} = serviceLabel;
  const header = (<HeaderCashmall langName={langName} />);
  return (
    <ServicePage title={title} aside={{labels, serviceLabels}} overview={{background, video, overviewText}} header={header}>
      {/*진행과정*/}
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="text__block-3">
            <h5 className="text__block-title">{serviceLabel.title1}</h5>
          </div>
          <img src={`${imagePath}/02.${langName}.jpg`} alt="chart" className="img-fluid mb-50" />
        </div>{/* /.col-lg-12 */}
      </div>{/* /.row */}
      {/*배송대행 상품 반품 및 교환 처리*/}
      <div className="row mb-30">
        <div className="col-sm-12 col-md-12 col-lg-12 mb-30">
          <div className="text__block">
            <h5 className="text__block-title">{serviceLabel.title2}</h5>
            {serviceLabel.desc2Items.map((label, key) => <p className='text__block-desc' key={key}>{label}</p>)}
          </div>{/* /.text-block */}
        </div>{/* /.col-lg-12 */}
        <div className="col-sm-12 col-md-12 col-lg-6 mb-30">
          <div className="text__block">
            <h5 className="text__block-title">{serviceLabel.title3}</h5>
            <p className="text__block-desc">{serviceLabel.desc3_1}</p>
            <p className="text__block-desc">{serviceLabel.desc3_2}</p>
          </div>{/* /.text-block */}
        </div>{/* /.col-lg-12 */}
        <div className="col-sm-12 col-md-12 col-lg-6 mb-30">
          <div className="text__block">
            <h5 className="text__block-title">{serviceLabel.title4}</h5>
            <p className="text__block-desc">{serviceLabel.desc4_1}</p>
            <p className="text__block-desc">{serviceLabel.desc4_2}</p>
          </div>{/* /.text-block */}
        </div>{/* /.col-lg-12 */}
      </div>{/* /.row */}
      {/*이미지 슬라이드*/}
      <div className='row mt-50'>
        <div className='col-sm-12 col-md-12 col-lg-12'>
          <BottomSlide imagePath={imagePath} items={serviceLabel.slideItems} />
        </div>
      </div>
    </ServicePage>
  );
};

export default Cashmall;
