/**
 * @flow
 */
import React from 'react';
import usePageScriptRunner from '../hooks/usePageScriptRunner';
import useGuestOnly from '../hooks/useGuestOnly';
import ResetPasswordForm from '../components/forms/ResetPasswordForm';

const ResetPassword = () => {
  useGuestOnly();
  usePageScriptRunner();
  return (
    <>
      <div className="common-page">
        <div className={'container'}>
          <ResetPasswordForm onSubmit={(email) => {
            // console.log('hello', email);
            // TODO: api call for reset password!
          }} />
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
