/**
 * @flow
 */
import React from 'react';
import {Link} from 'react-router-dom';

interface Breadcrumb {
  label: string;
  path: string;
}

interface PageTitleProps {
  title: string;
  breadcrumbs: Breadcrumb[];
  bgImage?: string;
  className?: string
}

const PageTitle = (props: PageTitleProps) => {
  const {bgImage = 'assets/public/images/page-titles/6.jpg'} = props;
  return (
    <section id="page-title" className={`page-title ${props.className} bg-overlay bg-parallax`}>
      <div className="bg-img"><img src={bgImage} alt="background" /></div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                {props.breadcrumbs.map(({label, path}) => {
                  return (
                    <li className="breadcrumb-item" key={label}><Link to={path}>{label}</Link></li>
                  );
                })}
                <li className="breadcrumb-item active" aria-current="page">{props.title}</li>
              </ol>
            </nav>
            {/*<h1 className="pagetitle__heading mb-20">{props.title}</h1>*/}
          </div>{/* /.col-lg-12 */}
        </div>{/* /.row */}
      </div>{/* /.container */}
    </section>
  );
};

export default PageTitle;
