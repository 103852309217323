/**
 * @flow
 */
import React from 'react';
import {AlertError, AlertSuccess, Col, Field, Row, TextArea} from './formHelper';
import {useUser} from '../../services/userReducer';
import {api, util} from '../../services/service';
import {useLabels} from '../../services/langReducer';

const ContactForm = () => {
  const [editError, setEditError] = React.useState('');
  const [editSuccess, setEditSuccess] = React.useState(false);
  const user = useUser()
  const formRef = React.useRef();
  const labels = useLabels();
  // React.useEffect(() => {
  //   if (user.ready === true && !user.partner_id) {
  //     // 게스트 모드인 경우 토큰을 요청
  //     api.queryToken().catch();
  //   }
  // }, [user]);
  function validate({customerName, email, bigo, phone}) {
    if (!(customerName && email && bigo && phone)) {
      return labels.requiredError;
    }
  }
  async function handleSubmit(e) {
    const {
      customerName, serviceType = 'Contact Us', email, phone, fromCountry, toCountry, address, bigo
    } = util.getFormDataFromEvent(e);
    const data = {
      customerName, serviceType, email, phone, fromCountry, toCountry, address, bigo,
    };
    const error = validate(data);
    if (error) {
      window.scrollTo(0, 0);
      setEditError(error);
      return;
    }
    if (!user.partner_id) {
      await api.queryToken();
    }
    const res = await api.requestQuote(data);
    if (!res) {
      setEditError(labels.requestFailedError);
      window.scrollTo(0, 0);
    } else if (typeof res === 'string') {
      setEditError(res);
      window.scrollTo(0, 0);
    } else {
      setEditSuccess(true);
      window.scrollTo(0, 0);
      formRef.current.reset();
    }
  }
  return (
    <section id="contact1" className="contact text-center" style={{paddingTop: 20, paddingBottom: 30}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
            <div className="heading text-center mb-50">
              <h2 className="heading__title">{labels.contactUsLabel}</h2>
              <div className="divider__line divider__theme divider__center mb-0" />
            </div>{/* /.heading */}
          </div>{/* /.col-lg-6 */}
        </div>{/* /.row */}
        <div className="row text-left">
          <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
            <form ref={formRef} onSubmit={handleSubmit}>
              {editError && (
                <Row>
                  <Col col={12}>
                    <AlertError message={editError} onClose={() => setEditError('')} />
                  </Col>
                </Row>
              )}
              {editSuccess && (
                <Row>
                  <Col col={12}>
                    <AlertSuccess message={labels.contactUsSuccess} onClose={() => setEditSuccess(false)} />
                  </Col>
                </Row>
              )}
              <Row>
                <Col col={6}><Field name={'customerName'} value={user.local_name ?? ''} label={labels.nameLabel} required /></Col>
                <Col col={6}><Field name={'address'} label={labels.companyLabel} /></Col>
              </Row>
              <Row>
                <Col col={6}><Field name={'email'} value={user.email} label={labels.emailLabel} type={"email"} required /></Col>
                <Col col={6}><Field name={'phone'} value={user.phone} label={labels.phoneLabel} type={"number"} required /></Col>
              </Row>
              <Row>
                <Col col={12}><TextArea name={'bigo'} label={labels.remarkLabel}  required /></Col>
              </Row>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <button type="submit" className="btn btn__secondary btn__hover3">{labels.submitLabel}</button>
                </div>{/* /.col-lg-12 */}
              </div>{/* /.row */}
            </form>
          </div>{/* /.col-lg-8 */}
        </div>{/* /.row */}
      </div>{/* /.container */}
    </section>
  );
};

export default ContactForm;
