/**
 * @flow
 */
import React from 'react';
import usePageScriptRunner from '../hooks/usePageScriptRunner';
import RequestForm from '../components/forms/RequestForm';

const Request = () => {
  usePageScriptRunner();
  return (
    <div className="common-page">
      <RequestForm />
    </div>
  );
};

export default Request;
