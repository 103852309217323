/**
 * @flow
 */
import React from 'react';
import usePageScriptRunner from '../hooks/usePageScriptRunner';
import ContactForm from '../components/forms/ContactForm';
import GlobalLocations from '../components/home/GlobalLocations';
import GoogleMap from '../components/home/GoogleMap';

const Contact = () => {
  usePageScriptRunner();
  return (
    <div className="common-page">
      <ContactForm />
      <GlobalLocations />
      <GoogleMap />
    </div>
  );
};

export default Contact;
