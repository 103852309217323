/**
 * @flow
 */
import React from "react";
import { useLang } from "../../services/langReducer";
import { Link } from "react-router-dom";
import usePageScriptRunner from "../../hooks/usePageScriptRunner";

//const SLIDER_TIMEOUT_SEC = process.env.NODE_ENV === "production" ? 5 : 30;

const Slider = () => {
  const lang = useLang();
  const sliderItems = window[lang === "en" ? "sliderItemsEn" : "sliderItemsKo"] ?? [];
  // React.useEffect(() => {
  //   const timerId = setInterval(() => {
  //     const nextButton = document.querySelector(`.owl-next`);
  //     if (Array.isArray(nextButton) && nextButton.length > 0) {
  //       nextButton[0].click();
  //     } else {
  //       nextButton && nextButton.click();
  //     }
  //   }, SLIDER_TIMEOUT_SEC * 1000);
  //   return () => {
  //     clearInterval(timerId);
  //   };
  // }, []);
  return (
    <section id="slider1" className="slider slider-1">
      <div
        className="owl-carousel thumbs-carousel carousel-arrows"
        data-slider-id="slider1"
        data-loop="true"
        data-dots="false"
        data-autoplay="true"
        data-autoplay-timeout="1000"
        data-autoplay-hover-pause="false"
        data-speed="3000"
        data-nav="true"
        data-transition="fade"
        data-animate-out="fadeOut"
        data-animate-in="fadeIn"
      >
        {sliderItems.map((item) => {
          return (
            <div className="slide-item align-v-h bg-overlay" key={item.thumbIcon} style={{
                  backgroundImage: 'url(assets/public/images/home/slider/' + item.bgImage + ')',
                  backgroundSize:'cover',
                  backgroundPosition:'center'
                }}>
              {/*bg-img 는 jquery 코드로 item.bgImage 를 img 태그에 src로 가져워서 뿌림 */}
              {/*state가 변경 된다고 jquery 코드가 refresh가 안되므로 하단의 img를 코멘트 처리하고 상위 div에 직접 스타일을 걸음*/}
              {/*<div className="bg-img">*/}
              {/*  <img*/}
              {/*    src={`assets/public/images/home/slider/${item.bgImage}`}*/}
              {/*    alt="slide img"*/}
              {/*  />*/}
              {/*</div>*/}
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-9">
                    {/* Contents */}
                    {item.title !== " " && (
                      <div className="slide__content">
                        <h2 className="slide__title">{item.title}</h2>
                        <p className="slide__desc">{item.desc}</p>

                        <Link
                          to={item.buttonPath}
                          className="btn btn__white"
                          style={{ marginRight: 80 }}
                        >
                          {item.buttonLabel}
                        </Link>

                        <a
                          className="btn btn__video popup-video"
                          href={item.videoUrl}
                          style={{ width: 250 }}
                        >
                          <div className="video__player">
                            <span className="video__player-animation" />
                            <i className="fa fa-play" />
                          </div>
                          <div className="text-left pl-4">{item.videoLabel}</div>
                        </a>
                      </div>
                    )}
                  </div>
                  {/* Contents 끝 */}

                  {/* Promotion을 위한 contents */}
                  {
                    <div
                      className="promotion_contents"
                      style={{ margin: "300px auto 0px" }}
                    >
                      {item.promotionBtnKakao !== " " && item.title === " " && (
                        <div className="slide__content_ups">
                          <h2 className="slide__title">{item.title}</h2>
                          <p className="slide__desc">{item.desc}</p>

                          <a
                            href="http://pf.kakao.com/_dLNBd/chat"
                            target="_blank"
                            className="btn .btn__white"
                          >
                            <img src={item.promotionBtnKakao} alt="kakaoT UPS" />
                          </a>

                          <a
                            className="btn btn__video popup-video"
                            href={item.videoUrl}
                            style={{ marginLeft: "20px" }}
                          >
                            <div className="video__player">
                              <span className="video__player-animation" />
                              <i className="fa fa-play" />
                            </div>
                            <div className="text-left pl-4">{item.videoLabel}</div>
                          </a>
                        </div>
                      )}
                    </div>
                  }
                </div>
                {/* /.row */}
              </div>
              {/* /.container */}
            </div>
          );
        })}
      </div>
      {/* /.carousel */}
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="owl-thumbs thumbs-dots" data-slider-id="slider1">
              {sliderItems.map(({ thumbIcon: icon, thumbLabel: label }) => {
                return (
                  <button className="owl-thumb-item w-30" key={icon}>
                    <i className={`icon-${icon}`} />
                    <span className="d-none d-lg-inline">
                      <br />
                      {label}
                    </span>
                  </button>
                );
              })}
            </div>
            {/* /.owl-thumbs */}
          </div>
          {/* /.col-lg-12 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
};

export default Slider;
