/**
 * @flow
 */
import React from 'react';
import {useLabels, useLang} from '../../services/langReducer';
import ServicePage, {getServiceLabels} from './ServicePage';
import BottomSlide from './BottomSlide';
import Accordion from './Accordion';

const Courier = () => {
  const lang = useLang();
  const langName = lang === 'ko' ? 'korean' : 'english';
  const labels = useLabels();
  const serviceLabels = getServiceLabels(labels);
  const serviceLabel = window[lang === 'ko' ? 'courierLabelsKo' : 'courierLabelsEn'];
  const imagePath = 'assets/public/images/services/courier';
  const title = labels.menuServiceShipping;
  const background = `courier/01.${langName}.jpg`;
  const {overviewText, video} = serviceLabel;
  const accordionItems = [
    {
      title: 'FAQ',
      content: (
        <>
          {serviceLabel.faqItems.map((item, index) => {
            if (index === 6) {
              return (
                <div className='mb-4' key={index}>
                  <b className='d-inline-block mb-2'>Q. {item.title}</b>
                  <div>
                    <p>{item.content[0]}</p>
                    <p className='mt-20'>{item.content[1]}</p>
                    <p>{item.content[2]}</p>
                  </div>
                </div>
              );
            } else if (index === 10) {
              return (
                <div className='mb-4' key={index}>
                  <b className='d-inline-block mb-2'>Q. {item.title}</b>
                  <div>
                    <p>{item.content[0]}</p>
                    <p className='mt-20'>{item.content[1]}</p>
                  </div>
                </div>
              );
            } else {
              return (
                <div className='mb-4' key={index}>
                  <b className='d-inline-block mb-2'>Q. {item.title}</b>
                  <div>{item.content}</div>
                </div>
              );
            }
          })}
        </>
      ),
    },
  ];
  return (
    <ServicePage title={title} aside={{labels, serviceLabels}} overview={{background, video, overviewText}}>
      {/*선박택배*/}
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="text__block">
            <h5 className="text__block-title">{serviceLabel.title1}</h5>
            <p className="text__block-desc">{serviceLabel.desc1_1}</p>
            <img src={`${imagePath}/02.${langName}.jpg`} width={'100%'} className='mb-50 mt-20' alt={'maple box'} />
            <p className="text__block-desc">{serviceLabel.desc1_2}</p>
            <ul style={{listStyleType: 'disc', paddingLeft: '1em'}}>
              {serviceLabel.desc1Items.map((label, key) => <li key={key}>{label}</li>)}
            </ul>
          </div>{/* /.text-block */}
        </div>{/* /.col-lg-12 */}
      </div>{/* /.row */}


      {/*항공 (캐나다 한국만 가능)*/}
      <div className="row mt-50">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="text__block">
            <h5 className="text__block-title">{serviceLabel.title2}</h5>
            <h6 className="text__block-title">{serviceLabel.title2_1}</h6>
            {serviceLabel.desc2Items1.map((label, key) => <p className='text__block-desc' key={key}>{label}</p>)}
            <ul style={{listStyleType: 'disc', paddingLeft: '1em'}}>
              {serviceLabel.desc2Items2.map((label, key) => <li key={key}>{label}</li>)}
            </ul>
          </div>{/* /.text-block */}
        </div>{/* /.col-lg-12 */}
      </div>{/* /.row */}

      {/*항공 (한국->캐나다) */}


      {langName === 'korean' && (
        <div className="row mt-20">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="text__block">

            <h6 className="text__block-title">{serviceLabel.title2_2}</h6>

            {serviceLabel.desc2_2Items1.map((label, key) => <p className='text__block-desc' key={key}>{label}</p>)}
            <ul style={{listStyleType: 'disc', paddingLeft: '1em'}}>
              {serviceLabel.desc2_2Items2.map((label, key) => <li key={key}>{label}</li>)}
            </ul>
          </div>
        </div>
      </div>
      )}


      {/*캐나다내, 캐나다-미국 택배 서비스*/}
      <div className="row mt-50">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="text__block">
            <h5 className="text__block-title">{serviceLabel.title3}</h5>
            <p className="text__block-desc">{serviceLabel.desc3}</p>
            <ul style={{listStyleType: 'disc', paddingLeft: '1em'}}>
              {serviceLabel.desc3Items.map((label, key) => <li key={key}>{label}</li>)}
            </ul>
          </div>{/* /.text-block */}
        </div>{/* /.col-lg-12 */}
      </div>{/* /.row */}
      {/*캐나다쉬핑 택배 접수처*/}
      <div className="row mt-50">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="text__block">
            <h5 className="text__block-title">{serviceLabel.title4}</h5>
            <p className="text__block-desc">{serviceLabel.desc4}</p>
          </div>{/* /.text-block */}
        </div>{/* /.col-lg-12 */}
      </div>{/* /.row */}
      {/*폴더블 컨텐츠*/}
      <div className="row mt-50 mb-30">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <Accordion items={accordionItems} prefix={'main'} />
        </div>{/* /.col-lg-12 */}
      </div>
      {/*이미지 슬라이드*/}
      <div className='row mt-50'>
        <div className='col-sm-12 col-md-12 col-lg-12'>
          <BottomSlide imagePath={imagePath} items={serviceLabel.slideItems} />
        </div>
      </div>
    </ServicePage>
  );
};

export default Courier;
