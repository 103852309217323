/**
 * @flow
 */
import React from 'react';
import {AgGridReact, AgGridReactProps} from 'ag-grid-react';
import {ColumnApi, GridApi} from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

export interface UseGridParams extends AgGridReactProps {
  height?: number | string;
  className?: string;
}

export interface UseGrid {
  apiRef: React$Ref<GridApi>;
  colApiRef: React$Ref<ColumnApi>;
  rows: any;
  setRows: (rows: any) => void;
  isReady: boolean;
  render: () => React$Node;
}

export default function useGrid(params: UseGridParams): UseGrid {
  const apiRef = React.useRef<GridApi>();
  const colApiRef = React.useRef<ColumnApi>();
  const [rows, setRows] = React.useState(null);
  const [isReady, setReady] = React.useState(false);
  const {
    className, height,
    onGridReady, enableCellTextSelection = true,
    ...props
  } = params;
  const gridProps: AgGridReactProps = {
    ...props,
    rowData: rows,
    enableCellTextSelection,
    onGridReady(e) {
      apiRef.current = e.api;
      colApiRef.current = e.columnApi;
      onGridReady && onGridReady(e);
      setReady(true);
    }
  };
  return {
    apiRef,
    colApiRef,
    rows,
    setRows,
    isReady,
    render: () => {
      return (
        <div className={`ag-theme-alpine ${className}`} style={{height}}>
          <AgGridReact {...gridProps} />
        </div>
      );
    },
  };
}
