/**
 * @flow
 */
import React from 'react';
import {Link} from 'react-router-dom';
import {useLabels, useLang} from '../services/langReducer';

const Footer = () => {
  const labels = useLabels();
  return (
    <footer id="footer" className="footer footer-1">
      <div className="container">
        <div className="footer-bottom">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-3 footer__widget footer__widget-about">
              <div className="footer__widget-content">
                {/*<a href={'test'} onClick={async (e) => {*/}
                {/*  e.preventDefault();*/}
                {/*  e.stopPropagation();*/}
                {/*}}>*/}
                  <img src="/assets/public/images/logo/logo-dark.png" alt="logo" className="mb-30" width={190} />
                {/*</a>*/}
              </div>
            </div>{/* /.col-lg-4 */}
            <div className="col-sm-12 col-md-9 col-lg-9">
              <div className="footer__copyright">
                <nav>
                  <ul className="list-unstyled footer__copyright-links d-flex flex-wrap">
                    <li><Link to={'/terms'}>{labels.termsLabel}</Link></li>
                    <li><Link to={'/privacy'}>{labels.privacyLabel}</Link></li>
                    {/*{lang === 'ko' && <li><Link to={'/email-policy'}>{labels.emailPolicyLabel}</Link></li>}*/}
                    {/*<li><a href="#">Sitemap</a></li>*/}
                    {/*<li><a href="#">Employee login</a></li>*/}
                  </ul>
                </nav>
                <span>© {(new Date()).getFullYear()} COSHIP, All rights reserved.</span>
                {/*<a href="http://themeforest.net/user/7oroof">7oroof.com</a>*/}
              </div>{/* /.Footer-copyright */}
            </div>{/* /.col-lg-6 */}
            {/*<div className="col-sm-12 col-md-3 col-lg-3 d-flex align-items-center">*/}
            {/*  <div className="social__icons justify-content-end w-100">*/}
            {/*    <a href="#"><i className="fa fa-facebook" /></a>*/}
            {/*    <a href="#"><i className="fa fa-twitter" /></a>*/}
            {/*    <a href="#"><i className="fa fa-instagram" /></a>*/}
            {/*    <a href="#"><i className="fa fa-linkedin" /></a>*/}
            {/*  </div>/!* /.social-icons *!/*/}
            {/*</div>/!* /.col-lg-6 *!/*/}
          </div>{/* /.row */}
        </div>{/* /.Footer-bottom */}
      </div>{/* /.container */}
    </footer>
  );
};

export default Footer;
