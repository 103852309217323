/**
 * @flow
 */
import React from "react";
import {useHistory, useLocation} from "react-router-dom";
import {Container, Row, Col, Card, CardBody, Button} from "reactstrap";
import FormManager from "../../lib/FormManager";
import {useSimpleGrid} from "../services/SimpleGrid";
import {useSimpleEditModal} from "../services/SimpleModal";
import ItemInputForm from "./ItemInputForm";
//import {DefaultButton, PrimaryButton} from '../components/buttons';
import {api, util} from "../../services/service";
import useConsentModal from "../modals/useConsentModal";
import * as yup from "yup";
import {useUser} from "../../services/userReducer";
import _c from "classnames";
import {AlertError} from "./formHelper";
import CustomerForm from "../../pages/CustomerForm";
import airUrl from "../../media/air.jpg";
import oceanUrl from "../../media/maplebox.jpg";
import upsUrl from "../../media/cpost_ups.jpg";
import ka_coship from "../../media/ka_coship.jpg";
import {template} from "lodash";

let cAEng;

export interface ButtonProps {
  label: string;
  onClick?: () => void;
  className?: string;
  nomargin?: boolean;
  size?: string;
  disabled?: boolean;
  type?: string;
  style?: Object;
}

export const DefaultButton = (props: ButtonProps) => {
  const className = _c({"no-margin": props.nomargin}, props.className);
  return (
    <Button
      type={props.type}
      size={props.size ?? "sm"}
      onClick={props.onClick}
      className={className}
      disabled={props.disabled}
      style={props.style}
    >
      {props.label}
    </Button>
  );
};

export const PrimaryButton = (props: ButtonProps) => {
  const className = _c({"no-margin": props.nomargin}, props.className);
  return (
    <Button
      type={props.type}
      size={props.size ?? "sm"}
      color={""}
      onClick={props.onClick}
      className={className}
      disabled={props.disabled}
    >
      {props.label}
    </Button>
  );
};

const emailSchema = yup.string().email();

class CustomerInputForm extends FormManager {
  onSave;
  forCustomer;
  grid;
  dropzoneId;
  gridData;
  serverData;
  destination;
  kind;

  constructor(forCustomer: boolean) {
    super({
      prefix: "ci-form",
      fields: (() => {
        const fieldsCopy = JSON.parse(JSON.stringify(fields));
        for (const field of fieldsCopy) {
          field.noAutoFill = forCustomer === true;
        }
        return fieldsCopy;
      })(),
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
        horizontal: false,
        onSubmit: () => {
          const {s, d} = util.getQS();
          const consent = this.getValue(FN.CONSENT);
          const fromEmail = this.getValue(FN.FROM_EMAIL);
          const toEmail = this.getValue(FN.TO_EMAIL);
          const visaType = this.getValue(FN.VISA_TYPE);
          const returnDate = this.getValue(FN.RETURN_DATE);

          if (this.forCustomer === true && consent?.[0] !== "on") {
            util.showWarning("Please check terms and conditions.");
            return;
          }
          const fromEmailValid = emailSchema.isValidSync(fromEmail);
          const toEmailValid = emailSchema.isValidSync(toEmail);
          if (!fromEmailValid) {
            util.showWarning("Please input a valid departure(shipper) email.");
            return;
          }
          if (!toEmailValid) {
            util.showWarning("Please input a valid destination(consignee) email.");
            return;
          }
          if (!this.grid.rows) {
            util.showWarning("Please input a Invoice & Packing List.");
            return;
          }
          if (s === "MO") {
            if (!returnDate) {
              util.showWarning("Please input a Return Date.");
              return;
            }
            if (!visaType) {
              util.showWarning("Please input a Visa Type.");
              return;
            }
          }
          this.onSave(this.getValuesToSubmit());
        },
      },
    });
    this.forCustomer = forCustomer;
  }

  onRender = () => {
    const [totalPrice, setTotalPrice] = React.useState(0);
    const [onDelBtnForBlNo, setOnDelBtnForBlNo] = React.useState("");
    const user = useUser();
    const history = useHistory();
    const modal = useConsentModal("OK", () => modal.close());
    const grid = useItemGrid();
    const {renderField: _r, getValue: _v, setValue: _c, getField: _f} = this;
    this.grid = grid;
    const serviceType = _v(FN.SERVICE_TYPE);
    const destination = this.destination ?? _v(FN.DESTINATION);
    const kind = this.kind ?? _v(FN.KIND);
    const isUPS = serviceType === "CP" || serviceType === "UPS";
    const isAir = serviceType === "MA";
    const isOcean = serviceType === "MO";
    const isVendor = user.partner_type === "VD";
    const isFromCanada = destination === "KOREA";
    const isFromKorea = destination === "CANADA";
    let label = "";

    if (isUPS) {
      label = "";
    } else if (this.destination === "KOREA") {
      label = " (캐나다 ⟶ 한국)";
    } else if (this.destination === "CANADA") {
      label = " (한국 ⟶ 캐나다)";
    }
    const onTerms = (e) => {
      const {s, d} = util.getQS();
      e.preventDefault();
      modal.open(s, d);
    };
    const onCalc = async () => {
      const grossWeight = parseFloat(_v(FN.TOTAL_WEIGHT));
      const volumeWeight = parseFloat(_v(FN.VOLUME));
      if (grossWeight > 0 && volumeWeight > 0) {
        const {data: [{totalPrice = ""}] = [{}]} = await api.getFreightPrice({
          portType: _v(FN.SERVICE_TYPE) === "MO" ? "O" : "A",
          grossWeightKg: grossWeight,
          length: util.toFloat(length),
          width: util.toFloat(width),
          height: util.toFloat(height),
          packageCount: util.toInt(packages),
        });
        _c(FN.SERVICE_CHARGE, totalPrice);
      } else {
        util.showWarning("Please input length, width, height, packages!");
      }
    };
    const type = _v(FN.TO_TYPE);
    const length = _v(FN.LENGTH);
    const width = _v(FN.WIDTH);
    const height = _v(FN.HEIGHT);
    const packages = _v(FN.PACKAGES);
    const pickupType = _v(FN.PICKUP_TYPE);
    const isDropOff = isVendor || pickupType !== "REQUEST PICKUP";
    // set total price
    const onCalcTotalPrice = () => {
      let tPrice = 0;
      this.grid.rows.forEach((row) => {
        tPrice += row.totalPrice;
      });
      setTotalPrice(tPrice);
    };
    //UPS type 자동 입력 위한 useEffect
    React.useEffect(() => {
      const userValue = window["pickUpLocation"].find((item) => item.value === type);

      if (userValue !== undefined) {
        _c(FN.TO_ADDR, userValue.address);
        _c(FN.TO_CITY, userValue.city);
        _c(FN.TO_POSTAL, userValue.zip);
        _c(FN.TO_PROVINCE, userValue.province);
      }
    }, [type]);


    //UPS 창에서 수정 url이 들어 왔을 경우 데이터를 자동으로 채워지게 하는 useEffect
    const {upsid = null, sid = null, key = null} = util.getQS()
    React.useEffect(() => {
      if (upsid && sid && key) {
        api.getUPSData({
          upsId: Number(upsid),
          staffId: sid === 'null' ? sid : Number(sid),
          keyStr: key
        }).then((res) => {
            const editJcommon = res?.data[0]?.jcommon
            const editJcustomer = res?.data[0]?.jcustomer
            const editJshipment = res?.data[0]?.jshipment

            _c(FN.CONSENT, ['on']);

            //jcustomer -shipper
            _c(FN.FROM_NAME_ENG, editJcustomer.shipper);
            _c(FN.FROM_NAME_KOR, editJcustomer.shipperLocal);
            _c(FN.FROM_ADDR, editJcustomer.shipperAddr);
            _c(FN.FROM_ADDR2, editJcustomer.shipperAddr2);
            _c(FN.FROM_CITY, editJcustomer.shipperCity);
            _c(FN.FROM_PROVINCE, editJcustomer.shipperProvince);
            _c(FN.FROM_COUNTRY, editJcustomer.shipperCountry);
            _c(FN.FROM_PHONE, editJcustomer.shipperPhone);
            _c(FN.FROM_PHONE2, editJcustomer.shipperPhone2);
            _c(FN.FROM_EMAIL, editJcustomer.shipperEmail);
            _c(FN.FROM_POSTAL, editJcustomer.shipperPostalCode);
            //jcustomer -consignee
            _c(FN.TO_NAME_ENG, editJcustomer.consignee);
            _c(FN.TO_NAME_KOR, editJcustomer.consigneeLocal);
            _c(FN.TO_ADDR, editJcustomer.consigneeAddr);
            _c(FN.TO_ADDR2, editJcustomer.consigneeAddr2);
            _c(FN.TO_ADDR, editJcustomer.consigneeAddrLocal);
            _c(FN.TO_CITY, editJcustomer.consigneeCity);
            _c(FN.TO_PROVINCE, editJcustomer.consigneeProvince);
            _c(FN.TO_COUNTRY, editJcustomer.consigneeCountry);
            _c(FN.TO_PHONE, editJcustomer.consigneePhone1);
            _c(FN.TO_PHONE2, editJcustomer.consigneePhone2);
            _c(FN.TO_POSTAL, editJcustomer.consigneePostalCode);
            _c(FN.TO_EMAIL, editJcustomer.consigneeEmail);
            //jcommon
            _c(FN.TO_TYPE, editJcommon.sendType);
            _c(FN.REMARK, editJcommon.remark);
            //jshipment
            _c(FN.TOTAL_WEIGHT, editJshipment.grossWeightKg);
            _c(FN.WIDTH, editJshipment.dzWidth);
            _c(FN.HEIGHT, editJshipment.dzHeight);
            _c(FN.LENGTH, editJshipment.dzLength);
            this.grid.setRows([...editJshipment.gridData]);
        })
      }
    }, []);


    React.useEffect(() => {
      if (this.grid.rows) {
        onCalcTotalPrice();
      }
    }, [this.grid.rows]);


    //선박이거나 항공일때
    React.useEffect(() => {

      const volumeWeight = (length * width * height * packages) / 5000;
      if (!isNaN(volumeWeight) && volumeWeight > 0) {
        _c(FN.VOLUME, volumeWeight.toFixed(2));
      } else {
        _c(FN.VOLUME, "");
      }
    }, [length, width, height, packages]);

    React.useEffect(() => {
      const {id: blId} = util.getQS();
      if (isDropOff) {
        const {partner_id, pickup_day, pickup_time} = user;
        const info = util.getPickupDate(partner_id, pickup_day, pickup_time);
        if (info) {
          _c(FN.PICKUP_DATE, util.formatD(info.pickupDate));
          _c(FN.PICKUP_TIME, info.pickupTime);
        }
      } else {
        if (pickupType !== undefined && blId === undefined) {
          // 수정모드가 아닌 경우에만 값을 리셋해줌
          _c(FN.PICKUP_DATE, "");
          _c(FN.PICKUP_TIME, "");
          _c(FN.LENGTH, "");
          _c(FN.WIDTH, "");
          _c(FN.HEIGHT, "");
          _c(FN.TOTAL_WEIGHT, "");
          _c(FN.VOLUME, "");
          _c(FN.SERVICE_CHARGE, "");
          _c(FN.DEPOSIT, "");
        }
      }
    }, [pickupType]);
    React.useEffect(() => {
      // 마이페이지에서 수정버튼을 눌러서 넘어오면 하단에서 데이터를 input에 set한다.
      const {r} = util.getQS();
      if (r) {
        setOnDelBtnForBlNo(r);
        api.getEditData(r).then((res) => {
          this.setValuesFromServer(res.data.data[0]);
        });
      }

      const {id: blId} = util.getQS();
      if (blId && user.partner_type === "DZ") {
        _f(FN.BL_NO).readonly = true;
      } else {
        _f(FN.BL_NO).readonly = false;
      }
      if (blId && user.partner_type === "VD") {
        _c(FN.PICKUP_TYPE, "DROP OFF");
      }
      if (!blId) {
        setTotalPrice(0);
      }
    }, []);
    React.useEffect(() => {
      if (this.forCustomer && isFromCanada && !isUPS) {
        _c(FN.FROM_COUNTRY, "CANADA");
        _c(FN.TO_COUNTRY, "KOREA");
      }
      if (this.forCustomer && isFromKorea && !isUPS) {
        _c(FN.FROM_COUNTRY, "KOREA");
        _c(FN.TO_COUNTRY, "CANADA");
      }
      if (isUPS) {
        _c(FN.FROM_COUNTRY, "");
        _c(FN.TO_COUNTRY, "");
        _c(FN.DESTINATION, "");
      }
    }, [isUPS, isAir, isFromCanada, isOcean, isFromKorea]);
    React.useEffect(() => {
      if (!isUPS) {
        if (destination) {
          _c(FN.FROM_COUNTRY, destination === "KOREA" ? "CANADA" : "KOREA");
          _c(FN.TO_COUNTRY, destination);
        }
      }
    }, [destination]);

    this.setFieldRequired();
    _f(FN.FROM_EMAIL).required = !isVendor;

    const onDelEditData = (onDelBtnForBlNo) => {
      api.delEditData(onDelBtnForBlNo).then((res) => {
        if (res.data["err_message"]?.length > 0) {
          util.showWarning(`${res.data.err_message}`);
        } else {
          return;
        }
      });
      history.push("/my-page");
    };

    const noteOnWriting = () => {
      const {s, d} = util.getQS();
      switch (s + d) {
        case "MAKOREA":
          return (
            <div className={"mb-3"} style={{color: "#535050"}}>
              <h5>작성시 유의사항</h5>
              <Row>
                <Col md={12} lg={6}>
                  * 한국주소는 한글로 새 도로명주소를 적어주세요.
                </Col>
                <Col>* 우편번호 5자리를 기재해 주세요.</Col>
              </Row>
              <Row>
                <Col md={12} lg={6}>
                  * 물품내역은 가급적 영어로 적어주세요.
                </Col>
                <Col>* USER와 NEW를 구분해주세요. (NEW 제품은 브랜드 명 기입)</Col>
              </Row>
              <Row>
                <Col md={12} lg={6}>
                  * 물품 총 비용이 $150 이상이면 과세대상자입니다.
                </Col>
              </Row>
              <Row>
                <Col md={12} lg={12} className={"text-danger"}>
                  * 받는 분의 통관부호 또는 주민번호 기재해주세요 (미기재 시 통관지연 또는
                  홀드) - 서류는 생년월일 기입
                </Col>
              </Row>
            </div>
          );
        case "MOKOREA":
          return (
            <div className={"mb-3"} style={{color: "#535050"}}>
              <h5>작성시 유의사항</h5>
              <Row>
                <Col sm={12} md={6}>
                  * 비자종류와 체류기간을 정확히 적어주세요.
                </Col>
              </Row>
              <Row>
                <Col>* 귀국일 후에 통관 및 국내배송이 이루어집니다.</Col>
              </Row>
              <Row>
                <Col>* 추가비용: 포장비$20 (골프백, 기타, 보드가방 등)</Col>
              </Row>
              <Row>
                <Col>
                  * 소요시간:{" "}
                  <span className={"text-danger"}>
                    현 코로나 상황 및 수요로 인해 선사스케쥴이 원활하지 않습니다. 평시보다
                    딜레이가 될 수 있으니 참고 및 양해부탁드립니다.
                  </span>
                </Col>
              </Row>
              <Row>
                <Col className={"text-danger font-weight-bold"}>
                  * 짐이 한국에 도착즈음 이메일을 통해 통관준비서류 및 국내배송에 대한
                  안내 받으실 예정입니다.
                </Col>
              </Row>
              <div
                className={"mt-3 text-danger font-weight-bold"}
                style={{fontSize: "1.1rem"}}
              >
                자격요건:
              </div>
              <Row>
                <Col>
                  캐나다체류3개월이상, 한국귀국예정자, 1년 이상 체류자: 면세 (특이사항
                  없을 시)
                </Col>
              </Row>
              <Row>
                <Col>
                  1년 미만 (가족동반 6개월 미만)체류자: - 통관발생비용 :
                  ₩33,000(관세사통관) + a
                </Col>
              </Row>
              <Row>
                <Col>(a: 거주기간, VALUE, ITEM, 사유 등에 의해서 달라집니다.)</Col>
              </Row>
              <Row>
                <Col>
                  귀국 후 공통 구비서류: 여권앞면, 출입국사실증명 (가족동반 시 가족들 각각
                  & 등본 1통)
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className={"font-weight-bold"}>- 추가 구비서 (요청 시)</span>:
                  사유서, 캐나다 거주증빙 서류 (기간과 이름이 명시된 렌트계약서 or
                  어학원&학기 수료증)
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className={"text-danger font-weight-bold"}>
                    영주권자(여권타입 PR에 한함)
                  </span>
                  : 전/월세 계약서 또는 고용계약서 필요(본인이름 명시, 1년 이상 기간 명시)
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className={"text-danger font-weight-bold"}>시민권자</span>: 1년
                  이상 기간이 유효한 거소증
                </Col>
              </Row>
            </div>
          );
        case "MOCANADA":
          return (
            <div className={"mb-3"} style={{color: "#535050"}}>
              <h5>작성시 유의사항</h5>
              <Row>
                <Col sm={12}>
                  * 성함 및 이메일 주소는 통관자 기준으로 작성 부탁 드립니다.
                </Col>
              </Row>
              <Row>
                <Col>* 비자 종류 및 비자 잔여기간을 정확히 적어주세요.</Col>
              </Row>
              <Row>
                <Col sm={12}>
                  * RETURN DATE 란에 캐나다 입국일을 적어주세요. 캐나다 입국일 후에 통관
                  및 배송이 이루어집니다.
                </Col>
              </Row>
              <Row>
                <Col>
                  * 물품내용은 각 상자에 포장하신 물품 기준으로 작성해주셔야 합니다. (예:
                  1번상자 - 옷, 신발 / 2번상자: 책, 문구류 등)
                </Col>
              </Row>
              <Row>
                <Col>
                  * 포장하신 상자 위에 상자 번호를 적어주세요. 물품 목록표 작성해 주신
                  것과 대조가 가능하도록 표기 부탁드립니다.
                </Col>
              </Row>
              <Row>
                <Col>
                  * 소요시간:{" "}
                  <span className={"text-danger"}>
                    현 코로나 상황 및 수요로 인해 선사스케쥴이 원활하지 않습니다. 평시보다
                    딜레이가 될 수 있으니 참고 및 양해부탁드립니다.
                  </span>
                </Col>
              </Row>
              <Row>
                <Col className={"text-danger font-weight-bold"}>
                  * 짐이 밴쿠버 본사에 도착할 때 즈음 밴쿠버 본사를 통해 통관 준비서류 및
                  현지 배송에 대한 안내를 받으실 예정입니다.
                </Col>
              </Row>
              <div
                className={"mt-3 text-danger font-weight-bold"}
                style={{fontSize: "1.1rem"}}
              >
                통관자 자격요건:
              </div>
              <Row>
                {/*<Col>*/}
                {/*	<span className={'text-danger font-weight-bold'}>* 영주권자(여권타입 PR에 한함)</span>: 전/월세 계약서 또는 고용계약서 필요(본인이름*/}
                {/*	명시, 1년 이상 기간 명시)*/}
                {/*</Col>*/}
                <Col>* 시민권자 / 영주권자 / 워크퍼밋 / 스터디퍼밋</Col>
              </Row>
              <Row>
                <Col>
                  {/*<span className={'text-danger font-weight-bold'}>* 시민권자</span>: 1년 이상 기간이 유효한 거소증*/}
                  * 관광비자로는 통관이 불가합니다.
                </Col>
              </Row>
            </div>
          );
        case "CP":
        case "UPS":
          return (
            <div className={"mb-3"} style={{color: "#535050"}}>
              <h5>작성시 유의사항</h5>
              <Row>
                <Col>* 받는 곳 주소 및 우편번호를 정확히 적어주세요.</Col>
              </Row>
              <Row>
                <Col>
                  * 물품내역은 가급적 영어로 적으시고, USER와 NEW를 구분해주세요 (NEW
                  제품은 브랜드 명 기입바랍니다)
                </Col>
              </Row>
              <Row>
                <Col>
                  * 아파트라면 버즈넘버 반드시 기입바랍니다. (없을 시 배송 지연 또는
                  반송될 수 있습니다)
                </Col>
              </Row>
            </div>
          );
        default:
          return (
            <div className={"mb-3"} style={{color: "#535050"}}>
              {/*UPS 전용 링크 form, 작성시 유의 사항   */}
              <h5 className={"text-danger"}>접수시 필수 기재사항</h5>

              <div className="container_ups">
                <div className="infoBox">
                  <ul>
                    <span className="importantUps">* 보내시는분</span>
                    <li>한국내 주소/ 연락처</li>
                    <li>박스수량/ 물품가액</li>
                    <li>사이즈(가로세로높이)</li>
                    <li>물품명,중량</li>
                    <li>이메일주소 : Tracking 정보제공용</li>
                  </ul>
                </div>
                <div className="infoBox">
                  <ul>
                    <span className="importantUps">* 받으시는분</span>
                    <li>캐나다내 주소</li>
                    <li>해외우편번호(6자리기재)</li>
                    <li>연락처: 통관안내시 중요함</li>
                    <li>이메일주소: Tracking 정보제공용</li>
                  </ul>
                </div>
                <div className="infoBox">
                  <ul>
                    <span className={"text-danger font-weight-bold"}>
                      * 작성시 유의사항
                    </span>
                    <li>
                      - 각 기재항목이 정확하지 않을경우 화물이 분실되거나 배송이 지연될 수
                      있습니다.
                    </li>
                    <li>
                      - 작성시 궁금한 사항은 캐나다 쉬핑 카카오 채널로 문의 하시면 보다
                      자세한 상담을 받으실 수 있습니다.
                    </li>
                    <li>
                      {" "}
                      <a href={"http://pf.kakao.com/_dLNBd/chat"} target="_blank">
                        <img
                          style={{height: "35px", marginTop: "5px"}}
                          src={ka_coship}
                          alt="kakao_Talk_channel"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <Row>
                <Col md={3}>{_r(FN.TO_TYPE)}</Col>
              </Row>
              {/* <Row>
								<Col md={12} lg={6}>
									* 물품 총 비용이 $150 이상이면 과세대상자입니다.
								</Col>
							</Row>
							<Row>
								<Col md={12} lg={12} className={'text-danger'}>
									* 받는 분의 통관부호 또는 주민번호 기재해주세요 (미기재 시 통관지연 또는 홀드) - 서류는 생년월일 기입
								</Col>
							</Row> */}
            </div>
          );
      }
    };
    //ups 어플리케이션 form에 가로,세로,무게를 input을 받기 위한 함수
    const notesOnBoxSize = () => {
      const {renderField: _r, getValue: _v, setValue: _c, getField: _f} = this;
      return (
        <>
          <h5>박스무게/사이즈</h5>
          <Row style={{marginLeft: "10px", marginBottom: "10px", color: "#535050"}}>
            {" "}
            * 모르실 경우 입력하지 않으셔도 됩니다.
          </Row>

          <Row gutter={16} style={{marginBottom: "10px"}}>
            <Col md={2}>{_r(FN.TOTAL_WEIGHT)}</Col>
            <Col md={2}>{_r(FN.LENGTH)}</Col>
            <Col md={2}>{_r(FN.WIDTH)}</Col>
            <Col md={2}>{_r(FN.HEIGHT)}</Col>
          </Row>
        </>
      );
    };

    const notesOnDeclaredItems = () => {
      const {s, d, n} = util.getQS();
      switch (s + d) {
        case "MAKOREA":
          return (
            <div className={"mb-4"} style={{color: "#535050"}}>
              <h5>신고 품목 유의사항</h5>
              <Row>
                <Col sm={12} md={12} lg={6}>
                  <Row>
                    <Col>* 주류, 향수는 높은 세율이 발행됩니다.(ml기재요망)</Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className={"text-danger font-weight-bold"}>* 금지품목</span>:
                      육포, 멜라토닌성분, 반려동물사료, 전자담배기계, 컨택트렌즈
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className={"text-danger font-weight-bold"}>* 제한품목</span>:
                      버섯, 꿀(최대5KG) (용량기재요망) 건강보조식품 최대 6병
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <div className={"text-danger font-weight-bold"}>* 추가비용품목</div>
                      <Col>
                        - 리튬배터리 10불: 노트북, 핸드폰, 스피커 등 (약 일주일 배송지연)
                      </Col>
                      <Col>- 포장 20불: 보드, 기타, 골프가방 등</Col>
                      <Col>- 픽업비 20불: 10kg 미만의 짐</Col>
                      <Col>- 길이초과 10불: 한변의 길이가 1m 이상인 짐</Col>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col className={"text-danger font-weight-bold"}>
                  "기재하지 않은 물품에 대한 과태료 또는 세금책임은 받는 분께 있습니다"
                </Col>
              </Row>
            </div>
          );
        case "MOKOREA":
          return <div></div>;
        case "MOCANADA":
          return (
            <div className={"mb-4"} style={{color: "#535050"}}>
              <h5>유의사항</h5>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <div className={"text-danger font-weight-bold"}>* 금지품목</div>
                      <Col>- 주류 및 담배류</Col>
                      <Col>- 가열시 폭발 위험이 있는 물품(배터리, 가스류)</Col>
                      <Col>- 육가공식품(장조림, 육포, 스팸 등)</Col>
                      <Col>- 씨앗류 (쌀, 콩, 곡식류 포함), 흙</Col>
                      <Col>- 귀금속, 현금</Col>
                      <Col>- 상업 용도의 화물</Col>
                      <Col>- 운송물 1개 포장의 가액이 C$400을 초과하는 경우</Col>
                      <Col>- 식품류</Col>
                    </Col>
                  </Row>
                </Col>
                {/*<Col sm={12} md={12} lg={6}>*/}
                {/*	<Row>*/}
                {/*		<Col>*/}
                {/*			* 주류, 향수는 높은 세율이 발행됩니다.(ml기재요망)*/}
                {/*		</Col>*/}
                {/*	</Row>*/}
                {/*	<Row>*/}
                {/*		<Col>*/}
                {/*			<span className={'text-danger font-weight-bold'}>* 금지품목</span>: 육포, 멜라토닌성분, 반려동물사료, 전자담배기계, 컨택트렌즈*/}
                {/*		</Col>*/}
                {/*	</Row>*/}
                {/*	<Row>*/}
                {/*		<Col>*/}
                {/*			<span className={'text-danger font-weight-bold'}>* 제한품목</span>: 버섯, 꿀(최대5KG) (용량기재요망) 건강보조식품 최대 6병*/}
                {/*		</Col>*/}
                {/*	</Row>*/}
                {/*</Col>*/}
                <Col>
                  <Row>
                    <Col>
                      <div className={"text-danger font-weight-bold"}>
                        * 기타 유의사항
                      </div>
                      <Col>
                        - 픽업 접수 후 1~3일 내에 CJ대한통운 택배사를 통해
                        수거됩니다.(명절 등 택배 물량이 몰리는 경우에는 평소보다 약 2~5일
                        픽업이 지연 될 수 있습니다.)
                      </Col>
                      <Col>- 소도시는 추가비용이 발생할 수 있습니다.</Col>
                      <Col>
                        - 당사 창고에 입고된 순서에 따라 컨테이너에 적재되며, 컨테이너
                        적재가 완료되면 선박 스케줄을 안내드리고 있습니다.
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{marginTop: "20px"}}>
                <Col>
                  당사 카카오톡 채널에 문의주시면 보다 빠르게 안내 받으실 수 있습니다.
                </Col>
              </Row>
              <Row>
                <Col>
                  #카카오톡 채널:{" "}
                  <a href={"http://pf.kakao.com/_dLNBd"}>http://pf.kakao.com/_dLNBd</a>
                </Col>
              </Row>
            </div>
          );
        case "CP":
        case "UPS":
          return <div></div>;
        default:
          return (
            <div className={"mb-4"} style={{color: "#535050"}}>
              <h5>신고 품목 유의사항</h5>
              {/* ups form  전용 신고 품목 유의사항 */}
              <Row>
                <Col sm={12} md={12} lg={6}>
                  <Row>
                    <Col>
                      {" "}
                      <span className={"text-danger font-weight-bold"}>* 금지품목</span>:
                      리튬밧데리포함 기기, 액체, 김치, 의약품, 가스가 포함된
                      스프레이(스프레이화장품 등), 안경, 담배, 주류 등
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className={"text-danger font-weight-bold"}>
                        * 기재하지 않은 물품과 금지품목에 대해서는 캐나다 통관시 세금발생
                        및 폐기될 수 있습니다.
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className={"text-danger font-weight-bold"}>
                        * 캐나다 통관 기준에 의해 관부가세가 발생할 수 있으며 관부가세의
                        납부 의무는 수하인에게 있습니다.
                      </span>
                    </Col>
                  </Row>
                </Col>

                <Col sm={12} md={12} lg={6}>
                  <Row>
                    <Col>
                      <div className={"text-danger font-weight-bold"}>* 추가비용품목</div>
                      <Col>- 창고입고 후 포장비 : 3만원 / 골프백, 기타, 보드 등</Col>
                      <Col>
                        - 합포장 비용 : 여러 구매처에서 입고 후 합포장시 입고 건당 5,000원
                        추가
                      </Col>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={"text-danger font-weight-bold"}>
                        * 신고품목 유의사항
                      </div>
                      <Col>
                        - 리튬밧데리 장착 기기는 화물포장전 반드시 문의주시기 바랍니다.
                      </Col>
                      <Col>
                        - 물품 파손에 대한 보상처리는 보험가입시보상이 가능하며 전체
                        분실시 신고금액과 CAD$100중 낮은 금액으로 보상됩니다.
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {notesOnBoxSize()}
            </div>
          );
      }
    };
    const {s, d} = util.getQS();
    //인보이스 패킹 리스트 추가 함수
    const addInvoice = () => {

      const inputValues = {
        item: _v(FN.ITEM),
        qty: _v(FN.QTY),
        unitPrice: _v(FN.UNIT_PRICE),
      };
      const {item, qty, unitPrice} = inputValues;

      if (item && qty && unitPrice) {
        const totalPrice = parseFloat(inputValues.qty * inputValues.unitPrice);
        let newRows = [];

        newRows = [...(grid.rows ?? []), {...inputValues, totalPrice}];

        if (newRows.length <= 20) {
          grid.setRows(newRows.map((i, index) => ({...i, no: index})));
          _c(FN.ITEM, null)
          _c(FN.QTY, null)
          _c(FN.UNIT_PRICE, null)
        } else {
          util.showWarning("20개 까지만 신고 하실수 있습니다.");
        }

      } else {
        util.showWarning("물품, 갯수, 개당가격을 모두 입력해 주세요");
      }

    }
    return (

      <Container className={"input-form-container px-4 pt-4"}>
        {!this.forCustomer && (
          <Card className={"pb-4"} style={{height: "initial"}}>
            <CardBody className={"p-0"}>
              <h4 className={"mb-4"}>SERVICE TYPE</h4>
              <Row>
                <Col md={3}>{_r(FN.SERVICE_TYPE)}</Col>
                {!isUPS && <Col md={3}>{_r(FN.DESTINATION)}</Col>}
                <Col md={3}>{_r(FN.BL_NO)}</Col>
              </Row>
              <Row>
                {!isVendor && <Col md={3}>{_r(FN.PICKUP_TYPE)}</Col>}
                <Col md={3}>{_r(FN.PICKUP_DATE)}</Col>
                <Col md={3}>{_r(FN.PICKUP_TIME)}</Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {!this.forCustomer && (
          <Card className={"pb-0"} style={{height: "initial"}}>
            <CardBody className={"p-0"}>
              <h4 className={"mb-4"}>QUOTATION</h4>
              <Row>
                <Col md={3}>{_r(FN.LENGTH)}</Col>
                <Col md={3}>{_r(FN.WIDTH)}</Col>
                <Col md={3}>{_r(FN.HEIGHT)}</Col>
                <Col md={3}>{_r(FN.PACKAGES)}</Col>
              </Row>
              <Row>
                <Col md={3}>{_r(FN.TOTAL_WEIGHT)}</Col>
                <Col md={3}>{_r(FN.VOLUME)}</Col>
                <Col md={3}>{isDropOff ? _r(FN.SERVICE_CHARGE) : _r(FN.DEPOSIT)}</Col>
                <Col md={3} className={"flex middle"}>
                  {isDropOff && (
                    <DefaultButton
                      label={"Calculate Price"}
                      type={"button"}
                      onClick={onCalc}
                      nomargin
                    />
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {this.forCustomer && (
          <Row>
            {/* 온라인 접수 form title에 해당합니다. */}
            <h4>
              {serviceTypeOptions.find((i) => i.value === serviceType)?.label} {label}
            </h4>
          </Row>
        )}
        {noteOnWriting()}
        <Row>
          {/* TODO AREA!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
          {s === "MO" && d === "KOREA" ? (
            <Col md={6} lg={3}>
              {_r(FN.AREA)}
            </Col>
          ) : (
            <div/>
          )}
          {s === "MA" && d === "KOREA" ? (
            <Col md={6} lg={3}>
              {_r(FN.AREA)}
            </Col>
          ) : (
            <div/>
          )}
          {s === "CP" ? (
            <Col md={6} lg={3}>
              {_r(FN.AREA)}
            </Col>
          ) : (
            <div/>
          )}
          {s === "UPS" ? (
            <Col md={6} lg={3}>
              {_r(FN.AREA)}
            </Col>
          ) : (
            <div/>
          )}
        </Row>
        {/*<img src={'https://cdn.searchenginejournal.com/wp-content/uploads/2019/08/c573bf41-6a7c-4927-845c-4ca0260aad6b-760x400.jpeg'} width={'50%'}></img>*/}
        {isUPS && this.renderUPS()}
        {isFromCanada && isAir && this.renderAirFromCanada()}
        {isFromKorea && isAir && this.renderAirFromKorea()}
        {isFromCanada && isOcean && this.renderOceanFromCanada()}
        {isFromKorea && isOcean && this.renderOceanFromKorea()}
        <div className={"mb-4"}>
          <div className={"flex between middle mb-2"}>
            {notesOnDeclaredItems()}
            <h5>INVOICE & PACKING LIST (영어로 작성 바랍니다)</h5>

            <Row justify="space-between">
              <Col xl={4}>{_r(FN.ITEM)}</Col>
              <Col xl={1}>{_r(FN.QTY)}</Col>
              <Col xl={3}>{_r(FN.UNIT_PRICE)}</Col>
              <Col xs={24} xl={2} style={{display: "flex", alignItems: "center"}}>
                <div>Total Price: {totalPrice.toFixed(2)}</div>
              </Col>
              <Col xs={24} style={{display: "flex", alignItems: "center"}}>
                <button
                  type="button"
                  className="btn btn__secondary btn__hover3 ml-3"
                  onClick={addInvoice}
                  nomargin
                >
                  추가
                </button>
              </Col>
            </Row>
          </div>
          {grid.render()}
        </div>
        {this.forCustomer && (
          <div
            className={"flex between wrap"}
            style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}
          >
            {(upsid && sid && key) ? '' :
              <div className={"flex"} style={{display: "flex"}}>
                {_r(FN.CONSENT)}
                {(s === 'MA' && d === 'CANADA') ? <a>Terms and Conditions</a> :
                  <a style={{fontWeight: "bold"}} href={"/terms"} onClick={onTerms}>
                    Terms and Conditions
                  </a>}
              </div>
            }

          </div>
        )}

        {(upsid && sid && key) && <div
          className={"flex center mb-4 pb-4"}
          style={{display: "flex", justifyContent: "center"}}
        >
          <PrimaryButton
            label={"저장"}
            type={"submit"}
            className={"mr-2 btn btn__secondary btn__hover3"}
          />
        </div>}

        {(!upsid) &&
        <div
          className={"flex center mb-4 pb-4"}
          style={{display: "flex", justifyContent: "center"}}
        >
          {onDelBtnForBlNo.length > 0 ? (
            <PrimaryButton
              label={"신청"}
              type={"submit"}
              className={"mr-2 btn btn__secondary btn__hover3"}
            />
          ) : (
            <PrimaryButton
              label={"신청"}
              type={"submit"}
              className={"mr-2 btn btn__secondary btn__hover3"}
            />
          )}
          {onDelBtnForBlNo.length > 0 ? (
            <DefaultButton
              label={"삭제"}
              type={"button"}
              className={"btn-danger"}
              onClick={() => onDelEditData(onDelBtnForBlNo)}
            />
          ) : (
            <DefaultButton
              label={"뒤로가기"}
              type={"button"}
              onClick={() => history.goBack()}
            />
          )}
        </div>}
        {modal.render()}
      </Container>
    )
      ;
  };
  onValidate = (values) => {
    // for (const field of this.fields) {
    //   if (field.required === true && values[field.name]?.length <= 0) {
    //     util.showError(`Please input ${field.label ?? field.name}`);
    //     return null;
    //   }
    // }
    return values;
  };

  setFieldRequired() {
    const setReq = (n, r) => (this.getField(n).required = r);
    const serviceType = this.getValue(FN.SERVICE_TYPE);
    if (!this.forCustomer) {
      setReq(FN.FROM_CITY, false);
      setReq(FN.FROM_PROVINCE, false);
      setReq(FN.FROM_COUNTRY, false);
      setReq(FN.TO_CITY, false);
      setReq(FN.TO_PROVINCE, false);
      setReq(FN.TO_COUNTRY, false);
      this.getField(FN.FROM_COUNTRY).disabled = false;
      this.getField(FN.TO_COUNTRY).disabled = false;
      this.getField(FN.FROM_ADDR).placeholder = "";
      this.getField(FN.TO_ADDR).placeholder = "";
      this.getField(FN.TO_TYPE).placeholder = "";
      if (serviceType === "UPS" || serviceType === "CP") {
        this.getField(FN.FROM_PROVINCE).options = undefined;
        this.getField(FN.TO_PROVINCE).options = undefined;
      } else {
        this.getField(FN.FROM_PROVINCE).options = provinceOptions;
        this.getField(FN.TO_PROVINCE).options = provinceOptions;
      }
      return;
    }
    switch (serviceType) {
      case "MO":
      case "MA":
        if (this.destination === "CANADA") {
          setReq(FN.AREA, false);
          setReq(FN.FROM_CITY, false);
          setReq(FN.FROM_PROVINCE, false);
          setReq(FN.FROM_COUNTRY, false);
          setReq(FN.TO_CITY, true);
          setReq(FN.TO_PROVINCE, true);
          setReq(FN.TO_COUNTRY, true);
          this.getField(FN.FROM_ADDR).placeholder =
            "검색 버튼으로 검색뒤 새도로명주소로 선택";
          this.getField(FN.TO_ADDR).placeholder = "";
        } else {
          setReq(FN.AREA, true);
          setReq(FN.FROM_CITY, true);
          setReq(FN.FROM_PROVINCE, true);
          setReq(FN.FROM_COUNTRY, true);
          setReq(FN.TO_CITY, false);
          setReq(FN.TO_PROVINCE, false);
          setReq(FN.TO_COUNTRY, false);
          this.getField(FN.FROM_ADDR).placeholder = "";
          this.getField(FN.TO_ADDR).placeholder =
            "검색 버튼으로 검색뒤 새도로명주소로 선택";
        }
        this.getField(FN.FROM_COUNTRY).options = countryOptions;
        this.getField(FN.FROM_COUNTRY).disabled = true;
        this.getField(FN.TO_COUNTRY).options = countryOptions;
        this.getField(FN.TO_COUNTRY).disabled = true;
        this.getField(FN.FROM_PROVINCE).options = provinceOptions;
        this.getField(FN.TO_PROVINCE).options = provinceOptions;
        this.getField(FN.MEMO).placeholder =
          serviceType === "MO" ? "특이사항" : "배송메시지 (예: 경비실에 맡겨주세요)";
        break;
      case "CP":
      case "UPS":
        setReq(FN.FROM_CITY, true);
        setReq(FN.FROM_PROVINCE, true);
        setReq(FN.FROM_COUNTRY, true);
        setReq(FN.TO_CITY, true);
        setReq(FN.TO_PROVINCE, true);
        setReq(FN.TO_COUNTRY, true);
        this.getField(FN.FROM_COUNTRY).options = undefined;
        this.getField(FN.FROM_COUNTRY).disabled = false;
        this.getField(FN.TO_COUNTRY).options = undefined;
        this.getField(FN.TO_COUNTRY).disabled = false;
        this.getField(FN.FROM_PROVINCE).options = undefined;
        this.getField(FN.TO_PROVINCE).options = undefined;
        break;
      default:
        break;
    }
  }

  // 아래는 서버에서 넘어오는 데이터명 입니다.
  setValuesFromServer(data) {
    const {
      jcommon: {
        area,
        blNo,
        hblNo,
        hawbNo,
        kind,
        currency,
        totalPrice,
        deposit,
        portType,
        tradeType,
        pickupType,
        pDate,
        pTime,
        isCustomer,
        mapleType,
        destination,
        sendType,
        remark
      },
      jcustomer: {
        shipper,
        shipperLocal,
        shipperId,
        shipperAddr,
        shipperAddr2,
        shipperCity,
        shipperProvince,
        shipperCountry,
        shipperPhone,
        shipperPhone2,
        shipperEmail,
        shipperPostalCode,
        consignee,
        consigneeLocal,
        consigneeId,
        consigneeAddr,
        consigneeAddr2,
        consigneeAddrEng,
        consigneeAddrLocal,
        consigneeCity,
        consigneeProvince,
        consigneeCountry,
        consigneePhone1,
        consigneePhone2,
        consigneePostalCode,
        consigneeEmail,
        customer,
        customerLocal,
        customerId,
        customerAddr,
        customerCity,
        customerProvince,
        customerCountry,
        customerPhone,
        customerPhone2,
        customerEmail,
        customerPostalCode,
        importNo,
        partnerId,
      },
      jshipment: {
        package: packageCount,
        packageType,
        grossWeightKg,
        volumeWeightKg,
        chargeableWeightKg,
        cbm,
        gridData,
        visaType,
        entDate,
        dzWidth,
        dzHeight,
        dzLength,
        dzServiceType,
        memo,
        height,
        width,
        length,
      },
    } = data ?? {jcommon: {}, jcustomer: {}, jshipment: {}};
    const values = {
      [FN.AREA]: area.toLowerCase(),
      [FN.SERVICE_TYPE]: dzServiceType,
      [FN.BL_NO]: blNo ? blNo : hawbNo ? hawbNo : hblNo,
      [FN.DEPOSIT]: deposit,
      [FN.SERVICE_CHARGE]: totalPrice,
      [FN.TOTAL_WEIGHT]: grossWeightKg,
      [FN.WIDTH]: dzWidth,
      [FN.LENGTH]: dzLength,
      [FN.HEIGHT]: dzHeight,
      [FN.VOLUME]: volumeWeightKg,
      [FN.PICKUP_DATE]: util.formatD(pDate),
      [FN.PICKUP_TIME]: pTime,
      [FN.PICKUP_TYPE]: pickupType,
      [FN.PACKAGES]: packageCount,
      [FN.FROM_NAME_KOR]: shipperLocal,
      [FN.FROM_NAME_ENG]: shipper,
      [FN.FROM_PHONE]: shipperPhone,
      [FN.FROM_PHONE2]: shipperPhone2,
      [FN.FROM_EMAIL]: shipperEmail,
      [FN.FROM_ADDR]: shipperAddr,
      [FN.FROM_ADDR2]: shipperAddr2,
      [FN.FROM_CITY]: shipperCity,
      [FN.FROM_PROVINCE]: shipperProvince,
      [FN.FROM_COUNTRY]: shipperCountry,
      [FN.FROM_POSTAL]: shipperPostalCode,
      [FN.RETURN_DATE]: entDate,
      [FN.VISA_TYPE]: visaType,
      [FN.TO_NAME_KOR]: consigneeLocal,
      [FN.TO_NAME_ENG]: consignee,
      [FN.TO_PHONE]: consigneePhone1,
      [FN.TO_PHONE2]: consigneePhone2,
      // consigneeAddr이 ""일 경우 consigneeAddrLocal에서 상세주소를 잘라서 주소를 리턴해줌
      [FN.TO_ADDR]: consigneeAddr !== "" ? consigneeAddr : consigneeAddr2 ? consigneeAddrLocal.substring(0, consigneeAddrLocal.lastIndexOf("(")).trim() : consigneeAddrLocal,
      [FN.TO_ADDR2]: consigneeAddr2,//상세주소
      [FN.TO_CITY]: consigneeCity,
      [FN.TO_EADDR]: consigneeAddrEng,
      [FN.TO_PROVINCE]: consigneeProvince,
      [FN.TO_COUNTRY]: consigneeCountry,
      [FN.TO_POSTAL]: consigneePostalCode,
      [FN.TO_EMAIL]: consigneeEmail,
      [FN.TO_CUSTOMS_NO]: importNo,
      [FN.MEMO]: memo,
      [FN.CONSENT]: undefined,
      [FN.IS_CUSTOMER]: isCustomer,
      [FN.MAPLE_TYPE]: mapleType,
      [FN.DESTINATION]: destination,
      [FN.KIND]: kind,
      [FN.TO_TYPE]: sendType,
      [FN.REMARK]: remark
    };
    this.dropzoneId = partnerId;
    this.serverData = data;
    this.setValuesFast(values);
    this.grid.setRows(gridData);
    this.gridData = gridData;
  }

  // 아래는 Submit시 가지고 올 수 있는 Data의 값들 입니다.
  getValuesToSubmit() {
    const v = this.getValues(undefined, true);
    const {width, height, length} = v;
    //항공 중량화물의 경우 5000으로 나눔
    const voluemAir = (width * height * length) / 5000;
    const volume = Math.round(voluemAir * 10) / 10;
    const jcommon = {
      blNo: v[FN.BL_NO],
      kind: this.kind,
      currency: "CAD",
      totalPrice: util.toFloat(v[FN.SERVICE_CHARGE]),
      deposit: util.toFloat(v[FN.DEPOSIT]),
      portType: v[FN.SERVICE_TYPE] === "MO" ? "O" : "A", // <MAPLEBOX OCEAN>인 경우에만 'O' 로 설정함
      tradeType: "E",
      pickupType: v[FN.PICKUP_TYPE] ?? "DROP OFF",
      pDate: util.toTS(v[FN.PICKUP_DATE]),
      pTime: v[FN.PICKUP_TIME],
      isCustomer: v[FN.IS_CUSTOMER] ?? this.forCustomer === true, // <Customer>가 입력한 경우 TRUE
      isVendorData: true, // 벤더가 입력한 데이터인 경우 TRUE
      mapleType: v[FN.MAPLE_TYPE],
      destination: v[FN.TO_COUNTRY],
      area: v[FN.AREA] ?? "SEOUL",
      sendType: v[FN.TO_TYPE],
      remark: v[FN.REMARK]
    };
    const jcustomer = {
      shipper: v[FN.FROM_NAME_ENG],
      shipperLocal: v[FN.FROM_NAME_KOR],
      shipperId: 0,
      shipperAddr: v[FN.FROM_ADDR],
      shipperAddr2: v[FN.FROM_ADDR2],
      shipperCity: v[FN.FROM_CITY],
      shipperProvince: v[FN.FROM_PROVINCE],
      shipperCountry: v[FN.FROM_COUNTRY],
      shipperPhone: v[FN.FROM_PHONE],
      shipperPhone2: v[FN.FROM_PHONE2],
      shipperEmail: v[FN.FROM_EMAIL],
      shipperPostalCode: v[FN.FROM_POSTAL],
      consignee: v[FN.TO_NAME_ENG],
      consigneeLocal: v[FN.TO_NAME_KOR],
      // consigneeId: 0,
      consigneeAddr: v[FN.TO_ADDR],
      consigneeAddr2: v[FN.TO_ADDR2],
      consigneeAddrLocal: v[FN.TO_ADDR],
      consigneeAddrEng: cAEng ?? "",
      consigneeCity: v[FN.TO_CITY],
      consigneeProvince: v[FN.TO_PROVINCE],
      consigneeCountry: v[FN.TO_COUNTRY],
      consigneePhone1: v[FN.TO_PHONE],
      consigneePhone2: v[FN.TO_PHONE2],
      consigneePostalCode: v[FN.TO_POSTAL],
      consigneeEmail: v[FN.TO_EMAIL],
      customer: v[FN.FROM_NAME_ENG],
      customerLocal: v[FN.FROM_NAME_KOR],
      customerId: 0,
      customerAddr: v[FN.FROM_ADDR],
      customerCity: v[FN.FROM_CITY],
      customerProvince: v[FN.FROM_PROVINCE],
      customerCountry: v[FN.FROM_COUNTRY],
      customerPhone: v[FN.FROM_PHONE],
      customerPhone2: v[FN.FROM_PHONE2],
      customerEmail: v[FN.FROM_EMAIL],
      customerPostalCode: v[FN.FROM_POSTAL],
      importNo: v[FN.TO_CUSTOMS_NO],
      partnerId: this.dropzoneId
    };
    const jshipment = {
      package: util.toInt(v[FN.PACKAGES]),
      packageType: "BOX",
      grossWeightKg: util.toFloat(v[FN.TOTAL_WEIGHT]),
      volumeWeightKg: isNaN(volume) ? undefined : volume,
      cbm: undefined, // TODO
      gridData: this.grid.rows,
      visaType: v[FN.VISA_TYPE] ?? "",
      entDate: v[FN.RETURN_DATE] ?? "",
      dzWidth: v[FN.WIDTH],
      dzHeight: v[FN.HEIGHT],
      dzLength: v[FN.LENGTH],
      dzServiceType: v[FN.SERVICE_TYPE],
      memo: v[FN.MEMO],
    };

    if (this.serverData) {
      const {
        jcommon: jcommonServer,
        jcustomer: jcustomerServer,
        jshipment: jshipmentServer,
      } = this.serverData;
      return {
        jcommon: {...jcommonServer, ...jcommon},
        jcustomer: {...jcustomerServer, ...jcustomer},
        jshipment: {...jshipmentServer, ...jshipment},
      };
    } else {
      return {
        jcommon,
        jcustomer: {...jcustomer, shipperId: 0, consigneeId: 0, customerId: 0},
        jshipment,
      };
    }
  }

  // 하단부터 실제 Selector에 따라 렌더되는 Form들이 있습니다.
  renderAirFromCanada() {
    const {renderField: _r, getValue: _v, setValue: _c, getField: _f} = this;
    const onAddressSearch = () => {
      // eslint-disable-next-line no-undef
      new daum.Postcode({
        oncomplete: function (data) {
          const {zonecode, address, buildingName, addressEnglish} = data;
          _c(
            FN.TO_ADDR,
            address.toUpperCase() + (buildingName ? ` (${buildingName})` : "")
          );
          // _c(FN.TO_EADDR, addressEnglish.toUpperCase());
          cAEng = addressEnglish.toUpperCase();
          _c(FN.TO_POSTAL, zonecode.toUpperCase());
        },
      }).open();
    };
    return (
      <Row>
        <Col lg={6} className={"py-4"}>
          <Card className={"pb-0 border-0"}>
            <CardBody className={"p-0"}>
              <h5 className={"mb-4"}>{LB.DEPARTURE}</h5>
              <Row>
                <Col md={6}>{_r(FN.FROM_NAME_ENG)}</Col>
                <Col md={6}>{_r(FN.FROM_NAME_KOR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_PHONE)}</Col>
                <Col md={6}>{_r(FN.FROM_PHONE2)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_ADDR)}</Col>
                <Col md={6}>{_r(FN.FROM_CITY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_PROVINCE)}</Col>
                <Col md={6}>{_r(FN.FROM_COUNTRY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_POSTAL)}</Col>
                <Col md={6}>{_r(FN.FROM_EMAIL)}</Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6} className={"py-4"}>
          <Card className={"pb-0 border-0"}>
            <CardBody className={"p-0"}>
              <h5 className={"mb-4"}>{LB.DESTINATION}</h5>
              <Row>
                <Col md={6}>{_r(FN.TO_NAME_ENG)}</Col>
                <Col md={6}>{_r(FN.TO_NAME_KOR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_PHONE)}</Col>
                <Col md={6}>{_r(FN.TO_PHONE2)}</Col>
              </Row>
              <Row>
                <Col md={9}>{_r(FN.TO_ADDR)}</Col>
                <Col
                  md={3}
                  className={"flex middle"}
                  style={{display: "flex", alignItems: "center", marginTop: "15px"}}
                >
                  <DefaultButton
                    label={"검색"}
                    className={"mr-2 btn btn__secondary btn__hover3"}
                    type={"button"}
                    onClick={onAddressSearch}
                    nomargin
                    style={{whiteSpace: "nowrap"}}
                  />
                </Col>
              </Row>
              {this.forCustomer && (
                <Row>
                  <Col md={9}>{_r(FN.TO_ADDR2)}</Col>
                </Row>
              )}
              <Row>
                <Col md={6}>{_r(FN.TO_POSTAL)}</Col>
                <Col md={6}>{_r(FN.TO_COUNTRY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_CUSTOMS_NO)}</Col>
              </Row>
              <Row>
                <Col>{_r(FN.MEMO)}</Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }

  renderAirFromKorea() {
    const {renderField: _r, getValue: _v, setValue: _c, getField: _f} = this;
    const {upsid = null, sid = null, key = null} = util.getQS()
    const onAddressSearch = () => {
      // eslint-disable-next-line no-undef
      new daum.Postcode({
        oncomplete: function (data) {
          const {zonecode, address, buildingName, addressEnglish} = data;
          _c(
            FN.FROM_ADDR,
            address.toUpperCase() + (buildingName ? ` (${buildingName})` : "")
          );
          // _c(FN.TO_EADDR, addressEnglish.toUpperCase());
          cAEng = addressEnglish.toUpperCase();
          _c(FN.FROM_POSTAL, zonecode.toUpperCase());
        },
      }).open();
    };

    return (
      <Row>
        {/* // #UPS 용 유저 인풋 파일 */}
        <Col lg={6} className={"py-4"}>
          <Card className={"pb-0 border-0"}>
            <CardBody className={"p-0"}>
              <h5 className={"mb-4"}>{LB.DEPARTURE}</h5>
              <Row>
                <Col md={6}>{_r(FN.FROM_NAME_ENG)}</Col>
                <Col md={6}>{_r(FN.FROM_NAME_KOR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_PHONE)}</Col>
                <Col md={6}>{_r(FN.FROM_PHONE2)}</Col>
              </Row>
              <Row>
                <Col md={9}>{_r(FN.FROM_ADDR)}</Col>
                <Col
                  md={3}
                  className={"flex middle"}
                  style={{display: "flex", alignItems: "center", marginTop: "15px"}}
                >
                  <DefaultButton
                    label={"주소검색"}
                    className={"mr-2 btn btn__secondary btn__hover3"}
                    type={"button"}
                    onClick={onAddressSearch}
                    nomargin
                    style={{whiteSpace: "nowrap"}}
                  />
                </Col>
              </Row>
              {this.forCustomer && (
                <Row>
                  <Col md={9}>{_r(FN.FROM_ADDR2)}</Col>
                </Row>
              )}
              <Row>
                <Col md={6}>{_r(FN.FROM_POSTAL)}</Col>
                <Col md={6}>{_r(FN.FROM_COUNTRY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_EMAIL)}</Col>
                <Col md={6}>{""}</Col>
              </Row>
              {/*UPS form을 수정 할때만 비고 란이 생김*/}
              {(upsid && sid && key) &&
              <Row>
                <Col span={6}>{_r(FN.REMARK)}</Col>
              </Row>
              }
            </CardBody>
          </Card>
        </Col>
        <Col lg={6} className={"py-4"}>
          <Card className={"pb-0 border-0"}>
            <CardBody className={"p-0"}>
              <h5 className={"mb-4"}>{LB.DESTINATION}</h5>
              <Row>
                <Col md={6}>{_r(FN.TO_NAME_ENG)}</Col>
                <Col md={6}>{_r(FN.TO_NAME_KOR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_PHONE)}</Col>
                <Col md={6}>{_r(FN.TO_PHONE2)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_ADDR)}</Col>
                <Col md={6}>{_r(FN.TO_CITY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_PROVINCE)}</Col>
                <Col md={6}>{_r(FN.TO_COUNTRY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_POSTAL)}</Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }

  renderOceanFromCanada() {
    const {renderField: _r, getValue: _v, setValue: _c, getField: _f} = this;
    const onAddressSearch = () => {
      // eslint-disable-next-line no-undef
      new daum.Postcode({
        oncomplete: function (data) {
          const {zonecode, address, buildingName, addressEnglish} = data;
          _c(
            FN.TO_ADDR,
            address.toUpperCase() + (buildingName ? ` (${buildingName})` : "")
          );
          // _c(FN.TO_EADDR, addressEnglish.toUpperCase());
          cAEng = addressEnglish.toUpperCase();
          _c(FN.TO_POSTAL, zonecode.toUpperCase());
        },
      }).open();
    };
    return (
      <Row>
        <Col lg={6} className={"py-4"}>
          <Card className={"pb-0 border-0"}>
            <CardBody className={"p-0"}>
              <h5 className={"mb-4"}>{LB.DEPARTURE}</h5>
              <Row>
                <Col md={6}>{_r(FN.FROM_NAME_ENG)}</Col>
                <Col md={6}>{_r(FN.FROM_NAME_KOR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_PHONE)}</Col>
                <Col md={6}>{_r(FN.FROM_PHONE2)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_ADDR)}</Col>
                <Col md={6}>{_r(FN.FROM_CITY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_PROVINCE)}</Col>
                <Col md={6}>{_r(FN.FROM_COUNTRY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_POSTAL)}</Col>
                <Col md={6}>{_r(FN.FROM_EMAIL)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.MAPLE_TYPE)}</Col>
                <Col md={6}/>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.RETURN_DATE)}</Col>
                <Col md={6}>{_r(FN.VISA_TYPE)}</Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6} className={"py-4"}>
          <Card className={"pb-0 border-0"}>
            <CardBody className={"p-0"}>
              <h5 className={"mb-4"}>{LB.DESTINATION}</h5>
              <Row>
                <Col md={6}>{_r(FN.TO_NAME_ENG)}</Col>
                <Col md={6}>{_r(FN.TO_NAME_KOR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_PHONE)}</Col>
                <Col md={6}>{_r(FN.TO_PHONE2)}</Col>
              </Row>
              <Row>
                <Col md={9}>{_r(FN.TO_ADDR)}</Col>
                <Col
                  md={3}
                  className={"flex middle"}
                  style={{display: "flex", alignItems: "center", marginTop: "15px"}}
                >
                  <DefaultButton
                    label={"Search"}
                    className={"mr-2 btn btn__secondary btn__hover3"}
                    type={"button"}
                    onClick={onAddressSearch}
                    nomargin
                    style={{whiteSpace: "nowrap"}}
                  />
                </Col>
              </Row>
              {this.forCustomer && (
                <Row>
                  <Col md={9}>{_r(FN.TO_ADDR2)}</Col>
                </Row>
              )}
              <Row>
                <Col md={6}>{_r(FN.TO_POSTAL)}</Col>
                <Col md={6}>{_r(FN.TO_COUNTRY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{""}</Col>
              </Row>
              <Row>
                <Col>{_r(FN.MEMO)}</Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }

  renderOceanFromKorea() {
    const {renderField: _r, getValue: _v, setValue: _c, getField: _f} = this;
    const onAddressSearch = () => {
      // eslint-disable-next-line no-undef
      new daum.Postcode({
        oncomplete: function (data) {
          const {zonecode, address, buildingName, addressEnglish} = data;
          _c(
            FN.FROM_ADDR,
            address.toUpperCase() + (buildingName ? ` (${buildingName})` : "")
          );
          // 한국 에서 캐나다로 보낼경우 consigneeAddrEng는 empty로 보낸다.
          // cAEng = addressEnglish.toUpperCase();
          _c(FN.FROM_POSTAL, zonecode.toUpperCase());
        },
      }).open();
    };
    return (
      <Row>
        <Col lg={6} className={"py-4"}>
          <Card className={"pb-0 border-0"}>
            <CardBody className={"p-0"}>
              <h5 className={"mb-4"}>{LB.DEPARTURE}</h5>
              <Row>
                <Col md={6}>{_r(FN.FROM_NAME_ENG)}</Col>
                <Col md={6}>{_r(FN.FROM_NAME_KOR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_PHONE)}</Col>
                <Col md={6}>{_r(FN.FROM_PHONE2)}</Col>
              </Row>
              <Row>
                <Col md={9}>{_r(FN.FROM_ADDR)}</Col>
                <Col
                  md={3}
                  className={"flex middle"}
                  style={{display: "flex", alignItems: "center", marginTop: "15px"}}
                >
                  <DefaultButton
                    label={"Search"}
                    className={"mr-2 btn btn__secondary btn__hover3"}
                    type={"button"}
                    onClick={onAddressSearch}
                    nomargin
                    style={{whiteSpace: "nowrap"}}
                  />
                </Col>
              </Row>
              {this.forCustomer && (
                <Row>
                  <Col md={9}>{_r(FN.FROM_ADDR2)}</Col>
                </Row>
              )}
              <Row>
                <Col md={6}>{_r(FN.FROM_POSTAL)}</Col>
                <Col md={6}>{_r(FN.FROM_COUNTRY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.MAPLE_TYPE)}</Col>
                <Col md={6}>{_r(FN.FROM_EMAIL)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.RETURN_DATE)}</Col>
                <Col md={6}>{_r(FN.VISA_TYPE)}</Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6} className={"py-4"}>
          <Card className={"pb-0 border-0"}>
            <CardBody className={"p-0"}>
              <h5 className={"mb-4"}>{LB.DESTINATION}</h5>
              <Row>
                <Col md={6}>{_r(FN.TO_NAME_ENG)}</Col>
                <Col md={6}>{_r(FN.TO_NAME_KOR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_PHONE)}</Col>
                <Col md={6}>{_r(FN.TO_PHONE2)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_ADDR)}</Col>
                <Col md={6}>{_r(FN.TO_CITY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_PROVINCE)}</Col>
                <Col md={6}>{_r(FN.TO_COUNTRY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_POSTAL)}</Col>
                <Col md={6}>{""}</Col>
              </Row>
              <Row>
                <Col>{_r(FN.MEMO)}</Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }

  renderUPS() {
    const {renderField: _r, getValue: _v, setValue: _c, getField: _f} = this;
    return (
      <Row>
        <Col lg={6} className={"py-4"}>
          <Card className={"pb-0 border-0"}>
            <CardBody className={"p-0"}>
              <h5 className={"mb-4"}>{LB.DEPARTURE}</h5>
              <Row>
                <Col md={6}>{_r(FN.FROM_NAME_ENG)}</Col>
                <Col md={6}>{_r(FN.FROM_NAME_KOR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_PHONE)}</Col>
                <Col md={6}>{_r(FN.FROM_PHONE2)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_ADDR)}</Col>
                <Col md={6}>{_r(FN.FROM_CITY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_PROVINCE)}</Col>
                <Col md={6}>{_r(FN.FROM_COUNTRY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_POSTAL)}</Col>
                <Col md={6}>{_r(FN.FROM_EMAIL)}</Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6} className={"py-4"}>
          <Card className={"pb-0 border-0"}>
            <CardBody className={"p-0"}>
              <h5 className={"mb-4"}>{LB.DESTINATION}</h5>
              <Row>
                <Col md={6}>{_r(FN.TO_NAME_ENG)}</Col>
                <Col md={6}>{_r(FN.TO_NAME_KOR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_PHONE)}</Col>
                <Col md={6}>{_r(FN.TO_PHONE2)}</Col>
              </Row>
              <Row>
                <Col md={12}>{_r(FN.TO_ADDR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_CITY)}</Col>
                <Col md={6}>{_r(FN.TO_PROVINCE)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_COUNTRY)}</Col>
                <Col md={6}>{_r(FN.TO_POSTAL)}</Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

// 아래부터는 데이터들의 이름을 정의 하였습니다.
export const serviceTypeOptions = [
  {value: "", label: ""},
  {value: "MA", label: "항공 택배 (AIR)"},
  {value: "MO", label: "선박 MAPLEBOX"},
  {value: "CP", label: "CANADA POST (소형 1박스일 경우)"},
  {value: "UPS", label: "UPS (대형 1박스 이상일 경우)"},
];

export const pickupTypeOptions = [
  {value: "DROP OFF", label: "DROP OFF"},
  {value: "REQUEST PICKUP", label: "REQUEST PICKUP"},
];

export const mapleTypeOptions = [
  {value: "Maple Box", label: "Maple Box (20KG)"},
  // {value: 'Maple Bag', label: 'Maple Bag (35KG)'},
  {value: "Maple Bundle", label: "Maple Bundle"},
];

// export const countryOptions = [
//   {value: 'CANADA', label: 'CANADA'},
//   {value: 'CANADA', label: 'CANADA'},
// ];

export const destinationOptions = [
  {value: "", label: ""},
  {value: "KOREA", label: "캐나다 ⟶ 한국"},
  {value: "CANADA", label: "한국 ⟶ 캐나다"},
];

const countryOptions = undefined;

export const provinceOptions = [
  {value: "", label: ""},
  {label: "Alberta", value: "AB"},
  {label: "British Columbia", value: "BC"},
  {label: "Manitoba", value: "MB"},
  {label: "New Brunswick", value: "NB"},
  {label: "Newfoundland and Labrador", value: "NL"},
  {label: "Northwest Territories", value: "NT"},
  {label: "Nova Scotia", value: "NS"},
  {label: "Nunavut", value: "NU"},
  {label: "Ontario", value: "ON"},
  {label: "Prince Edward Island", value: "PE"},
  {label: "Quebec", value: "QC"},
  {label: "Saskatchewan", value: "SK"},
  {label: "Yukon", value: "YT"},
];
export const areaOption = [
  {value: "", label: ""},
  {value: "vancouver", label: "Vancouver"},
  {value: "calgary", label: "Calgary"},
  {value: "toronto", label: "Toronto"},
];

const FN = {
  KIND: "kind",
  SERVICE_TYPE: "serviceType",
  BL_NO: "blNo",
  DEPOSIT: "deposit",
  SERVICE_CHARGE: "serviceCharge",
  TOTAL_WEIGHT: "totalWeight",
  LENGTH: "length",
  WIDTH: "width",
  HEIGHT: "height",
  VOLUME: "volume",
  PICKUP_DATE: "pickupDate",
  PICKUP_TIME: "pickupTime",
  PICKUP_TYPE: "pickupType",
  PACKAGES: "packages",
  FROM_NAME_KOR: "fromNameKor",
  FROM_NAME_ENG: "fromNameEng",
  FROM_PHONE: "fromPhone",
  FROM_PHONE2: "fromPhone2",
  FROM_EMAIL: "fromEmail",
  FROM_ADDR: "fromAddr",
  FROM_ADDR2: "fromAddr2",
  FROM_CITY: "fromCity",
  FROM_PROVINCE: "fromProvince",
  FROM_COUNTRY: "fromCountry",
  FROM_POSTAL: "fromPostal",
  RETURN_DATE: "returnDate",
  VISA_TYPE: "visaType",
  TO_NAME_KOR: "toNameKor",
  TO_NAME_ENG: "toNameEng",
  TO_PHONE: "toPhone",
  TO_PHONE2: "toPhone2",
  TO_TYPE: "toType",
  TO_ADDR: "toAddr",
  TO_ADDR2: "toAddr2",
  TO_CITY: "toCity",
  TO_EADDR: "toEAddr",
  TO_PROVINCE: "toProvince",
  TO_COUNTRY: "toCountry",
  TO_POSTAL: "toPostal",
  TO_EMAIL: "toEmail",
  TO_CUSTOMS_NO: "toCustomsNo",
  CONSENT: "consent",
  MEMO: "memo",
  IS_CUSTOMER: "isCustomer",
  MAPLE_TYPE: "mapleType",
  DESTINATION: "destination",
  AREA: "area",
  REMARK: "remark",
  NO: 'no',
  ITEM: 'item',
  QTY: 'qty',
  UNIT_PRICE: 'unitPrice',
};

const LB = {
  KIND: "kind",
  SERVICE_TYPE: "Service Type",
  BL_NO: "B/L No.",
  DEPOSIT: "Deposit (CAD)",
  SERVICE_CHARGE: "Total Price (CAD)",
  TOTAL_WEIGHT: "무게 (KG)",
  LENGTH: "가로 (cm)",
  WIDTH: "세로 (cm)",
  HEIGHT: "높이 (cm)",
  VOLUME: "부피 중량 (KG)",
  PICKUP_DATE: "Pickup Date (받는 날짜)",
  PICKUP_TIME: "Pickup Time (받는 시간)",
  PICKUP_TYPE: "Pickup Type (받는 종류)",
  PACKAGES: "Packages",
  FROM_NAME_KOR: "Name (한글성함)",
  FROM_NAME_ENG: "Name (영문성함)",
  FROM_PHONE: "Phone(연락처)",
  FROM_PHONE2: "Phone2(연락처2)",
  FROM_EMAIL: "Email",
  FROM_ADDR: "Address(주소)",
  FROM_CITY: "City(도시)",
  FROM_PROVINCE: "Province(주)",
  FROM_COUNTRY: "Country(국가)",
  FROM_POSTAL: "Postal Code(우편번호)",
  RETURN_DATE: "Return Date (미정 시, 예상 일자 작성)",
  VISA_TYPE: "Visa Type & Visit Duration",
  TO_NAME_KOR: "Name (한글성함)",
  TO_NAME_ENG: "Name (영문성함)",
  TO_PHONE: "Phone(연락처)",
  TO_PHONE2: "Phone2(연락처2)",
  TO_EMAIL: "Email",
  TO_ADDR: "Address(주소)",
  TO_TYPE: "Type(종류)",
  TO_CITY: "City(도시)",
  TO_PROVINCE: "Province(주)",
  TO_COUNTRY: "Country(국가)",
  TO_POSTAL: "Postal Code(우편번호)",
  TO_CUSTOMS_NO: "ID/Customs No.",
  CONSENT: "I agree to the Coship",
  MEMO: "Memo(메모)",
  IS_CUSTOMER: "Is Customer",
  MAPLE_TYPE: "Maple Type",
  DESTINATION: "DESTINATION(도착지 정보)",
  AREA: "Area(지역)",
  DEPARTURE: "DEPARTURE(출발지 정보)",
  REMARK: "Remark(비고)",
  NO: 'No',
  ITEM: 'Item(물품)',
  QTY: 'Qty.(갯수)',
  UNIT_PRICE: 'Unit Price(개당가격 US달러)',
};

const SN = FN;

// 아래는 Input field에 관련한 정보를 담고 있습니다.
const fields = [
  {
    name: FN.SERVICE_TYPE,
    serverName: SN.SERVICE_TYPE,
    label: LB.SERVICE_TYPE,
    type: "",
    required: true,
    options: serviceTypeOptions,
    noDefOption: true,
  },
  {
    name: FN.DESTINATION,
    serverName: SN.DESTINATION,
    label: LB.DESTINATION,
    type: "",
    required: false,
    options: destinationOptions,
    noDefOption: true,
  },
  {name: FN.BL_NO, serverName: SN.BL_NO, label: LB.BL_NO, type: "", required: false},
  {
    name: FN.DEPOSIT,
    serverName: SN.DEPOSIT,
    label: LB.DEPOSIT,
    type: "",
    required: false,
  },
  {
    name: FN.SERVICE_CHARGE,
    serverName: SN.SERVICE_CHARGE,
    label: LB.SERVICE_CHARGE,
    type: "",
    required: false,
    readonly: true,
  },
  {
    name: FN.TOTAL_WEIGHT,
    serverName: SN.TOTAL_WEIGHT,
    label: LB.TOTAL_WEIGHT,
    type: "",
    required: false,
  },
  {name: FN.LENGTH, serverName: SN.LENGTH, label: LB.LENGTH, type: "", required: false},
  {name: FN.WIDTH, serverName: SN.WIDTH, label: LB.WIDTH, type: "", required: false},
  {name: FN.HEIGHT, serverName: SN.HEIGHT, label: LB.HEIGHT, type: "", required: false},
  {
    name: FN.VOLUME,
    serverName: SN.VOLUME,
    label: LB.VOLUME,
    type: "",
    required: false,
    readonly: true,
  },
  {
    name: FN.PICKUP_DATE,
    serverName: SN.PICKUP_DATE,
    label: LB.PICKUP_DATE,
    type: "date",
    required: false,
  },
  {
    name: FN.PICKUP_TIME,
    serverName: SN.PICKUP_TIME,
    label: LB.PICKUP_TIME,
    required: false,
  },
  {
    name: FN.PICKUP_TYPE,
    serverName: SN.PICKUP_TYPE,
    label: LB.PICKUP_TYPE,
    options: pickupTypeOptions,
    noDefOption: true,
    required: false,
  },
  {
    name: FN.PACKAGES,
    serverName: SN.PACKAGES,
    label: LB.PACKAGES,
    type: "",
    required: false,
  },
  {
    name: FN.FROM_NAME_KOR,
    serverName: SN.FROM_NAME_KOR,
    label: LB.FROM_NAME_KOR,
    type: "",
    required: true,
  },
  {
    name: FN.FROM_NAME_ENG,
    serverName: SN.FROM_NAME_ENG,
    label: LB.FROM_NAME_ENG,
    type: "",
    required: true,
    placeholder: "HONG, GIL DONG",
  },
  {
    name: FN.FROM_PHONE,
    serverName: SN.FROM_PHONE,
    label: LB.FROM_PHONE,
    type: "",
    required: true,
    placeholder: "***-***-****",
  },
  {
    name: FN.FROM_PHONE2,
    serverName: SN.FROM_PHONE2,
    label: LB.FROM_PHONE2,
    type: "",
    required: false,
  },
  {
    name: FN.FROM_EMAIL,
    serverName: SN.FROM_EMAIL,
    label: LB.FROM_EMAIL,
    type: "",
    required: true,
  },
  {
    name: FN.FROM_ADDR,
    serverName: SN.FROM_ADDR,
    label: LB.FROM_ADDR,
    type: "",
    required: true,
  },
  {
    name: FN.FROM_ADDR2,
    serverName: SN.FROM_ADDR2,
    label: LB.FROM_ADDR2,
    type: "",
    required: false,
    placeholder: "상세주소 별도입력",
  },
  {
    name: FN.FROM_CITY,
    serverName: SN.FROM_CITY,
    label: LB.FROM_CITY,
    type: "",
    required: true,
  },
  {
    name: FN.FROM_PROVINCE,
    serverName: SN.FROM_PROVINCE,
    label: LB.FROM_PROVINCE,
    type: "",
    required: true,
    options: provinceOptions,
    noDefOption: true,
  },
  {
    name: FN.AREA,
    serverName: SN.AREA,
    label: LB.AREA,
    type: "",
    required: false,
    options: areaOption,
    noDefOption: true,
  },
  {
    name: FN.FROM_COUNTRY,
    serverName: SN.FROM_COUNTRY,
    label: LB.FROM_COUNTRY,
    type: "",
    required: true,
    options: countryOptions,
    noDefOption: true,
    disabled: true,
  },
  {
    name: FN.FROM_POSTAL,
    serverName: SN.FROM_POSTAL,
    label: LB.FROM_POSTAL,
    type: "",
    required: true,
  },
  {
    name: FN.RETURN_DATE,
    serverName: SN.RETURN_DATE,
    label: LB.RETURN_DATE,
    type: "date",
    required: false,
  },
  {
    name: FN.VISA_TYPE,
    serverName: SN.VISA_TYPE,
    label: LB.VISA_TYPE,
    type: "",
    required: false,
    placeholder: "비자종류 및 비자만료날짜",
  },
  {
    name: FN.TO_NAME_KOR,
    serverName: SN.TO_NAME_KOR,
    label: LB.TO_NAME_KOR,
    type: "",
    required: true,
  },
  {
    name: FN.TO_NAME_ENG,
    serverName: SN.TO_NAME_ENG,
    label: LB.TO_NAME_ENG,
    type: "",
    required: true,
    placeholder: "HONG, GIL DONG",
  },
  {
    name: FN.TO_PHONE,
    serverName: SN.TO_PHONE,
    label: LB.TO_PHONE,
    type: "",
    required: true,
    placeholder: "***-***-****",
  },
  {
    name: FN.TO_PHONE2,
    serverName: SN.TO_PHONE2,
    label: LB.TO_PHONE2,
    type: "",
    required: false,
  },
  {
    name: FN.TO_TYPE,
    serverName: SN.TO_TYPE,
    label: LB.TO_TYPE,
    type: "",
    options: window["pickUpLocation"],
    noDefOption: true,
    required: false,
  },
  {
    name: FN.TO_ADDR,
    serverName: SN.TO_ADDR,
    label: LB.TO_ADDR,
    type: "",
    required: true,
  },
  {
    name: FN.TO_ADDR2,
    serverName: SN.TO_ADDR2,
    label: LB.TO_ADDR2,
    type: "",
    required: false,
    placeholder: "상세주소 별도입력",
  },
  {
    name: FN.TO_CITY,
    serverName: SN.TO_CITY,
    label: LB.TO_CITY,
    type: "",
    required: true,
  },
  {
    name: FN.TO_PROVINCE,
    serverName: SN.TO_PROVINCE,
    label: LB.TO_PROVINCE,
    type: "",
    required: true,
    options: provinceOptions,
    noDefOption: true,
  },
  {
    name: FN.TO_COUNTRY,
    serverName: SN.TO_COUNTRY,
    label: LB.TO_COUNTRY,
    type: "",
    required: true,
    options: countryOptions,
    noDefOption: true,
    disabled: true,
  },
  {
    name: FN.TO_POSTAL,
    serverName: SN.TO_POSTAL,
    label: LB.TO_POSTAL,
    type: "",
    required: true,
  },
  {
    name: FN.TO_EMAIL,
    serverName: SN.TO_EMAIL,
    label: LB.TO_EMAIL,
    type: "",
    required: false,
  },
  {
    name: FN.TO_CUSTOMS_NO,
    serverName: SN.TO_CUSTOMS_NO,
    label: LB.TO_CUSTOMS_NO,
    type: "",
    required: false,
    placeholder: "수취인 주민번호 또는 통관부호",
  },
  {
    name: FN.CONSENT,
    serverName: SN.CONSENT,
    label: LB.CONSENT,
    type: "checkbox",
    required: false,
  },
  {
    name: FN.MEMO,
    serverName: SN.MEMO,
    label: LB.MEMO,
    type: "",
    required: false,
    placeholder: "배송메시지 (예: 경비실에 맡겨주세요)",
  },
  {
    name: FN.IS_CUSTOMER,
    serverName: SN.IS_CUSTOMER,
    label: LB.IS_CUSTOMER,
    type: "",
    required: false,
  },
  {
    name: FN.MAPLE_TYPE,
    serverName: SN.MAPLE_TYPE,
    label: LB.MAPLE_TYPE,
    type: "",
    required: false,
    options: mapleTypeOptions,
    noDefOption: true,
  },
  {
    name: FN.REMARK,
    serverName: SN.REMARK,
    label: LB.REMARK,
    type: "textarea",
    required: false,
  },
  {name: FN.NO, serverName: SN.NO, type: '', hide: true},
  {name: FN.ITEM, serverName: SN.ITEM, label: LB.ITEM, type: '', required: false,},
  {name: FN.QTY, serverName: SN.QTY, label: LB.QTY, type: '', required: false, mask: util.MASK_QTY},
  {
    name: FN.UNIT_PRICE,
    serverName: SN.UNIT_PRICE,
    label: LB.UNIT_PRICE,
    type: '',
    required: false,
    mask: util.MASK_PRICE
  },
];

// 아래는 Invoice에 관련한 Grid를 생성합니다.
function useItemGrid() {
  const gridRef = React.useRef();
  const grid = useSimpleGrid({
    height: 300,
    columns: [
      {field: "no", headerName: "No.", width: 100, valueFormatter: (p) => p.value + 1},
      {field: "item", headerName: "Item", flex: 1, minWidth: 200},
      {field: "qty", headerName: "QTY", width: 120},
      {
        field: "unitPrice",
        headerName: "Unit Price",
        width: 120,
        valueFormatter: util.currencyFormatter,
      },
      {
        field: "totalPrice",
        headerName: "Total Price",
        width: 120,
        valueFormatter: util.currencyFormatter,
      },
    ],
    actions: ["delete"],
    actionWidth: 100,
    onAction: (action, data) => {
      const curGrid = gridRef.current;
      const newRows = curGrid.rows
        .filter((i) => i["no"] !== data["no"])
        .map((i, index) => ({...i, no: index}));
      curGrid.setRows(newRows);
    },
  });
  gridRef.current = grid;
  return grid;
}

export default CustomerInputForm;
