/**
 * @flow
 */
import React from "react";
import formLabels from "./formLabels";

export const LoginField = ({ name, label, icon, type }) => {
  return (
    <div className="form-group">
      <label className={"ml-40"}>{label}</label>
      <div className={"input-group d-flex align-items-center"}>
        <span className={"input-group-addon"}>
          <i className={`fa fa-${icon} fa-lg`} style={{ width: 36 }} />
        </span>
        <input type={type ?? "text"} id={name} name={name} className="form-control" />
      </div>
    </div>
  );
};

export const Field = ({ name, value, label, required, type }) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>
        {label}
        {required === true && <b className="ml-1 color-theme">*</b>}
      </label>
      {type === "number" ? (
        <input
          type="text"
          id={name}
          name={name}
          defaultValue={value}
          className="form-control"
          // pattern={"[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}"}
          inputMode={"numeric"}
        />
      ) : (
        <input type={type} id={name} name={name} defaultValue={value} className="form-control" />
      )}
    </div>
  );
};

export const CheckField = ({ name, checked, label, className }) => {
  return (
    <div className={`form-check ${className}`}>
      <input type="checkbox" id={name} name={name} defaultChecked={checked} className="form-check-input" />
      <label className={"form-check-label"} htmlFor={name}>
        {label}
      </label>
    </div>
  );
};

export const SelectField = ({ name, value, label, required, options, addPleaseSelect }) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>
        {label}
        {required === true && <b className="ml-1 color-theme">*</b>}
      </label>
      <select id={name} name={name} defaultValue={value} className="form-control">
        {addPleaseSelect === true && <option value={""}>{formLabels.pleaseSelectLabel}</option>}
        {options.map((i) => {
          if (typeof i === "string") {
            return (
              <option value={i} key={i}>
                {i}
              </option>
            );
          } else {
            return (
              <option value={i.value} key={i.value}>
                {i.label}
              </option>
            );
          }
        })}
      </select>
    </div>
  );
};

export const TextArea = ({ name, value, label, required }) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>
        {label}
        {required === true && <b className="ml-1 color-theme">*</b>}
      </label>
      <textarea id={name} name={name} className="form-control">
        {value}
      </textarea>
    </div>
  );
};

export const Row = ({ className, children }) => <div className={`row ${className ?? ""}`}>{children}</div>;

export const Col = ({ col, className, children }) => (
  <div className={`col-sm-12 col-md-12 col-lg-${col ?? 12} ${className ?? ""}`}>{children}</div>
);

export const AlertError = ({ message, onClose }) => {
  return (
    <div className="alert alert-primary text-center mb-20" role={"alert"}>
      <i className="fa fa-lg fa-exclamation-triangle mr-3 align-self-center" />
      {message}
      <button type="button" className="close" onClick={onClose}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

export const AlertSuccess = ({ message, onClose }) => {
  return (
    <div className="alert alert-primary text-center mb-20" role={"alert"}>
      <i className="fa fa-lg fa-check mr-3 align-self-center" />
      {message}
      <button type="button" className="close" onClick={onClose}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};
