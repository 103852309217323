/**
 * @flow
 */
import React from 'react';
import Aside from './Aside';
import Overview from './Overview';

const ServicePage = ({title, aside: {labels, serviceLabels}, overview: {background, video, overviewText}, children, header}) => {
  return (
    <section id="caseStudiesSingle" className="case-studies-single" style={{paddingTop: 20}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
            <div className="heading text-center mb-50">
              <h2 className="heading__title">{title}</h2>
              <div className="divider__line divider__theme divider__center mb-0" />
            </div>{/* /.heading */}
          </div>{/* /.col-lg-6 */}
        </div>{/* /.row */}
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-4 d-none d-lg-block">
            <Aside labels={labels} serviceLabels={serviceLabels}/>
          </div>{/* /.col-lg-4 */}
          <div className="col-sm-12 col-md-12 col-lg-8">
            {header}
            <Overview
              overviewText={overviewText}
              background={background}
              video={video}
            />
            {children}
          </div>{/* /.col-lg-8 */}
        </div>{/* /.row */}
      </div>{/* /.container */}
    </section>
  );
};

export function getServiceLabels(labels) {
  return {
    'moving': {title: labels.menuServiceInternational},
    'vehicle': {title: labels.menuServiceCar},
    'courier': {title: labels.menuServiceShipping},
    'cashmall': {title: labels.menuServiceAgent},
    'forwarding': {title: labels.menuServiceCommercial},
    'ecommerce': {title: labels.menuServiceWarehouse},
  }
}

export default ServicePage;
