/**
 * @flow
 */
import React, {useEffect} from 'react';
import clsx from 'clsx';
import {Link, useLocation} from 'react-router-dom';
import {useUser} from '../services/userReducer';
import {api, util} from '../services/service';
import {useDispatch} from 'react-redux';
import {updateLang, useLabels, useLang} from '../services/langReducer';
import usePageScriptRunner from '../hooks/usePageScriptRunner'
import queryString from 'query-string';

interface MenuDef {
  path: string;
  label: string;
  onClick?: (e: SyntheticEvent<HTMLAnchorElement>) => void;
}

interface MenuProps {
  path: string;
  label: string;
  type?: 'singleItem' | 'twoItems';
  active?: boolean;
  items?: MenuDef[];
  secondItems?: MenuDef[];
  itemsTitle?: string;
  secondItemsTitle?: string;
  mobileOnly?: boolean;
  userOnly?: boolean;
  onClick?: (e: SyntheticEvent<HTMLAnchorElement>) => void;
}

const paths = {
  home: '/',
  about: '/about',
  services: '',
  request: '/request',
  application: '/application',
  tracking: '/tracking/NG',
  newsMedia: '',
  contactUs: '/contact',
  registerLogin: '/login',
  myPage: '/my-page',
  logout: '',
  language: '',
};


const Header = () => {
  const lang = useLang();
  const user = useUser();
  const location = useLocation();
  const dispatch = useDispatch();

  //http://localhost:3000/?lang=ko 이렇게 오면 lang 한글로
  useEffect(() => {
    let language = queryString.parse(location.search)
    if (language.lang === 'ko') {
      updateLang(dispatch, 'ko');
    } else {
      updateLang(dispatch, 'en');
    }
  }, [])

  const onLangChange = (e, lang: 'en' | 'ko') => {
    e.preventDefault();
    e.stopPropagation();
    updateLang(dispatch, lang);
  }
  let navbarClasses = 'navbar navbar-expand-lg';
  if (location.pathname !== '/') {
    navbarClasses += ' fixed-navbar';
  }
  const labels = useLabels();
  const menus: MenuProps[] = [
    {
      path: paths.about,
      label: labels.menuAbout
    },
    {
      path: paths.services,
      label: labels.menuServices,
      type: 'twoItems',
      items: [
        {path: '/moving', label: labels.menuServiceInternational},
        {path: '/vehicle', label: labels.menuServiceCar},
        {path: '/courier', label: labels.menuServiceShipping},
      ],
      itemsTitle: '',
      secondItems: [
        {path: '/cashmall', label: labels.menuServiceAgent},
        {path: '/forwarding', label: labels.menuServiceCommercial},
        {path: '/ecommerce', label: labels.menuServiceWarehouse},
      ],
      secondItemsTitle: '',
    },
    {
      path: paths.request,
      label: labels.menuRequest,
    },
    {
      path: paths.application,
      label: labels.menuApplication,
    },
    {
      path: paths.tracking,
      label: labels.menuTracking,
    },
    {
      path: paths.newsMedia,
      label: labels.menuNewsMedia,
      type: 'singleItem',
      items: [
        {path: 'https://pf.kakao.com/_dLNBd', label: labels.menuMediaKakao},
        {path: 'https://blog.naver.com/canadashipping', label: labels.menuMediaNaver},
        {path: 'https://www.facebook.com/Coshipcanada', label: labels.menuMediaFacebook},
        {path: 'https://www.instagram.com/canadashipping_', label: labels.menuMediaInstagram},
        // {path: '/', label: labels.menuMediaTwitter},
      ],
    },
    {
      path: paths.contactUs,
      label: labels.menuContactUs,
    },
    {
      path: paths.myPage,
      label: labels.myPageLabel,
      mobileOnly: true,
      userOnly: true,
    },
    {
      path: paths.language,
      label: 'Language', //labels.languageLabel,
      mobileOnly: true,
      type: 'singleItem',
      items: [
        {path: '', label: labels.english, onClick: () => updateLang(dispatch, 'en')},
        {path: '', label: labels.korean, onClick: () => updateLang(dispatch, 'ko')},
      ],
    },
    {
      path: paths.registerLogin,
      label: labels.registerLogin,
      mobileOnly: true,
      userOnly: false,
    },
    {
      path: paths.logout,
      label: labels.logoutLabel,
      onClick: () => handleLogout(),
      mobileOnly: true,
      userOnly: true,
    },
  ];
  const langLabel = {
    en: labels.english,
    ko: labels.korean,
  };

  async function handleLogout() {
    await api.logout();
    document.location.reload();
  }

  function renderMenus() {
    return menus.map((menu) => {
      if (menu.mobileOnly && util.getClientWidth() > 991) {
        return null;
      }
      if (user.ready) {
        if (menu.userOnly === true) {
          if ((user?.id ?? 0) <= 0) return null;
          // console.log('user only and user signed in', menu, user);
        } else if (menu.userOnly === false) {
          if ((user?.id ?? 0) > 0) return null;
          // console.log('guest only and user signed out', menu, user);
        }
      } else {
        return null;
      }
      switch (menu.type) {
        case 'singleItem':
          return <MenuWithItems {...menu} key={menu.label}/>;
        case 'twoItems':
          return <MenuWithTwoItems {...menu} key={menu.label}/>;
        default:
          return <MenuLink {...menu} key={menu.label}/>;
      }
    });
  }

  return (
    <header id="header" className="header header-transparent">
      <nav className={navbarClasses}>
        <div className="container">
          <Link className="navbar-brand" to={'/'}>
            <img src="/assets/public/images/logo/logo-light.png" className="logo-light" alt="logo" width={190}/>
            <img src="/assets/public/images/logo/logo-dark.png" className="logo-dark" alt="logo" width={190}/>
          </Link>
          <button id='navToggler' className="navbar-toggler" type="button">
            <span className="menu-lines"><span/></span>
          </button>

          <div className="header__top-right d-none d-lg-block">
            <ul className="list-unstyled d-flex justify-content-end align-items-center">
              <li><i className="fa fa-phone"/><span>{labels.phone}</span></li>
              <li>
                <div className="dropdown">
                  <button className="btn dropdown-toggle width-auto" id="langDropdown" data-toggle="dropdown">
                    <i className="icon-map"/><span>{langLabel[lang ?? 'en']}</span>
                  </button>
                  <div className="dropdown-menu" aria-labelledby="langDropdown">
                    <a className="dropdown-item" href="english"
                       onClick={(e) => onLangChange(e, 'en')}>{labels.english}</a>
                    <a className="dropdown-item" href="korean"
                       onClick={(e) => onLangChange(e, 'ko')}>{labels.korean}</a>
                  </div>
                </div>
              </li>
              <li>
                {user.partner_id > 0
                  ? null
                  : (
                    <Link to={'/login'} className="btn btn__white">
                      <i className="fa fa-sign-in"/><span>{labels.registerLogin}</span>
                    </Link>
                  )
                }
              </li>
              {user.partner_id > 0 && (
                <li style={{marginLeft: '0px'}}>
                  <a href={'#logout'} onClick={handleLogout}>
                    {labels.logoutLabel}
                  </a>
                </li>
              )}
            </ul>
          </div>
          {/* /.header-top-right */}
          <div className='d-md-none d-sm-flex' style={{marginRight: 70}}>
            <Link to={'/tracking/NG'} className="bg-theme color-white d-flex justify-content-center align-items-center"
                  style={{width: 80, height: 30, lineHeight: '30px'}}>
              <span>{labels.trackingLabel}</span>
            </Link>
          </div>
          <div className="collapse navbar-collapse" id="mainNavigation">
            <ul className="navbar-nav ml-auto">
              {renderMenus()}
              {user.partner_id > 0 ?
                <li className="nav__item">
                  <Link to={'/my-page'} className={clsx('nav__item-link')}>{labels.myPageLabel}</Link>
                </li> :
                null}
              {/*<li className={'nav__item'}>*/}
              {/*  <Link to={'/my-page'}>*/}
              {/*   <span className="color-white">{labels.myPageLabel}</span>*/}
              {/*  </Link>*/}
              {/*</li>*/}
            </ul>
            {/* /.navbar-nav */}
          </div>
          {/* /.navbar-collapse */}
          {/*<div className="navbar-modules">*/}
          {/*  <div className="modules__wrapper d-flex align-items-center">*/}
          {/*    <a href="#" className="module__btn module__btn-search"><i className="fa fa-search" /></a>*/}
          {/*  </div>/!* /.modules-wrapper *!/*/}
          {/*</div>/!* /.navbar-modules *!/*/}
        </div>
        {/* /.container */}
      </nav>
      {/* /.navabr */}
    </header>
  );
};

const MenuLink = (props: MenuProps) => {
  if (props.onClick) {
    return (
      <li className="nav__item">
        <a href={'#'} onClick={props.onClick}
           className={clsx('nav__item-link', {active: props.active})}>{props.label}</a>
      </li>
    );
  } else {
    return (
      <li className="nav__item">
        <Link to={props.path} className={clsx('nav__item-link', {active: props.active})}>{props.label}</Link>
      </li>
    );
  }
};

const MenuWithItems = (props: MenuProps) => {
  return (
    <li className="nav__item with-dropdown">
      <a href={'#menu'} className={clsx('dropdown-toggle nav__item-link', {active: props.active})}
         onClick={e => e.preventDefault()}>{props.label}</a>
      <i className="fa fa-angle-right" data-toggle="dropdown" style={{width: '100%'}}/>
      <ul className="dropdown-menu">
        {props.items.map(({path, label, onClick}) => {
          if (onClick) {
            return (
              <li className="nav__item" key={label}>
                <a href={'#'} onClick={onClick} className={clsx('nav__item-link', {active: props.active})}>{label}</a>
              </li>
            );
          } else {
            if (path.startsWith('https://')) {
              return (
                <li className="nav__item" key={label}>
                  <a href={path} target={'_blank'} rel="noopener noreferrer" className="nav__item-link">{label}</a>
                </li>
              );
            } else {
              return (
                <li className="nav__item" key={label}>
                  <Link to={path} className="nav__item-link">{label}</Link>
                </li>
              );
            }
          }
        })}
      </ul>
      {/* /.dropdown-menu */}
    </li>
  );
};

const MenuWithTwoItems = (props: MenuProps) => {
  return (
    <li className="nav__item with-dropdown">
      <a href={'#menu'} className={clsx('nav__item-link', {active: props.active})}
         onClick={e => e.preventDefault()}>{props.label}</a>
      <i className="fa fa-angle-right" data-toggle="dropdown" style={{width: '100%'}}/>
      <ul className="dropdown-menu wide-dropdown-menu">
        <li className="nav__item">
          <div className="row mx-0">
            <div className="col-sm-6 dropdown-menu-col">
              {props.itemsTitle && <h6>{props.itemsTitle}</h6>}
              <ul className="nav flex-column">
                {props.items.map(({path, label}) => {
                  return (
                    <li className="nav__item" key={label}><Link className="nav__item-link" to={path}>{label}</Link></li>
                  );
                })}
              </ul>
            </div>
            {/* /.col-sm-6 */}
            <div className="col-sm-6 dropdown-menu-col">
              {props.secondItemsTitle && <h6>{props.secondItemsTitle}</h6>}
              <ul className="nav flex-column">
                {props.secondItems.map(({path, label}) => {
                  return (
                    <li className="nav__item" key={label}><Link className="nav__item-link" to={path}>{label}</Link></li>
                  );
                })}
              </ul>
            </div>
            {/* /.col-sm-6 */}
          </div>
          {/* /.row */}
        </li>
        {/* /.nav-item */}
      </ul>
      {/* /.dropdown-menu */}
    </li>
  );
};

export default Header;
