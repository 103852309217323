/**
 * @flow
 */
import React from 'react';

interface BottomSlideProps {
  imagePath: string;
  items: {
    image: string;
    content: React$Node;
  }[];
}

const BottomSlide = (props: BottomSlideProps) => {
  return (
    <div className="projects-carousel-2 carousel owl-carousel carousel-dots" data-slide={3} data-slide-md={2} data-slide-sm={1} data-autoplay="true" data-nav="false" data-dots="true" data-space={30} data-loop="true" data-speed={800}>
      {props.items.map((label, index) => {
        return (
          <div className="project-item" key={index}>
            <div className="project__img">
              <img src={`${props.imagePath}/work/${index + 1}.jpg`} alt="project img" />
              {/*<a href="#" className="zoom__icon" />*/}
            </div>{/* /.project-img */}
            <div className="project__content">
              {/*<div className="project__cat">*/}
              {/*  <a href="#">Analystics</a><a href="#">Optimization</a>*/}
              {/*</div>/!* /.project-cat *!/*/}
              {/*<h4 className="project__title"><a href="#">Lane Pairing Analysis</a></h4>*/}
              <h4 className="project__title">{label}</h4>
            </div>{/* /.project-content */}
          </div>
        );
      })}
    </div>
);
};

export default BottomSlide;
