/**
 * @flow
 */
import {combineReducers, createStore} from 'redux';
import userReducer from './userReducer';
import langReducer from './langReducer';
import globalDialogReducer from '../components/services/Dialog';
import {api, util} from './service';

const reducers = combineReducers({
  user: userReducer,
  lang: langReducer,
  gdlg: globalDialogReducer,
});

const store = createStore(reducers);
api.dispatch = store.dispatch;
util.dispatch = store.dispatch;

export default store;
