/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {util} from '../../services/service';

class ItemInputForm extends FormManager {
  onSave;
  constructor() {
    super({
      prefix: 'item-form',
      fields,
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
        horizontal: false,
        onSubmit: () => this.onSave(),
      },
    });
  }
  onRender = () => {
    const {renderField: _r} = this;
    return (
      <div className={'w-full'}>
        {_r(FN.ITEM)}
        {_r(FN.QTY)}
        {_r(FN.UNIT_PRICE)}
      </div>
    );
  };
  onValidate = (values) => {
    const {item, qty, unitPrice} = values;
    if (!item) {
      util.showError('필수 항목을 넣어주세요.');
      return null;
    }
    if (!qty) {
      util.showError('필수 항목을 넣어주세요.');
      return null;
    }
    if (!unitPrice) {
      util.showError('필수 항목을 넣어주세요.');
      return null;
    }
    return values;
  };
}

const FN = {
  NO: 'no',
  ITEM: 'item',
  QTY: 'qty',
  UNIT_PRICE: 'unitPrice',
};

const LB = {
  NO: 'No',
  ITEM: 'Item',
  QTY: 'QTY',
  UNIT_PRICE: 'Unit Price',
};

const SN = FN;

const fields = [
  { name: FN.NO, serverName: SN.NO, type: '', hide: true},
  { name: FN.ITEM, serverName: SN.ITEM, label: LB.ITEM, type: '', required: true, },
  { name: FN.QTY, serverName: SN.QTY, label: LB.QTY, type: '', required: true, mask: util.MASK_QTY},
  { name: FN.UNIT_PRICE, serverName: SN.UNIT_PRICE, label: LB.UNIT_PRICE, type: '', required: true, mask: util.MASK_PRICE},
];

export default ItemInputForm;
