/**
 * @flow
 */
import React from 'react';
import {useLabels, useLang} from '../../services/langReducer';
import ServicePage, {getServiceLabels} from './ServicePage';
import Accordion from './Accordion';
import BottomSlide from './BottomSlide';

const Moving = () => {
  const lang = useLang();
  const langName = lang === 'ko' ? 'korean' : 'english';
  const labels = useLabels();
  const serviceLabels = getServiceLabels(labels);
  const serviceLabel = window[lang === 'ko' ? 'movingLabelsKo' : 'movingLabelsEn'];
  const imagePath = 'assets/public/images/services/moving';
  const title = labels.menuServiceInternational;
  const background = `moving/01.${langName}.jpg`;
  const {video, overviewText} = serviceLabel;
  const accordionItems = [
    {
      title: serviceLabel.folder1Title,
      content: (
        <>
          <p className='mb-20'>{serviceLabel.folder1_1Title}</p>
          <ul style={{listStyleType: 'disc', paddingLeft: '1em'}}>
            {serviceLabel.folder1_1Items.map((label, index) => <li key={index}>{label}</li>)}
          </ul>
          <p className='mb-20 mt-20'>{serviceLabel.folder1_2Title}</p>
          <ul style={{listStyleType: 'disc', paddingLeft: '1em'}}>
            {serviceLabel.folder1_2Items.map((label, index) => <li key={index}>{label}</li>)}
          </ul>
          <p className='mb-20 mt-20'>{serviceLabel.folder1_3Title}</p>
          <ul style={{listStyleType: 'disc', paddingLeft: '2em'}}>
            <li>{serviceLabel.folder1_3Items[0]}</li>
            <li>{serviceLabel.folder1_3Items[1]}</li>
            <ul style={{listStyleType: 'square', paddingLeft: '1em'}}>
              <li>{serviceLabel.folder1_3Items[2]}</li>
              <li>{serviceLabel.folder1_3Items[3]}</li>
              <li>{serviceLabel.folder1_3Items[4]}</li>
              <li>{serviceLabel.folder1_3Items[5]}</li>
            </ul>
            <li>{serviceLabel.folder1_3Items[6]}</li>
          </ul>
        </>
      ),
    },
    {
      title: serviceLabel.folder2Title,
      content: (
        <>
          <p>{serviceLabel.folder2Items[0]}</p>
          <img src={`assets/public/images/services/moving/04.${langName}.jpg`} width={'100%'} alt={'CBSA'} />
        </>
      ),
    },
    {
      title: serviceLabel.folder3Title,
      // content: serviceLabel.folder3Items.map((label, index) => <p key={index} className={'mb-20'}>{label}</p>),
      content: (
        <>
          <p className='mb-20'>{serviceLabel.folder3_1Title}</p>
          {serviceLabel.folder3_1Items.map((label, index) => <p key={index} className={'mb-20'}>{label}</p>)}
          <p className='mb-20'>{serviceLabel.folder3_2Title}</p>
          {serviceLabel.folder3_2Items.map((label, index) => <p key={index} className={'mb-20'}>{label}</p>)}
          <p className='mb-20'>{serviceLabel.folder3_3Title}</p>
          {serviceLabel.folder3_3Items.map((label, index) => <p key={index} className={'mb-20'}>{label}</p>)}
          <p className='mb-20'>{serviceLabel.folder3_4Title}</p>
          {serviceLabel.folder3_4Items.map((label, index) => <p key={index} className={'mb-20'}>{label}</p>)}
        </>
      ),
    },
    // {
    //   title: serviceLabel.folder4Title,
    //   content: serviceLabel.folder4Items.map((label, index) => <p key={index} className={'mb-20'}>{label}</p>),
    // },
    // {
    //   title: serviceLabel.folder5Title,
    //   content: serviceLabel.folder5Items.map((label, index) => <p key={index} className={'mb-20'}>{label}</p>),
    // },
    {
      title: `FAQ`,
      content: (
        <>
          {serviceLabel.faqItems.map((item, index) => {
            return (
              <div className='mb-4' key={index}>
                <b className='d-inline-block mb-2'>Q. {item.title}</b>
                <div>{item.content}</div>
              </div>
            );
          })}
        </>
      ),
    }
  ];
  return (
    <ServicePage title={title} aside={{labels, serviceLabels}} overview={{background, video, overviewText}}>
      {/*진행과정*/}
      <div className="row mb-50">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="text__block-3">
            <h5 className="text__block-title">{serviceLabel.processLabel}</h5>
            <p className="text__block-desc">{serviceLabel.processText}</p>
            <p className="text__block-desc">{serviceLabel.processText2}</p>
          </div>
          <img src={`${imagePath}/02.${langName}.jpg`} alt="chart" className="img-fluid mb-30" />
          <div className='text__block-3'>
            <h6>TIP:</h6>
            <ul style={{listStyleType: 'disc', paddingLeft: '1em'}}>
              {serviceLabel.tips.map((label, index) => <li key={index}>{label}</li>)}
            </ul>
          </div>
        </div>{/* /.col-lg-12 */}
      </div>{/* /.row */}
      {/*단가 산출 방식*/}
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="text__block">
            <h5 className="text__block-title">{serviceLabel.priceTitle}</h5>
            <p className="text__block-desc">{serviceLabel.priceDesc}</p>
          </div>{/* /.text-block */}
          <img src={`${imagePath}/03.${langName}.jpg`} alt="chart" className="img-fluid mb-50" />
        </div>{/* /.col-lg-12 */}
      </div>{/* /.row */}
      {/*WHY COSHIP 타이틀*/}
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="text__block">
            <h5 className="text__block-title">{serviceLabel.whyUs}</h5>
          </div>{/* /.text-block */}
        </div>{/* /.col-lg-12 */}
      </div>{/* /.row */}
      {/*WHY COSHIP 내용*/}
      <div className="fancybox-layout2 fancybox-layout2-panel mb-50">
        <div className="row">
          {serviceLabel.whyUsItems.map((item) => {
            const {icon, title, desc_1, desc_2} = item;
            return (
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="fancybox-item">
                  <div className="fancybox__icon">
                    {/*<i className={icon} />*/}
                    <img src={`assets/public/images/services/${icon}`} height={48} />
                  </div>{/* /.fancybox-icon */}
                  <div className="fancybox__content">
                    <h4 className="fancybox__title">{title}</h4>
                    <p className="fancybox__desc font-weight-bold">{desc_1}</p>
                    <p className="fancybox__desc font-weight-bold">{desc_2}</p>
                  </div>{/* /.fancybox-content */}
                </div>
              </div>
            );
          })}
        </div>{/* /.row */}
      </div>{/* /.fancybox-layout2-panel */}
      {/*타주이사*/}
      <div className="row mb-50">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="text__block-3">
            <h5 className="text__block-title">{serviceLabel.moveOtherTitle}</h5>
          </div>
          <p className='text__block-desc'>{serviceLabel.moveOtherDesc}</p>
          <p className='text__block-desc'>{serviceLabel.moveOtherDesc2}</p>
          <div style={{position: 'relative'}}>
            <img src={`${imagePath}/03-1.jpg`} alt="background" />
            <div className="d-flex justify-content-center align-items-center" style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0}}>
              <div className="video__btn align-v-h">
                <a className="popup-video" href={serviceLabel.moveOtherVideo}>
                  <span className="video__player-animation" />
                  <div className="video__player">
                    <i className="fa fa-play" />
                  </div>
                </a>
              </div>
            </div>
          </div>
          {/*<div className='d-flex justify-content-center w-100 mx-4' style={{paddingLeft: 100}}>*/}
          {/*  <a className="btn btn__video popup-video" href={''}>*/}
          {/*    <div className="video__player">*/}
          {/*      <span className="video__player-animation" />*/}
          {/*      <i className="fa fa-play" />*/}
          {/*    </div>*/}
          {/*    /!*<div className="text-left pl-4">*!/*/}
          {/*    /!*  소개영상*!/*/}
          {/*    /!*</div>*!/*/}
          {/*  </a>*/}
          {/*</div>*/}
        </div>{/* /.col-lg-12 */}
      </div>{/* /.row */}
      {/*폴더블 컨텐츠*/}
      <div className="row mb-30">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <Accordion items={accordionItems} prefix={'main'} />
        </div>{/* /.col-lg-12 */}
      </div>
      {/*이미지 슬라이드*/}
      <div className='row mt-50'>
        <div className='col-sm-12 col-md-12 col-lg-12'>
          <BottomSlide imagePath={imagePath} items={serviceLabel.slideItems} />
        </div>
      </div>
    </ServicePage>
  );
};

export default Moving;
