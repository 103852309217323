/**
 * @flow
 */
import React from 'react';
import usePageScriptRunner from '../hooks/usePageScriptRunner';
import useUserOnly from '../hooks/useUserOnly';
import ProfileForm from '../components/forms/ProfileForm';
import useGrid from '../hooks/useGrid';
import {useLang} from '../services/langReducer';
import {api, util} from '../services/service';
import {useUser} from '../services/userReducer';
import {useHistory, useLocation} from 'react-router-dom';

const MyPage = () => {
  useUserOnly();
  usePageScriptRunner();
  const grid = useMyPageGrid();
  return (
    <div className="common-page">
      <section id="requestQuoteTabs" className="request-quote request-quote-tabs pt-0 bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="request__form">
                <nav className="nav nav-tabs">
                  <a className="nav__link active" data-toggle="tab" href="#my-page">
                    <div className="request__form-header d-flex align-items-center">
                      <i className="icon-box"/>
                      <h4 className="request__form-title">My Page</h4>
                    </div>
                    {/* /.request-form-header */}
                  </a>
                  <a className="nav__link" data-toggle="tab" href="#profile">
                    <div className="request__form-header d-flex align-items-center">
                      <i className="icon-worldwide"/>
                      <h4 className="request__form-title">Profile</h4>
                    </div>
                    {/* /.request-form-header */}
                  </a>
                </nav>
                <div className="tab-content px-2 pb-3">
                  <div className="tab-pane fade show active" id="my-page">
                    {grid.render()}
                  </div>
                  {/* /.tab */}
                  <div className="tab-pane fade" id="profile">
                    <ProfileForm/>
                  </div>
                  {/* /.tab */}
                </div>
                {/* /.tab-content */}
              </div>
              {/* /.request-form */}
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    </div>
  );
};

function TrackingCellRenderer(params) {
  const history = useHistory();
  const {tracking_type: type, bl_no: blNo} = params.data;
  switch (type) {
    case 'KP':
      return (
        <a href={'/tracking/null'} onClick={(e) => {
          e.preventDefault();
          history.push('/tracking/null', {blNo})
        }}>{params.value}</a>
      );
    case 'CP':
      return (
        <a href={`https://www.canadapost.ca/trackweb/en#/search?searchFor=${params.value}`}
           target={'_blank'}>{params.value}</a>
      );
    case 'UP':
      return (
        <a href={`https://www.ups.com/track?loc=en_CA&tracknum=${params.value}`} target={'_blank'}>{params.value}</a>
      );
    case 'CO':
      return (
        <a href={'/tracking/null'} onClick={(e) => {
          e.preventDefault();
          history.push('/tracking/null', {blNo})
        }}>{params.value}</a>
      );
    default:
      return params.value;
  }
}

function EditCellRenderer(params) {
  const history = useHistory();
  const lang = useLang();
  const isEn = lang === 'en';
  const {bl_no: blNo, tracking_type, ups_id} = params.data; // 일전에 tracking_type: type이 포함되어 있다 에러가 의심되어 삭제
  let trackingBtn = isEn ? 'Tracking' : '트래킹';
  let url = '/tracking/null';
  if (blNo === null && tracking_type === 'KU') {
    trackingBtn = isEn ? 'View' : '조회';
    url = `/application/form?s=MA&d=CANADA&n=C&upsid=${ups_id}&sid=null&key=null`;
  }
  return (
    // weight가 0이거나 없으면 && eta,etd 가 없으면  수정할 수 있어야 한다.
    (params.data.weight === '' || params.data.weight === '0') && (params.data.eta === "" && params.data.etd === "") ?
      <div>
        <a style={{
          backgroundColor: '#ff5e14',
          color: 'white',
          padding: '2px 4px',
          borderRadius: '5px',
          fontSize: '0.8rem',
          cursor: 'pointer',
          marginRight: '3px'
        }} href={'/tracking'} onClick={(e) => {
          e.preventDefault();
          history.push(url, {blNo})
        }}>
          {trackingBtn}
        </a>
        <a style={{
          backgroundColor: '#ff5e14',
          color: 'white',
          padding: '2px 4px',
          borderRadius: '5px',
          fontSize: '0.8rem',
          cursor: 'pointer'
        }} onClick={async () => {
          const blNo = params.data.bl_no;
          // console.log(params.data.weight);
          api.getEditData(blNo).then(res => {
            //console.log('이 데이터를 가지고 form으로 가야 한다.', res.data.data[0]);
            //console.log('kind 요청 응답 데이터', res.data.data[0]?.jcommon.kind);
            //console.log('출발지역 요청 응답 데이터', res.data.data[0]?.jcommon.destination);
            //console.log('항구? 배편? 요청 응답 데이터', res.data.data[0]?.jshipment.dzServiceType);
            const destination = res.data.data[0]?.jcommon.destination;
            const serviceType = res.data.data[0]?.jshipment.dzServiceType;
            history.push(`/application/form?s=${serviceType}&d=${destination}&r=${blNo}`);
          })
        }}>
          {isEn ? 'Edit' : '수정'}
        </a>
      </div> :
      <div>
        <a style={{
          backgroundColor: '#ff5e14',
          color: 'white',
          padding: '2px 4px',
          borderRadius: '5px',
          fontSize: '0.8rem',
          cursor: 'pointer'
        }} href={'/tracking'} onClick={(e) => {
          e.preventDefault();
          history.push(url, {blNo})
        }}>{trackingBtn}</a>
      </div>
  )
};


function useMyPageGrid() {
  const lang = useLang();
  const user = useUser();
  const isEn = lang === 'en';
  const grid = useGrid({
    height: 500,
    columnDefs: [
      {field: 'cdate', headerName: isEn ? 'Date' : '날짜', flex: 1, valueFormatter: util.dateFormatter},
      {field: 'shipper', headerName: isEn ? 'Shipper' : '보낸분', flex: 1},
      {field: 'consignee', headerName: isEn ? 'Consignee' : '받는분', flex: 1},
      {field: 'weight', headerName: isEn ? 'CBM/Weight' : '무게', flex: 0.5},
      {field: 'etd', headerName: isEn ? 'ETD' : '출발예상일', flex: 0.9},
      {field: 'eta', headerName: isEn ? 'ETA' : '도착예상일', flex: 0.9},
      {
        field: 'bl_no',
        headerName: isEn ? 'Tracking No.' : '트래킹넘버',
        flex: 1.2,
        cellRendererFramework: TrackingCellRenderer
      },
      {field: 'edit', headerName: '', flex: 1, cellRendererFramework: EditCellRenderer},
      // {field: 'tracking_type', hide: true}, 에러로 의심되어 삭제
    ],
    pagination: true,
    paginationPageSize: 20,
  });
  React.useEffect(() => {
    if (grid.isReady) {
      api.getBLList(user['partner_id']).then(({data}) => {
        if (data?.length > 0) {
          grid.setRows(data);
        } else {
          const rows = [];
          // for (let i = 0; i < 100; ++i) {
          //   // rows.push({
          //   //   cdate: '2021-01-01',
          //   //   shipper: `홍길동${i}`,
          //   //   consignee: '홍길순',
          //   //   weight: '0.7',
          //   //   etd: '2021-01-02',
          //   //   eta: '2021-01-03',
          //   //   bl_no: '1234567890',
          //   //   tracking_type: 'CO',
          //   //   //edit: () => {}
          //   // });
          // }
          grid.setRows(rows);
        }
      });
    }
  }, [grid.isReady]);
  return grid;
}

export default MyPage;
